import { useEffect, useState } from 'react';
import Split from 'react-split';
import { Label, Menu, Tab } from 'semantic-ui-react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import RuleCard from '@components/RuleCard';
import DocumentEmails from '@components/DocumentEmails';
import DocumentActivities from '@components/DocumentActivities';
import Alerts from '@components/Alerts';
import useHyperscience from '@hooks/useHyperscience';
import DocumentEventsForm from '@components/DocumentEventsForm';
import { updateAlerts, updateRules } from '@actions';
import WorkItemDocuments from '@components/WorkItemDocuments';
import { getFieldsFromDocument } from '@services/documents';

import DocumentViewer from './DocumentViewer/DocumentViewer';
import HSTable from './Table/HSTable';
import s from './styles.module.scss';

export default function Document({
  activity,
  hsDocument,
  disableLeftEvents,
  readOnly,
}) {
  const [fields, setFields] = useState([]);
  const [tables, setTables] = useState([]);
  // const containerRef = useRef();
  const {
    nameFilter,
    filterExceptions,
    selectedField,
    selectedTable,
    selectField,
    selectTable,
    setEditable,
  } = useHyperscience();

  useEffect(() => {
    const isEditable = checkEditableStatus();
    setEditable(isEditable);
  }, [activity]);

  useEffect(() => {
    if (selectedField && !fields.some((field) => field.id === selectedField)) {
      selectField(null);
    }
  }, [fields]);

  useEffect(() => {
    if (selectedTable && !tables.some((table) => table.id === selectedTable)) {
      selectTable(null);
    }
  }, [tables]);

  const checkEditableStatus = () => {
    const { status } = activity;
    return status !== 'Complete';
  };

  const filterTables = (tableList) => {
    if (!nameFilter && !filterExceptions) return tableList;
    return tableList.filter((table) => {
      if (
        nameFilter &&
        !table.name.toLowerCase().includes(nameFilter.toLowerCase())
      ) {
        return false;
      }
      if (filterExceptions && !tableHasExceptions(table)) {
        return false;
      }
      return true;
    });
  };

  useEffect(() => {
    if (hsDocument) {
      const fieldsFromDocument = getFieldsFromDocument(hsDocument);
      if (hsDocument.document_tables) {
        const auxTables = filterTables(hsDocument.document_tables);
        const fieldsFromTable = auxTables
          .map((table) => getFieldsFromTable(table))
          .flat();
        setFields([...fieldsFromDocument, ...fieldsFromTable]);
        setTables(auxTables);
      } else {
        setFields(fieldsFromDocument);
      }
    }
  }, [nameFilter, filterExceptions]);

  const tableHasExceptions = (tbl) => {
    return tbl.rows.some((row) => {
      return row.cells.some(
        (cell) => cell.exceptions && cell.exceptions?.length > 0,
      );
    });
  };

  const getFieldsFromTable = (table) => {
    const tableFields = table.rows.map((row) => {
      return row.cells.map((cell) => ({
        ...cell,
        type: 'cell',
        table_id: table.id,
        coordinates: cell.bounding_box
          ? {
              startX: cell.bounding_box[0],
              startY: cell.bounding_box[1],
              endX: cell.bounding_box[2],
              endY: cell.bounding_box[3],
            }
          : null,
      }));
    });
    return tableFields.flat();
  };

  const tablePresent = !!selectedTable;
  const fieldsForList = fields.filter((field) => field.type === 'field');

  // 153 from top and 11 is margin of eventsDocumentWrapper
  // const containerTop = containerRef.current?.offsetTop - 153 - 11;

  // const combineAttachments = (documents = []) => {
  //   const attachments = documents.reduce((acc, document) => {
  //     acc.push(...document.pages);
  //     return acc;
  //   }, []);
  //   return attachments;
  // };

  // const attachments = combineAttachments(activity?.documents);

  return (
    <div>
      <Split
        direction="vertical"
        style={{ height: '100%' }}
        expandToMin
        gutterSize={tablePresent ? 10 : 0}
        sizes={tablePresent ? [50, 50] : [100, 0]}
        minSize={0}
      >
        <div>
          <div className="hs-data-container">
            {!disableLeftEvents && (
              <FieldListWrapper
                layouts={hsDocument.layouts}
                fields={fieldsForList}
                tables={tables}
                readOnly={readOnly}
              />
            )}
            <WorkItemDocuments fields={fields} readOnly={readOnly} />
            {/* <DocumentViewerWrapper
              hsDocument={hsDocument}
              fields={fields}
              attachments={attachments}
              // attachments={activity.attachments}
              tabsClassName={tabsClassName}
            /> */}
          </div>
        </div>
        <div className="hs-table-container">
          {tablePresent && <HSTable hsDocument={hsDocument} />}
        </div>
      </Split>
    </div>
  );
}

const DocumentViewerWrapper = ({
  hsDocument,
  fields,
  attachments,
  tabsClassName,
}) => {
  const panes = [
    {
      menuItem: 'Images',
      render: () => (
        <Tab.Pane attached={false}>
          <DocumentViewer
            hsDocument={hsDocument}
            fields={fields}
            attachments={attachments}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Emails',
      render: () => (
        <Tab.Pane attached={false}>
          <DocumentEmails />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Activities',
      render: () => (
        <Tab.Pane attached={false}>
          <DocumentActivities />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className={clsx('PDFViewerTabs', tabsClassName)}>
      <Tab menu={{ secondary: true }} panes={panes} />
    </div>
  );
};

const FieldListWrapper = ({ layouts, fields, tables, readOnly }) => {
  // ? test alerts
  // const alerts = ALERTS_LIST;
  // const checkedAlerts = [1, 5, 8];
  const alerts = useSelector((state) => state.alertsReducer?.alerts);
  const checkedAlerts = useSelector(
    (state) => state.workItemReducer?.workItem?.alerts,
  );

  const dispatch = useDispatch();

  const rules = useSelector((state) => state.workItemReducer?.workItem?.rules);
  // ? test rules
  // const rules = {
  //   'Rule 1': null,
  //   'Rule 2': 'fixed',
  //   'Rule 3': 'fixed',
  //   'Rule 4': 'ignored',
  //   'Rule 5': 'ignored',
  //   'Rule 6': 'ignored',
  // };

  const createRulesList = (rulesList) => {
    return Object.keys(rulesList).map((rule, index) => ({
      id: index,
      title: [rule],
      key: rulesList[rule],
    }));
  };

  const rulesList = createRulesList(rules);

  const panes = [
    {
      menuItem: (
        <Menu.Item key="rules">
          Rules<Label>{rulesList?.length}</Label>
        </Menu.Item>
      ),
      render: () => (
        <Tab.Pane>
          {/* {false && (
            <div>
              <Button className={s.validateButton}>Validate rules</Button>
            </div>
          )} */}
          {rulesList?.length === 0 && (
            <div className={s.noRules}>No rules found.</div>
          )}
          {rulesList?.length > 0 && (
            <div className="cardsWrapper">
              {rulesList.map((card) => (
                <RuleCard
                  title={card.title}
                  key={card.id}
                  id={card.id}
                  status={card.key}
                  onRuleChange={(newRules) => dispatch(updateRules(newRules))}
                  readOnly={readOnly}
                />
              ))}
            </div>
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Alerts',
      render: () => (
        <Tab.Pane attached={false}>
          <Alerts
            alerts={alerts}
            checkedAlerts={checkedAlerts}
            onAlertChange={(newAlerts) => dispatch(updateAlerts(newAlerts))}
            readOnly={readOnly}
          />
        </Tab.Pane>
      ),
    },
    // {
    //   menuItem: 'Data',
    //   render: () => (
    //     <Tab.Pane attached={false}>
    //       <FieldList layouts={layouts} fields={fields} tables={tables} />
    //     </Tab.Pane>
    //   ),
    // },
    {
      menuItem: 'Output',
      render: () => (
        <Tab.Pane>
          <DocumentEventsForm readOnly={readOnly} />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div className="PDFListTabs documentEventsWrapper">
      <Tab menu={{ secondary: true }} panes={panes} defaultActiveIndex={2} />
    </div>
  );
  // return <FieldList layouts={layouts} fields={fields} tables={tables} />;
};
