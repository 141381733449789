import { Image } from 'semantic-ui-react';

// import defaultUser from '../../../../assets/images/default_profile.jpg';
const defaultProfile = `${process.env.PUBLIC_URL}/default_profile.jpg`;

export default function Avatar({ bot, imageUrl, noLine = false }) {
  const auxImageUrl = imageUrl || defaultProfile;
  return (
    <>
      {noLine || <div className="vbar" />}
      <Image className="smp-event-avatar" src={auxImageUrl} circular />
    </>
  );
}
