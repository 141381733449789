// ! NEED TO REWRITE THIS uniq func
/* eslint-disable no-plusplus */
import { cloneDeep } from 'lodash';

export const unique = (array) => {
  const a = array;
  for (let i = 0; i < a.length; ++i) {
    for (let j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }
  return a;
};

export const isIE = () => {
  const ua = navigator.userAgent;

  return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
};

export const isDesktop = () => {
  return window.innerWidth >= 768;
};

export function getDeepPathValue(object, path) {
  const arrayPath = path.split('.');
  const value = arrayPath.reduce((prevValue, currentValue, index) => {
    if (!prevValue || typeof prevValue !== 'object') {
      console.error('The specified item is not an object');
      return null;
    }
    const iterationValue = prevValue[currentValue];
    if (!iterationValue) {
      console.error(
        `The key ${currentValue} does not exist in ${path[index - 1]}!`,
      );
      return null;
    }
    return iterationValue;
  }, object);

  return value;
}

export const transformFileToBase64 = (file) => {
  const promise = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => reject(error);
  });
  return promise;
};

export const removePropertiesFromObject = (obj, propsToRemove) => {
  const objectCopy = cloneDeep(obj);
  propsToRemove.forEach((prop) => {
    if (Object.prototype.hasOwnProperty.call(objectCopy, prop)) {
      delete objectCopy[prop];
    }
  });
  return objectCopy;
};
