/* eslint-disable no-return-assign */
import { Icon, Ref, Table } from 'semantic-ui-react';

import ResizeHandler from './ResizeHandler';
import { FILTER_TYPES } from './Filters/filterTypes';

export default function HeaderCell(props) {
  const {
    column,
    filters,
    updateFilters,
    index,
    resizedColumnIndex,
    setResizedColumnIndex,
    refs,
  } = props;
  const sortKey = column.key.includes('.')
    ? column.key.split('.').at(-1)
    : column.key;

  const columnIsSorted = (columnKey) => filters?.sort?.includes(columnKey);

  const handleColumnSort = (columnKey) => {
    let { sort } = filters;
    if (!columnIsSorted(columnKey)) {
      sort = column.filter === FILTER_TYPES.DATE ? `-${columnKey}` : columnKey;
    } else {
      sort = sort.includes('-') ? columnKey : `-${columnKey}`;
    }
    updateFilters({ sort });
  };

  return (
    <Ref innerRef={(el) => (refs.current[index] = el)}>
      <Table.HeaderCell className="table-header-cell">
        {column.sortable !== false ? (
          <>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => handleColumnSort(sortKey)}
              onKeyPress={() => handleColumnSort(sortKey)}
              role="button"
              tabIndex="0"
            >
              {column.name}
            </span>
            {columnIsSorted(sortKey) && (
              <Icon
                name={`angle ${
                  filters.sort.includes(`-${sortKey}`) ? 'down' : 'up'
                }`}
              />
            )}
          </>
        ) : (
          column.name
        )}
        <ResizeHandler
          index={index}
          resizedColumnIndex={resizedColumnIndex}
          setResizedColumnIndex={setResizedColumnIndex}
        />
      </Table.HeaderCell>
    </Ref>
  );
}
