import { AUTH_TOKEN } from '@constants';
import { cookies } from '@constants/cookies';

const useAuthToken = () => {
  const authToken = cookies.get(AUTH_TOKEN);

  return { authToken, cookies };
};

export default useAuthToken;
