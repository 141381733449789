import { useDrag, useDrop } from 'react-dnd';
import { useRef } from 'react';

import s from '@components/DocumentPage/DocumentPage.module.scss';

const DocumentPage = ({
  page,
  count,
  documentId,
  wiId,
  from,
  onPageClick,
  onPageDrop,
  readOnly,
}) => {
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'page',
      item: { id: page.id, documentId, wiId, from },
      canDrag: !readOnly,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [page.id, documentId],
  );

  const handlePageClick = (e) => {
    e.stopPropagation();
    onPageClick(page);
  };

  const [{ handlerId, canDrop, isOver }, drop] = useDrop({
    accept: 'page',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
    drop(item) {
      if (item.id === page.id) return;
      onPageDrop({
        newDocumentId: documentId,
        oldDocumentId: item.documentId,
        pageId: item.id,
        newWorkItemId: wiId,
        oldWorkItemId: item.wiId,
        from: item.from,
        to: from,
        replaceThisPageTo: page.id,
      });
    },
  });
  const isActive = canDrop && isOver;
  drag(drop(ref));
  return (
    <div onClick={handlePageClick} data-handler-id={handlerId}>
      <img
        ref={ref}
        className={s.card}
        src={page.image_url_thumb}
        alt=""
        style={{ border: isActive && '1px solid #227d98' }}
      />
      <div className={s.page}>{count}</div>
    </div>
  );
};

export default DocumentPage;
