/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { set } from 'lodash';
import toast from 'react-hot-toast';

import {
  setEditedOutputField,
  setWorkItem,
  updateHsState,
  updateOutputFields,
} from '@actions';
import api from '@services/axios';

import BoxInput from './BoxInput';

const BASE_CLASS = 'bounding-box';

export default function BoundingBox({ field, dimensions }) {
  const { selectedField } = useSelector((state) => state.hyperscienceReducer);
  const selectedOutputField = useSelector(
    (state) => state?.workItemReducer?.editedField?.field,
  );
  const workItem = useSelector((state) => state.workItemReducer.workItem);

  const boundingBoxRef = useRef();
  const dispatch = useDispatch();
  const { coordinates, id } = field;

  useEffect(() => {
    if (isSelected) {
      boundingBoxRef.current.scrollIntoView({
        block: 'center',
        inline: 'center',
      });
    }
  }, [selectedField]);
  if (!coordinates || !dimensions) return null;

  const isSelected = selectedField === id;

  const width = (coordinates.endX - coordinates.startX) * dimensions.width;
  const height = (coordinates.endY - coordinates.startY) * dimensions.height;
  const offsetX = coordinates.startX * dimensions.width;
  const offsetY = coordinates.startY * dimensions.height;

  const auxOffsetX = Number.isNaN(offsetX) ? 0 : offsetX;
  const auxOffsetY = Number.isNaN(offsetY) ? 0 : offsetY;

  const pasteToField = () => {
    if (selectedOutputField?.length > 0) {
      const fieldData = {};
      set(fieldData, selectedOutputField, field.transcription.normalized);
      const updatingToast = toast.loading('Updating field...');
      api
        .jsonAPI()
        .patch(`/work_items/${workItem.id}/output_fields`, {
          data: fieldData,
        })
        .then(({ data }) => {
          dispatch(updateOutputFields(data.outputFields));
          dispatch(setEditedOutputField({}));
          dispatch(
            updateHsState({
              field: null,
              table: null,
            }),
          );
          toast.dismiss(updatingToast);
          toast.success('Field successfully updated');
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
          toast.error('Something went wrong when updating field');
        });
    }
  };

  const onClickHandler = () => {
    dispatch(
      updateHsState({
        field: selectedField === id ? null : id,
        table: field.table_id ?? null,
      }),
    );
  };

  const hasException = field.exceptions && field.exceptions.length > 0;

  const boxClassName = () => {
    const classes = [BASE_CLASS];
    if (hasException) {
      classes.push('exception');
    }
    if (isSelected) {
      classes.push('selected');
    }
    return classes.join(' ');
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: auxOffsetX,
        top: auxOffsetY,
        zIndex: isSelected && '1000',
      }}
    >
      <div
        ref={boundingBoxRef}
        className={boxClassName()}
        onClick={onClickHandler}
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
      />
      {isSelected && (
        <BoxInput
          field={field}
          onPasteClick={pasteToField}
          containerWidth={width}
          containerHeight={height}
        />
      )}
    </div>
  );
}
