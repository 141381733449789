const downloadFile = (text, name, format) => {
  const element = document.createElement('a');
  const file = new Blob([text], {
    type: 'text/plain',
  });
  element.href = URL.createObjectURL(file);
  element.download = `${name}.${format}`;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

export const saveFile = (data, name, format) => {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.${format}`);
  document.body.appendChild(link);
  link.click();
};

export default downloadFile;
