import { Checkbox } from 'antd';

import s from './OutputField.module.scss';

const OutputField = ({
  field: { name, label, visible, required },
  blockName,
  onChange,
  parentVisible,
  parentRequired,
}) => {
  return (
    <li className={s.fieldWrapper}>
      <div>{label}</div>
      <div className={s.checkboxes}>
        <Checkbox
          name="visible"
          checked={visible}
          disabled={!parentVisible}
          onChange={(e) =>
            onChange(e.target.name, e.target.checked, name, blockName)
          }
        />
        <Checkbox
          name="required"
          checked={required}
          disabled={!parentRequired}
          onChange={(e) =>
            onChange(e.target.name, e.target.checked, name, blockName)
          }
        />
      </div>
    </li>
  );
};

export default OutputField;
