import { Checkbox } from 'antd';

import OutputField from '@components/OutputFieldsManager/components/OutputField';

import s from './OutputField.module.scss';

const OutputFieldsBlock = ({
  field: { name, label, fields, visible, required },
  onBlockChange,
  onFieldChange,
}) => {
  if (!fields) return null;
  // console.log(field);
  return (
    <div>
      <div className={s.fieldBlock}>
        <div>{label}</div>
        <div className={s.checkboxes}>
          <Checkbox
            checked={visible}
            name="visible"
            onChange={(e) =>
              onBlockChange(e.target.name, e.target.checked, name)
            }
          />
          <Checkbox
            checked={required}
            name="required"
            onChange={(e) =>
              onBlockChange(e.target.name, e.target.checked, name)
            }
          />
        </div>
      </div>
      <ul>
        {fields.map((field) => (
          <OutputField
            key={field.name}
            field={field}
            onChange={onFieldChange}
            blockName={name}
            parentVisible={visible}
            parentRequired={required}
          />
        ))}
      </ul>
    </div>
  );
};

export default OutputFieldsBlock;
