import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import useFilters from '@hooks/useFilters';
import api from '@services/axios';
import Table from '@components/aria/UI/Table/Table';
import downloadFile from '@hooks/downloadFile';

export default function EmailAddresses() {
  const { t } = useTranslation();
  const [filters, updateFilters] = useFilters();
  const uploadRef = useRef(null);
  const [tableMetaData, setTableMetaData] = useState(1);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const getEmailAddresses = () => {
    setLoading(true);
    api
      .jsonAPI()
      .get('email_addresses', { params: filters })
      .then((res) => {
        setLoading(false);
        setEmailAddresses(res.data);
        setTableMetaData(res.data.meta?.pagy);
      })
      .catch((err) => {
        console.error('Error on getEmailAddresses', { error: err });
        toast.error(t('Error'));
      });
  };

  const onExportEmailAddresses = (types) => {
    downloadFile(JSON.stringify(types), 'emailAddresses', 'txt');
  };

  const onImportEmailAddresses = (e) => {
    const importToast = toast.loading('Importing email addresses');
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      api
        .jsonAPI()
        .post('email_addresses/import', { data: JSON.parse(text) })
        .then((res) => {
          setEmailAddresses(res.data);
          toast.success('Email addresses successfully imported');
        })
        .catch(() => {
          toast.error('Error while importing email addresses');
        })
        .finally(() => {
          uploadRef.current.value = '';
          toast.dismiss(importToast);
        });
    };
    reader.readAsText(e.target.files[0]);
  };

  const COLUMNS = [{ name: t('Email'), key: 'email' }];

  const userActions = [
    {
      link: '{id}',
      label: 'Edit',
      icon: 'edit',
    },
    {
      label: 'Delete',
      icon: 'trash',
      modal: true,
      modalConfig: {
        header: t('DeleteItem', { item: 'Email address' }),
        text: t('DeleteModalConfirmation'),
        onClick: (id) => deleteTemplate(id),
        yesButton: t('DeleteItemYes', { item: 'email address' }),
      },
    },
  ];

  useEffect(() => {
    getEmailAddresses();
  }, []);

  useEffect(() => {
    getEmailAddresses(filters);
  }, [filters]);

  const tableButtons = [
    {
      label: 'Refresh',
      type: 'secondary',
      icon: 'sync',
      handleClick: () => getEmailAddresses(filters),
    },
    {
      label: 'Import',
      type: 'secondary',
      icon: 'download',
      handleClick: () => uploadRef.current.click(),
    },
    {
      label: 'Export',
      type: 'secondary',
      icon: 'upload',
      handleClick: () => onExportEmailAddresses(emailAddresses),
    },
    { label: 'Create', type: 'primary', icon: 'add', link: 'new' },
  ];

  const deleteTemplate = (id) => {
    api
      .jsonAPI()
      .delete(`/email_addresses/${id}`)
      .then(() => {
        toast.success(t('ItemDeleted', { item: 'Template' }));
        getEmailAddresses();
      })
      .catch((err) => {
        console.error('Error deleting the template', { err });
        toast.error(t('ItemNotDeleted', { item: 'template' }));
      });
  };

  return (
    <>
      <input
        ref={uploadRef}
        type="file"
        style={{ display: 'none' }}
        onChange={onImportEmailAddresses}
      />
      <Table
        columns={COLUMNS}
        items={emailAddresses}
        totalItems={tableMetaData?.count ?? 0}
        buttons={tableButtons}
        actions={userActions}
        loading={loading}
        noItemsKey="No Templates"
        filters={filters}
        pages={tableMetaData?.last ?? 1}
        updateFilters={updateFilters}
      />
    </>
  );
}
