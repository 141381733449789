import api from '@services/axios';

const getSettings = async () => {
  return api
    .restAPI()
    .get('/settings')
    .then(({ data }) => data.data);
};

export { getSettings };
