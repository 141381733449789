import { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { CloseOutlined } from '@ant-design/icons';

import useClickOut from '@hooks/useClickOut';

import s from './OutputNotes.module.scss';

const OutputNotes = ({ modalTitle, text }) => {
  const [visible, setVisible] = useState(false);
  const { ref } = useClickOut(() => setVisible(false));
  return (
    <div className={s.wrapper} ref={ref}>
      <div
        className={s.button}
        onClick={() => setVisible((prevState) => !prevState)}
      >
        <Icon name="file alternate outline" size="large" />
      </div>
      {visible && (
        <div className={s.content}>
          <div className={s.contentHeader}>
            <div className={s.contentTitle}>{modalTitle}</div>
            <div
              onClick={() => {
                setVisible(false);
              }}
            >
              <CloseOutlined className={s.closeButton} />
            </div>
          </div>
          <div className={s.text}>{text}</div>
        </div>
      )}
    </div>
  );
};

export default OutputNotes;
