import i18n from '@locales/i18n';

export const parseId = (id) => (typeof id === 'string' ? parseInt(id, 10) : id);

export const capitalizeFirst = (string) => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const userName = (user) => {
  if (user && Object.prototype.hasOwnProperty.call(user, 'name')) {
    return user.name || user.email.split('@')[0];
  }
  return '';
};
export const teamNames = (teams = []) => {
  return teams && teams instanceof Array && teams.length > 0
    ? teams
        .map((team) => {
          return capitalizeFirst(team.description);
        })
        .join(', ')
    : i18n.t('NoTeams');
};

export const rolesNames = (roles = []) => {
  return roles && roles instanceof Array && roles.length > 0
    ? roles
        .map((role) => {
          return i18n.t(role);
        })
        .join(', ')
    : i18n.t('NoRoles');
};

export const applicationNames = (applications) => {
  return applications &&
    applications instanceof Array &&
    applications.length > 0
    ? applications.map((application) => application.name).join(', ')
    : i18n.t('NoApplications');
};

export const joinArrayToString = (elements) => {
  return elements && elements instanceof Array ? elements.join(', ') : '-';
};

export const fileName = (url) => {
  let name = '';

  if (typeof url === 'object') {
    name = url.name;
  } else if (typeof url === 'string') {
    name = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('?'));
  }

  return decodeURI(name);
};

export const displayFileName = (name) => {
  const S3_LINK = 'https://ww-raasportal.s3.eu-central';
  if (!name || typeof name !== 'string') return name;
  if (name.includes(S3_LINK)) return fileName(name);
  return name;
};

export const stringToBoolean = (value) => {
  if (value.toLowerCase() === 'true') return true;
  if (value.toLowerCase() === 'false') return false;
  return value;
};

export const formatToAmericanCurrency = (amount, displaySign = true) => {
  if ([undefined, null, ''].includes(amount)) return '';
  const formatter = new Intl.NumberFormat('en-US', {
    style: displaySign ? 'currency' : undefined,
    currency: displaySign ? 'USD' : undefined,
    maximumFractionDigits: 2,
    roundingIncrement: 1,
    displaySign,
  });

  return formatter.format(amount);
};
