import { isEmpty } from 'lodash';

import s from './styles.module.scss';

function NoFields() {
  return (
    <div className={s.documentFieldsList}>
      <p className={s.noFields}>This document has no fields to display.</p>
    </div>
  );
}

export default function Fields({ fields }) {
  if (!fields || isEmpty(fields)) return <NoFields />;

  return (
    <ul className={s.documentFieldsList}>
      {fields.map((field) => {
        const fieldValue = field?.transcription?.normalized;
        const isBlank = !fieldValue;
        const valueClassName = isBlank ? 'blankValue' : 'value';
        return (
          <li key={field?.id} className={s.fieldItem}>
            <p className={s.label}>{field.name}</p>
            <p className={s[valueClassName]}>
              {isBlank ? 'BLANK' : fieldValue}
            </p>
          </li>
        );
      })}
    </ul>
  );
}
