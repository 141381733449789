/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react';
import { Spin, Tag } from 'antd';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useDrop } from 'react-dnd';
import { Icon as SuiIcon } from 'semantic-ui-react';

import s from '@components/WorkItemCard/styles.module.scss';
import { statusColors } from '@constants';
import DocumentCard from '@components/DocumentCard/DocumentCard';
import AddDocumentButton from '@components/AddDocumentButton/AddDocumentButton';
import NewModal from '@components/Modal/NewModal';

const WorkItemCard = ({
  workItem,
  onDocumentDrop,
  onPageDrop,
  onWorkItemRemove,
  onAddDocument,
  onMovePageToNewDocument,
  onCloseDocument,
  workItems,
  onDownloadDocument,
  onWorkItemOpen,
  from,
  onPageClick,
  onDocumentTypeChange,
  defaultOpen = false,
  readOnly,
  onDocumentRemove,
  removable,
}) => {
  const [open, setOpen] = useState(defaultOpen || false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: readOnly ? '' : 'document',
      drop: (item) => {
        onDocumentDrop({
          documentId: item.id,
          newWorkItemId: workItem.id,
          oldWorkItemId: item.wiId,
          from: item.from,
          to: from,
        });
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [workItem, workItems],
  );

  const toggleCardClick = () => {
    if (!open && onWorkItemOpen && !removeModalVisible) {
      onWorkItemOpen(workItem);
    }
    if (!removeModalVisible) {
      setOpen(!open);
    }
  };

  const date = dayjs(workItem?.attributes?.receivedAt).format('MM/DD/YYYY');
  return (
    <div ref={drop} className={s.card} onClick={toggleCardClick} id="card">
      {removeModalVisible && (
        <NewModal
          maskClosable={false}
          onClick={(e) => {
            e.stopPropagation();
          }}
          title="Remove work item confirm"
          onCancel={(e) => {
            e.stopPropagation();
            setRemoveModalVisible(false);
          }}
          visible={removeModalVisible}
          onCrossClick={(e) => {
            e.stopPropagation();

            setRemoveModalVisible(false);
          }}
          onSubmit={(e) => {
            e.stopPropagation();
            setRemoveModalVisible(false);
            onWorkItemRemove({ id: workItem.id, from });
          }}
        >
          {`Do you want to delete work item ${workItem?.id}?`}
        </NewModal>
      )}
      <div className={s.cardHeader}>
        <div className={s.cardHeaderTitle}>
          Load number: {workItem?.attributes?.loadNumber || 'N/A'} (WI#
          {workItem.id})
        </div>
        <div className={s.cardHeader}>
          <Tag
            style={{ width: '116px', textAlign: 'center', marginRight: 24 }}
            color={statusColors.get(workItem?.attributes?.status?.name)}
          >
            {workItem?.attributes?.status?.caption}
          </Tag>
          {removable && (
            <SuiIcon
              name="trash"
              style={{
                cursor: 'pointer',
                color: '#918f8c',
              }}
              onClick={(e) => {
                e.stopPropagation();
                setRemoveModalVisible(true);
              }}
            />
          )}
          {open ? (
            <CaretRightOutlined
              style={{ color: '#918f8c' }}
              onClick={() => setOpen(!open)}
            />
          ) : (
            <CaretDownOutlined
              style={{ color: '#918f8c' }}
              onClick={() => {
                onWorkItemOpen(workItem);
                setOpen(!open);
              }}
            />
          )}
        </div>
      </div>
      <div className={s.cardInfo}>
        <div>Client: {workItem?.attributes?.outputFields?.client?.name}</div>
        <div>Batch# {workItem?.attributes?.batch?.data?.id}</div>
        <div>{date}</div>
      </div>
      <div className={s.cardMailInfo}>
        <div>
          Email: {workItem?.attributes?.batch?.data?.attributes?.email || 'N/A'}
        </div>
        <div>
          Subject:{' '}
          {workItem?.attributes?.batch?.data?.attributes?.subjectLine || 'N/A'}
        </div>
      </div>
      {workItem?.isLoading && (
        <div className={s.documentLoader}>
          <Spin />
        </div>
      )}
      {workItem?.attributes?.documents?.length > 0 && open && (
        <div className={s.documents}>
          {workItem.attributes.documents
            .filter(
              (document) =>
                !document?.discarded && document?.layout_name !== 'Audit Log',
            )
            .sort(
              (a, b) =>
                new Date(b?.order_date || b?.created_at || b?.updated_at) -
                new Date(a?.order_date || a?.created_at || a?.updated_at),
            )
            .map((document) => (
              <DocumentCard
                defaultOpen={defaultOpen}
                from={from}
                key={document.id}
                document={document}
                wiId={workItem.id}
                onPageDrop={onPageDrop}
                onCloseDocument={onCloseDocument}
                onDownloadDocument={onDownloadDocument}
                onPageClick={onPageClick}
                onDocumentTypeChange={onDocumentTypeChange}
                readOnly={readOnly}
                onRemove={onDocumentRemove}
              />
            ))}
        </div>
      )}

      {open && !workItem?.isLoading && !readOnly && (
        <AddDocumentButton
          wiId={workItem.id}
          from={from}
          onClick={() => onAddDocument({ wiId: workItem.id, from })}
          onMovePageToNewDocument={onMovePageToNewDocument}
        />
      )}
    </div>
  );
};

export default WorkItemCard;
