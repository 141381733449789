import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export default function TableLoader() {
  const { t } = useTranslation();
  return (
    <Loader active inline="centered" className="table-loader">
      {t('Loading')}
    </Loader>
  );
}
