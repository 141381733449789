import { Icon, Popup } from 'semantic-ui-react';

export default function NavButton({ popupText, onClick, next = false }) {
  const iconDirection = next ? 'right' : 'left';
  const icon = (
    <Icon
      name={`arrow circle ${iconDirection}`}
      className="handcursor"
      size="large"
      onClick={onClick}
    />
  );

  return (
    <Popup inverted trigger={icon}>
      {popupText}
    </Popup>
  );
}
