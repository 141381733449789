export const generateAddDocumentsOptions = ({
  canGenerateInvoice = false,
  canGenerateNoA = false,
}) => {
  return [
    {
      key: 'invoice',
      label: !canGenerateInvoice ? 'Regenerate Invoice' : 'Generate Invoice',
      // title: !canGenerateInvoice
      //   ? 'Work Item already has Factor Invoice, please delete existing Factor Invoice before adding new one.'
      //   : ' ',
      // disabled: !canGenerateInvoice,
    },
    // {
    //   key: 'noa',
    //   label: 'Generate NoA',
    //   disabled: !canGenerateNoA,
    // },
    {
      key: 'document',
      label: 'Upload document',
    },
  ];
};
