import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

export default function Card({ title, icon, path }) {
  return (
    <Link className="admin-card" to={path}>
      <Icon name={icon} size="huge" />
      <h2 className="title">{title}</h2>
    </Link>
  );
}
