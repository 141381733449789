import 'react-circular-progressbar/dist/styles.css';

import { Fragment } from 'react';
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import { Icon } from 'semantic-ui-react';
import { isEqual } from 'lodash';
import clsx from 'clsx';

import { STATUS_CARDS } from '@constants';

const WorkItemCards = ({ stats, filters = {}, updateFilters }) => {
  const nElements = (key) =>
    stats[key] && !Number.isNaN(stats[key]) ? parseInt(stats[key], 10) : 0;

  const percentageValue = (key) => {
    const total = Object.keys(stats).reduce((base, item) => {
      base += stats[item];
      return base;
    }, 0);

    if (!total || !stats[key]) return 0;

    const percentage = parseInt((stats[key] / total) * 100, 10);
    if (percentage === 0 && stats[key] > 0) return 1;

    return percentage;
  };

  const onCardClick = (cardFilter) => {
    const status = isEqual(filters['filter[status][]'], cardFilter)
      ? null
      : cardFilter;
    updateFilters({ 'filter[status][]': status, 'page[number]': 1 });
  };

  const isSelected = (cardFilter) => {
    const selected =
      !filters['filter[status][]'] ||
      isEqual(filters['filter[status][]'], cardFilter);

    if (!selected) {
      return ' unselected';
    }

    return '';
  };

  return (
    <Fragment>
      <div className="activity-cards-container status-card-container">
        {STATUS_CARDS.map((card) => {
          const percentage = percentageValue(card.status);
          const contents = nElements(card.status);

          return (
            <div
              className={clsx(
                'activity-card',
                'status-card',
                isSelected(card.title),
              )}
              key={card.status}
              onClick={() => onCardClick(card.title)}
              onKeyPress={onCardClick}
              role="button"
              tabIndex={0}
            >
              <div className="card-icon">
                <div className={`icon-circle ${card.status}`}>
                  <CircularProgressbarWithChildren
                    value={percentage}
                    styles={buildStyles({ pathColor: card.color })}
                  >
                    <Icon name={card.icon} size="small" fitted />
                  </CircularProgressbarWithChildren>
                </div>
              </div>
              <div className="card-info">
                <p className={card.status}>{contents}</p>
              </div>
              <div className="card-title">{card.title}</div>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
};

export default WorkItemCards;
