import { statusColors, WORK_ITEM_STATUSES } from '@constants';

export const clientScoreboardOptions = {
  isStacked: true,
  legend: { position: 'bottom' },
  legendTextStyle: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
  },
  chartArea: { width: '93%', height: '70%', top: '7%' },
  colors: [
    statusColors.get(WORK_ITEM_STATUSES.approved),
    statusColors.get(WORK_ITEM_STATUSES.finalReview),
    statusColors.get(WORK_ITEM_STATUSES.needsAttention),
    statusColors.get(WORK_ITEM_STATUSES.paused),
    statusColors.get(WORK_ITEM_STATUSES.rejected),
  ],
  vAxis: {
    gridlines: {
      count: 5,
    },
    textStyle: {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
  hAxis: {
    textStyle: {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
};

export const userProductivityOptions = {
  seriesType: 'bars',
  series: { 5: { type: 'line' } },
  chartArea: { width: '90%', height: '70%', top: '7%' },
  legendTextStyle: { fontFamily: 'Roboto', fontSize: 16, fontWeight: 400 },
  legend: { position: 'bottom' },
  hAxis: {
    title: 'Users',
    textStyle: {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
  vAxis: {
    gridlines: {
      count: '2',
    },
    format: '0',
    textStyle: {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
};

export const systemThroughputOptions = {
  isStacked: true,
  legend: { position: 'bottom' },
  chartArea: { width: '90%', height: '70%', top: '7%' },
  legendTextStyle: { fontFamily: 'Roboto', fontSize: 16, fontWeight: 400 },
  hAxis: {
    textStyle: {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
  vAxis: {
    format: '0',
    textStyle: {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
  colors: [
    statusColors.get(WORK_ITEM_STATUSES.approved),
    statusColors.get(WORK_ITEM_STATUSES.finalReview),
    statusColors.get(WORK_ITEM_STATUSES.needsAttention),
    statusColors.get(WORK_ITEM_STATUSES.paused),
    statusColors.get(WORK_ITEM_STATUSES.rejected),
  ],
};

export const valueOfCompletedWIOptions = {
  legend: { position: 'bottom' },
  chartArea: { width: '90%', height: '70%', top: '7%' },
  legendTextStyle: { fontFamily: 'Roboto', fontSize: 16, fontWeight: 400 },
  hAxis: {
    textStyle: {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
  vAxis: {
    format: '0',
    textStyle: {
      fontSize: 14,
      fontFamily: 'Roboto',
    },
  },
  colors: [
    '#007BFF',
    '#20C997',
    '#6610F2',
    '#DC3545',
    '#FFC107',
    '#28A745',
    '#E83E8C',
    '#6E1B23',
    '#ED9AA2',
    '#2474BB',
  ],
};
