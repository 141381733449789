import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { Fragment, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import clsx from 'clsx';
import { Button, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { string } from 'yup';

import ErrorIcon from '@assets/icons/error_circle.svg';
import s from '@components/ResetPassword/ResetPassword.module.scss';
import api from '@services/axios';
import { ReactComponent as Logo } from '@assets/icons/login_logo.svg';

const NewPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const loginSchema = Yup.object().shape({
    password: string()
      .required('Password is required field')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
      ),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Password confirmation is required field'),
  });

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    api
      .restAPI()
      .post(
        `users/update_password?token=${token}&password=${encodeURIComponent(
          values.password,
        )}&password_confirmation=${encodeURIComponent(
          values.password_confirmation,
        )}`,
      )
      .then(() => {
        setSuccess(true);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.warn('[Login] err.message: ', err);
        setStatus({
          serverError:
            err?.response?.data?.messages.join(', ') || 'Something went wrong',
        });
      })
      .finally(() => setSubmitting(false));
  };
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return (
    <Fragment>
      <div className={s.authWrapper}>
        <div className={s.auth}>
          <div className={s.logoWrapper}>
            <Logo />
          </div>
          {!success && (
            <Fragment>
              <div className={s.authTitle}>Reset password</div>
              <div className={s.authDetails}>Please enter new password.</div>
            </Fragment>
          )}
          {success && (
            <div className={s.success}>
              <div className={s.successDetails}>
                Password changed successfully
              </div>
            </div>
          )}
          {!success && (
            <div className={s.authForm}>
              <Formik
                initialValues={{
                  password: '',
                  password_confirmation: '',
                }}
                validationSchema={loginSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, isValid, errors, touched, status }) => {
                  return (
                    <Form>
                      <div
                        className={clsx(s.fieldWrapper, {
                          [s.fieldError]: errors.password && touched.password,
                        })}
                      >
                        <Field
                          type="password"
                          name="password"
                          className={s.password}
                          placeholder={t('Enter your password')}
                        />
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className={s.errorText}
                      />
                      <div
                        className={clsx(s.fieldWrapper, s.fieldPassword, {
                          [s.fieldError]:
                            errors.password_confirmation &&
                            touched.password_confirmation,
                        })}
                      >
                        <Field
                          type="password"
                          name="password_confirmation"
                          className={s.email}
                          placeholder={t('Confirm your password')}
                        />
                      </div>
                      <ErrorMessage
                        name="password_confirmation"
                        component="div"
                        className={s.errorText}
                      />
                      <div>
                        <div className={s.serverErrorWrapper}>
                          {status?.serverError && (
                            <img
                              src={ErrorIcon}
                              alt="error icon"
                              className={s.errorIcon}
                            />
                          )}

                          <div className={s.errorText}>
                            {status?.serverError && status.serverError}
                          </div>
                        </div>
                        <button
                          type="submit"
                          className={s.submitButton}
                          disabled={!isValid || isSubmitting}
                        >
                          {isSubmitting ? <Spin /> : t('Change password')}
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          )}
          <div className={clsx(s.backToSignIn)}>
            <Button
              type="button"
              className={clsx(s.backToSignInButton)}
              onClick={() => navigate('/login')}
            >
              {t('Back to Sign in')}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewPassword;
