import { DatesRangeInput } from 'semantic-ui-calendar-react-yz';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { convertToDatePickerFormat } from '@services/date';
import useCurrentUser from '@hooks/useCurrentUser';

const DATE_FORMAT = 'dd-MM-yyyy';

export default function DateFilter({
  column,
  onChange,
  filters,
  dateFormat = DATE_FORMAT,
}) {
  const { currentUser } = useCurrentUser();
  const { t } = useTranslation();

  const key = column.searchKey ?? column.key;
  const datePickerDateFormat = convertToDatePickerFormat(dateFormat);
  const { timezone } = currentUser;

  const appendDates = (date, _key) => {
    const applyTimezone = (d) =>
      DateTime.fromFormat(d, dateFormat, { zone: timezone }).toString();

    const applyEndOfDayTimezone = (d) =>
      DateTime.fromFormat(d, dateFormat, { zone: timezone })
        .endOf('day')
        .toString();

    const keyFrom = `filter[${_key}From]`;
    const keyTo = `filter[${_key}To]`;
    const splitDate = date.split(' - ');
    const from = splitDate[0] ? applyTimezone(splitDate[0]) : undefined;
    const to = splitDate[1] ? applyEndOfDayTimezone(splitDate[1]) : undefined;

    return { [keyFrom]: from, [keyTo]: to };
  };

  const formatDateForInput = (from, to) => {
    const formatDate = (date) => {
      const dateTimeObject = DateTime.fromISO(date).setZone(timezone);
      return dateTimeObject.toFormat(dateFormat);
    };
    // DateTime.fromISO(date, { zone: 'utc' }).toFormat(dateFormat)
    if (!from && !to) return '';
    if (!to) {
      return formatDate(from);
    }
    const timezonedFrom = formatDate(from);
    const timezonedTo = formatDate(to);

    return `${timezonedFrom} - ${timezonedTo}`;
  };
  return (
    <form autoComplete="off">
      <DatesRangeInput
        popupPosition="bottom center"
        fluid
        dateFormat={datePickerDateFormat}
        name="key"
        placeholder={t('SearchPlaceholder')}
        value={formatDateForInput(
          filters[`filter[${key}From]`],
          filters[`filter[${key}To]`],
        )}
        iconPosition="right"
        closable
        clearable
        animation="none"
        style={{ height: 32, fontSize: 14 }}
        allowSameEndDate
        onChange={(e, { value }) => onChange(appendDates(value, key))}
      />
    </form>
  );
}
