import {
  GET_WORK_ITEM,
  SET_WORK_ITEM,
  RESET_WORK_ITEM,
  UPDATE_OUTPUT_FIELDS,
  UPDATE_RULES,
  UPDATE_ALERTS,
  UPDATE_STATUS,
  UPDATE_EDITED_FIELD,
  SET_ADDITIONAL_FEES_OPTIONS,
  SET_APPROVAL_OVERRIDE_OPTIONS,
} from '@constants/action-types';

const defaultState = {
  workItem: {},
  editedField: {},
  additionalFeesOptions: [],
};

export default (state = defaultState, action) => {
  if (action.type === SET_WORK_ITEM) {
    return { ...state, workItem: action.payload };
  }
  if (action.type === GET_WORK_ITEM) {
    return { ...state };
  }
  if (action.type === UPDATE_OUTPUT_FIELDS) {
    return {
      ...state,
      workItem: {
        ...state.workItem,
        outputFields: action.payload,
      },
    };
  }
  if (action.type === RESET_WORK_ITEM) {
    return {
      workItem: {},
      editedField: {},
      additionalFeesOptions: [...(state?.additionalFeesOptions || [])],
    };
  }
  if (action.type === UPDATE_RULES) {
    return {
      ...state,
      workItem: {
        ...state.workItem,
        rules: action.payload,
      },
    };
  }
  if (action.type === UPDATE_RULES) {
    return {
      ...state,
      workItem: {
        ...state.workItem,
        rules: action.payload,
      },
    };
  }
  if (action.type === UPDATE_ALERTS) {
    return {
      ...state,
      workItem: {
        ...state.workItem,
        alerts: action.payload,
      },
    };
  }
  // update status
  if (action.type === UPDATE_STATUS) {
    return {
      ...state,
      workItem: {
        ...state.workItem,
        status: action.payload,
      },
    };
  }
  if (action.type === UPDATE_EDITED_FIELD) {
    return {
      ...state,
      editedField: {
        field: action.payload,
      },
    };
  }
  if (action.type === SET_ADDITIONAL_FEES_OPTIONS) {
    return {
      ...state,
      additionalFeesOptions: action.payload,
    };
  }
  if (action.type === SET_APPROVAL_OVERRIDE_OPTIONS) {
    return {
      ...state,
      approvalOverrideOptions: action.payload,
    };
  }
  return state;
};
