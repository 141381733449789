/* eslint-disable no-unused-expressions */
import { Checkbox, Table } from 'semantic-ui-react';

import TableCell from './TableCell';

export default function TableRow(props) {
  const {
    item,
    columns,
    actions,
    containerRef,
    selectedItems,
    disableScrollToView,
  } = props;
  const itemSelected = selectedItems && selectedItems.includes(item.id);
  const isSelected = props.selectedItemId === item.id;

  const onCheckboxChange = (evt) => {
    evt.stopPropagation();
    typeof props.onItemSelect === 'function' && props.onItemSelect(item);
  };

  // move the line below inside the Table.row className to block processing items:
  //  ${item.status === 'Processing' && 'forbidden-click'}

  const rowClassName = () => {
    const classes = [];

    if (isSelected) {
      classes.push('selected-row');
    }

    if (typeof props.onRowClick === 'function') {
      classes.push('clickable-row');
    }

    return classes.length > 0 ? classes.join(' ') : 'clickable-row';
  };

  const className = rowClassName();

  return (
    <Table.Row className={className} key={item.id}>
      {selectedItems && (
        <Table.Cell>
          <Checkbox
            checked={itemSelected || props.selectedAll}
            onChange={onCheckboxChange}
            disabled={props.selectedAll}
          />
        </Table.Cell>
      )}
      {columns.map((column, index, self) => (
        <TableCell
          key={`${item.id}_${column.tableKey ?? column.key}`}
          actions={actions}
          column={column}
          item={item}
          index={index}
          row={self}
          containerRef={containerRef}
          setModalConfig={props.setModalConfig}
          onClick={props.onRowClick}
          selectedItemId={props.selectedItemId}
          disableScrollToView={disableScrollToView}
        />
      ))}
    </Table.Row>
  );
}
