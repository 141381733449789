// import { useState, useEffect } from 'react';
// import Jsona from 'jsona';

// import { documentViewerMock } from '@components/WorkItemsList/mockWorkItems';
import Hyperscience from '@components/aria/Application/Activity/Hyperscience/Hyperscience';

const PDFViewerWrapper = ({
  workItem,
  disableRightEvents,
  disableLeftEvents,
  tabsClassName,
  readOnly,
}) => {
  return (
    <>
      <Hyperscience
        activity={workItem}
        app="HyperScience"
        getActivity={() => null}
        disableRightEvents={disableRightEvents}
        disableLeftEvents={disableLeftEvents}
        tabsClassName={tabsClassName}
        readOnly={readOnly}
      />
    </>
  );
};

export default PDFViewerWrapper;
