import { Icon } from 'semantic-ui-react';

import Tooltip from '../Tooltip';

export default function ColumnIcon({ item, column }) {
  const color = column.config.color(item[column.key]);
  const message = column.config.message(item[column.key]);

  const icon = <Icon name={column.config.icon} color={color} />;

  if (message) {
    return <Tooltip text={message} node={icon} />;
  }

  return icon;
}
