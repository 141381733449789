import { Navigate, Outlet } from 'react-router-dom';

import useAuthToken from '@hooks/useAuthToken';

const ProtectedRoute = ({ redirectPath = '/login' }) => {
  const { authToken } = useAuthToken();

  function hasJWT() {
    if (authToken) return true;
    return false;
  }

  if (!hasJWT()) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
