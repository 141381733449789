import SelectFromEndpoint from '@components/aria/UI/SelectFromEndpoint';

export default function SelectFromEndpointFilter({
  column,
  filterValue,
  onChange,
}) {
  const { keys } = column;

  const updateFilter = (selected) => {
    onChange(selected?.value);
  };
  const searchKey = column.searchKey ?? column.key;

  return (
    <SelectFromEndpoint
      value={filterValue}
      setValue={updateFilter}
      endpoint={column.url}
      keys={keys}
      isClearable
      removePagination={column.removePagination}
      applyFilters={column.applyFilters}
      filterToRemove={column.applyFilters ? searchKey : undefined}
      loadOnInit={!!filterValue}
    />
  );
}
