import {
  ActionCableConsumer,
  ActionCableProvider,
} from '@thrash-industries/react-actioncable-provider';

import config from '@config';

const ActionCableContainer = ({ active, channel, onReceived, token }) => {
  if (!active) return null;
  const url = `${config.api.url.ws}/cable?token=${token}`;

  return (
    <ActionCableProvider url={url}>
      <ActionCableConsumer channel={channel} onReceived={onReceived} />
    </ActionCableProvider>
  );
};

export default ActionCableContainer;
