/* eslint-disable jsx-a11y/label-has-associated-control */
import { ErrorMessage, Formik } from 'formik';
import Jsona from 'jsona';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Checkbox, Form, Input } from 'semantic-ui-react';
import * as Yup from 'yup';

import api from '@services/axios';

export default function AlertsForm({ alert, loading }) {
  const navigate = useNavigate();
  const { id: alertId } = useParams();
  const { t } = useTranslation();
  const dataFormatter = new Jsona();
  const prepareData = (formData) => {
    const data = {
      name: formData?.name,
      hsName: formData.hsName,
      visible: formData.visible,
    };
    return {
      type: 'alerts',
      id: alert?.id ?? null,
      ...data,
    };
  };

  const onSubmitHandler = (formValues, { setSubmitting }) => {
    const preparedData = dataFormatter.serialize({
      stuff: prepareData(formValues),
    });
    const saveFunction = alertId
      ? api.jsonAPI().put(`/alerts/${alertId}`, preparedData)
      : api.jsonAPI().post('alerts', preparedData);
    setSubmitting(true);
    saveFunction
      .then(() => {
        const toastText = alertId
          ? t('ItemSaved', { item: 'Alert' })
          : t('TemplateCreated', { name: formValues.title });
        toast.success(toastText);
        navigate('/admin/alerts');
      })
      .catch((err) => {
        console.error('Error on form submission', { err });
        toast.error(t('ItemNotSaved', { item: 'alert' }));
      })
      .finally(() => setSubmitting(false));
  };

  const displayError = (errors, touched, key) => {
    return errors[key] && touched[key];
  };
  return (
    <Formik
      initialValues={{
        name: alert?.name ?? '',
        hsName: alert?.hsName ?? '',
        visible: alert?.visible ?? false,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('ItemRequired', { item: 'Name' })),
        hsName: Yup.string().required(t('ItemRequired', { item: 'HS name' })),
      })}
      enableReinitialize
      onSubmit={onSubmitHandler}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
      }) => (
        <Form
          loading={loading}
          onSubmit={handleSubmit}
          className="form-container"
        >
          <div className="users-form">
            <Form.Field
              required
              className="inline-field"
              error={displayError(errors, touched, 'hsName')}
            >
              <label>{t('HS name')}</label>
              <div className="text-input">
                <Input
                  name="hsName"
                  onChange={handleChange}
                  placeholder={t('ItemAnPlaceholder', {
                    item: 'hsName',
                  })}
                  value={values.hsName}
                />
                <ErrorMessage
                  name="hsName"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <Form.Field
              required
              className="inline-field"
              error={displayError(errors, touched, 'name')}
            >
              <label>{t('Name')}</label>
              <div className="text-input">
                <Input
                  name="name"
                  onChange={handleChange}
                  placeholder={t('ItemAnPlaceholder', {
                    item: 'name',
                  })}
                  value={values.name}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <Form.Field
              required
              className="inline-field"
              error={displayError(errors, touched, 'visible')}
            >
              <label>{t('Visible')}</label>
              <div className="text-input">
                <Checkbox
                  id="visible"
                  name="visible"
                  onChange={handleChange}
                  checked={values.visible}
                />
                <ErrorMessage
                  name="visible"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <div className="submit-button">
              <Button
                basic
                as={Link}
                to="/admin/alerts"
                disabled={isSubmitting}
              >
                {t('Cancel')}
              </Button>
              <Button
                primary
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {t('Submit')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
