import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { CloseOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Lightbox } from 'react-modal-image';
import withScrolling from 'react-dnd-scrolling';

import s from '@components/GroupingNew/GroupingNew.module.scss';
import {
  addNewWorkItemAction,
  changeDocumentType,
  getDocumentsTypes,
  getWorkItemByFilter,
  getWorkItemById,
  getWorkItemDocumentsById,
  onAddUnassignedDocumentAction,
  onDocumentDropAction,
  onMovePageToNewDocumentAction,
  onPageDropAction,
  onRemoveWorkItemAction,
  removeDocument,
  resetGrouping,
  resetWorkItem,
} from '@actions';
import WorkItemCard from '@components/WorkItemCard/WorkItemCard';
import useFilters from '@hooks/useFilters';
import GroupingFilters from '@components/GroupingNew/components/GroupingFilters/GroupingFilters';
import {
  generatePdfFromDoc,
  getFiltersOptions,
} from '@components/GroupingNew/helpers';
import { WORK_ITEM_STATUSES } from '@constants';
import api from '@services/axios';
import {
  LOAD_GROUPING_WORK_ITEMS_ERROR,
  LOAD_GROUPING_WORK_ITEMS_SUCCESS,
} from '@constants/action-types';

const ScrollingComponent = withScrolling('div');

const GroupingNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const workItem = useSelector((state) => state?.workItemReducer?.workItem);
  const currentBatch = useSelector(
    (state) => state?.groupingReducer?.batch?.data,
  );
  const currenWorkItems = useSelector(
    (state) => state?.groupingReducer?.workItems?.data,
  );
  const isWorkItemsLoading = useSelector(
    (state) => state?.groupingReducer?.workItems?.isLoading,
  );
  const isBatchLoading = useSelector(
    (state) => state?.groupingReducer?.batch?.isLoading,
  );

  const readOnly =
    workItem?.status?.name === WORK_ITEM_STATUSES.rejected ||
    workItem?.status?.name === WORK_ITEM_STATUSES.approved ||
    workItem?.status?.name === WORK_ITEM_STATUSES.error ||
    workItem?.status?.name === WORK_ITEM_STATUSES.completed;

  const [filtersData, setFiltersData] = useState({
    batchOptions: [],
    emailOptions: [],
    loadNumbersOptions: [],
    subjectLineOptions: [],
  });
  const [selectedPage, setSelectedPage] = useState({});
  const [filters, updateFilters] = useFilters({
    grouping: true,
  });

  useEffect(() => {
    return () => {
      dispatch(resetWorkItem());
      dispatch(resetGrouping());
      api
        .jsonAPI()
        .post(`/work_items/${id}/close_grouping`)
        .catch((err) => {
          console.log(err);
        });
    };
  }, [dispatch]);

  const { id } = useParams();
  const onWorkItemOpen = (wi) => {
    if (wi.attributes.documents === undefined) {
      dispatch(getWorkItemDocumentsById(wi.id));
    }
  };

  useEffect(() => {
    dispatch(getWorkItemById(id));
  }, [id]);

  useEffect(() => {
    dispatch(getDocumentsTypes());
    api
      .restAPI()
      .get(`/work_items`, { params: { grouping: true } })
      .then((res) => {
        const {
          batchOptions,
          emailOptions,
          loadNumbersOptions,
          subjectLineOptions,
        } = getFiltersOptions(res.data.data);
        setFiltersData({
          batchOptions,
          emailOptions,
          loadNumbersOptions,
          subjectLineOptions,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (filters?.['filter[status]']) {
      dispatch(getWorkItemByFilter(filters));
    }
  }, [filters]);

  useEffect(() => {
    if (currentBatch?.id) {
      updateFilters({
        'filter[batchId]': [Number(currentBatch?.id)],
        'filter[status]': ['Needs attention', 'Paused', 'Replied'],
      });
    }
  }, [currentBatch?.id]);

  const onDocumentDrop = (dragInfo) => {
    if (dragInfo.newWorkItemId !== dragInfo.oldWorkItemId) {
      dispatch(onDocumentDropAction(dragInfo));
    }
  };

  const onAddUnassignedDocument = ({ wiId, from }) => {
    dispatch(onAddUnassignedDocumentAction({ wiId, from }));
  };

  const onMovePageToNewDocument = (dragInfo) => {
    dispatch(onMovePageToNewDocumentAction(dragInfo));
  };

  const onPageDrop = (dragInfo) => {
    dispatch(onPageDropAction(dragInfo));
  };

  const onWorkItemRemove = (wi) => {
    if (wi.id && wi.from) {
      dispatch(onRemoveWorkItemAction(wi));
    }
  };

  const onAddNewWorkItem = () => {
    dispatch(addNewWorkItemAction(id));
  };

  if (isBatchLoading) {
    return (
      <div className={s.loading}>
        <Spin size="large" />
      </div>
    );
  }

  const onPageClick = (page) => {
    setSelectedPage(page);
  };

  const onClosePage = () => {
    dispatch(resetWorkItem());
    dispatch(resetGrouping());
    navigate(`/workitems/${workItem.id}`);
  };
  const onDocumentTypeChange = ({ selectedType, documentId, wiId, from }) => {
    dispatch(changeDocumentType({ selectedType, documentId, wiId, from }));
  };
  const onDocumentRemove = (removedDocId, from, wiId) => {
    dispatch(removeDocument(removedDocId, from, wiId));
  };
  return (
    <div className={s.wrapper}>
      {selectedPage.src && (
        <Lightbox
          сlassName={s.lightbox}
          medium={selectedPage.image_url}
          // showRotate
          onClose={() => setSelectedPage({})}
        />
      )}
      <div className={s.header}>
        <div className={s.headerLeft}>
          <div className={s.title}>
            grouping (batch#{workItem?.attributes?.batch?.data?.id})
          </div>
          <div className={s.companyName}>
            {workItem?.attributes?.outputFields?.client?.name}
          </div>
          <div className={s.wiNumber}>WI#{workItem?.id}</div>
        </div>
        <CloseOutlined className={s.headerClose} onClick={onClosePage} />
      </div>
      <div className={s.content}>
        <ScrollingComponent className={s.contentLeft}>
          <div className={s.workItems}>
            {currentBatch?.workItems?.data?.length > 0 &&
              currentBatch?.workItems?.data
                .filter((wi) => wi.id === workItem.id)
                .map((mappedWorkItem) => (
                  <WorkItemCard
                    from="leftSide"
                    defaultOpen
                    onWorkItemOpen={() => {}}
                    key={mappedWorkItem.id}
                    workItem={mappedWorkItem}
                    onDocumentDrop={onDocumentDrop}
                    onPageDrop={onPageDrop}
                    onWorkItemRemove={onWorkItemRemove}
                    onAddDocument={onAddUnassignedDocument}
                    onMovePageToNewDocument={onMovePageToNewDocument}
                    workItems={currentBatch?.workItems?.data}
                    onDownloadDocument={generatePdfFromDoc}
                    onPageClick={onPageClick}
                    onDocumentTypeChange={onDocumentTypeChange}
                    readOnly={readOnly}
                    onDocumentRemove={onDocumentRemove}
                  />
                ))}
          </div>
        </ScrollingComponent>
        <div className={s.contentRight}>
          <div className={s.filtersHeader}>
            <GroupingFilters
              filtersData={filters}
              batchOptions={filtersData.batchOptions}
              emailsOptions={filtersData.emailOptions}
              loadNumbersOptions={filtersData.loadNumbersOptions}
              subjectLineOptions={filtersData.subjectLineOptions}
              updateFilters={updateFilters}
            />
            <Button
              type="button"
              className={s.addButton}
              onClick={onAddNewWorkItem}
            >
              Add Work Item
            </Button>
          </div>

          <ScrollingComponent className={s.workItems}>
            {!currenWorkItems?.filter((wi) => wi.id !== workItem.id)?.length &&
              !isWorkItemsLoading && (
                <div className={s.loadingWrapper}>
                  No Work Items found those match search criteria
                </div>
              )}
            {isWorkItemsLoading && (
              <div className={s.loadingWrapper}>
                <Spin />
              </div>
            )}
            {currenWorkItems?.length > 0 &&
              [...currenWorkItems]
                .filter(
                  (currentWorkItem) =>
                    parseInt(currentWorkItem.id, 10) !==
                      parseInt(workItem.id, 10) &&
                    !currentWorkItem?.attributes?.discardedAt?.length,
                )
                .map((currentWorkItem) => (
                  <WorkItemCard
                    from="rightSide"
                    key={currentWorkItem.id}
                    workItem={currentWorkItem}
                    onDocumentDrop={onDocumentDrop}
                    onPageDrop={onPageDrop}
                    onWorkItemRemove={onWorkItemRemove}
                    onAddDocument={onAddUnassignedDocument}
                    onMovePageToNewDocument={onMovePageToNewDocument}
                    onWorkItemOpen={onWorkItemOpen}
                    workItems={currenWorkItems}
                    onDownloadDocument={generatePdfFromDoc}
                    onPageClick={onPageClick}
                    onDocumentTypeChange={onDocumentTypeChange}
                    onDocumentRemove={onDocumentRemove}
                    removable
                  />
                ))}
          </ScrollingComponent>
        </div>
      </div>
    </div>
  );
};

export default GroupingNew;
