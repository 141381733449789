import { Icon } from 'semantic-ui-react';

import Tooltip from '@ui/Tooltip';

export default function ColumnAction({ action, item, column }) {
  const onClickHandler =
    typeof column.config.callback === 'function'
      ? () => column.config.callback(item[column.config.key], action.type)
      : undefined;

  const icon = (
    <Icon
      className="handcursor"
      name={action.icon}
      color={action.color}
      onClick={onClickHandler}
    />
  );

  if (action.message) {
    return <Tooltip text={action.message} node={icon} />;
  }

  return icon;
}
