import { useLocation, useNavigate } from 'react-router';

import useBreadcrumb from '@hooks/useBreadcrumb';
import { isDesktop } from '@services/general';
import Upperbar from '@components/aria/UI/Upperbar/Upperbar';

export default function AdminUpperbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { breadcrumb } = useBreadcrumb();

  const goBack = () => {
    const currentLocation = location.pathname;
    const urlToGo = currentLocation.includes('widget')
      ? '/admin/dashboard'
      : currentLocation.substring(0, currentLocation.lastIndexOf('/'));
    navigate(urlToGo);
  };

  const onCancelHandler = location.pathname === '/admin' ? null : goBack;

  if (!isDesktop() || location.pathname === '/admin') return null;
  return <Upperbar title={breadcrumb} onCancel={onCancelHandler} />;
}
