const defaultProfile = `${process.env.PUBLIC_URL}/default_profile.jpg`;

export default function Avatar({ name, imageUrl }) {
  const auxImageUrl = imageUrl || defaultProfile;
  return (
    <div className="avatar-container">
      <div
        className="ui avatar image"
        style={{
          backgroundImage: `url(${auxImageUrl})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
      />
      {name && <span className="username">{name}</span>}
    </div>
  );
}
