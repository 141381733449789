import { Icon } from 'semantic-ui-react';

import Status from '@components/aria/Application/ApplicationConfiguration/Status';

import NavSection from './NavSection';

export default function Upperbar(props) {
  return (
    <div className="d-flex-center-sb UpperBar-container">
      <div className="title-container">
        <div className="title">
          <span className="title-text">{props.title}</span>
          {props.subtitle && <span className="subtitle">{props.subtitle}</span>}
        </div>
        {props.status && <Status status={props.status} />}
      </div>
      <div className="nav-container">
        <NavSection
          prevItem={props.prevItem}
          onPrevClick={props.onPrevClick}
          nextItem={props.nextItem}
          onNextClick={props.onNextClick}
          loading={props.loadingNavigation}
        />
        {props.onCancel && (
          <div
            role="button"
            tabIndex={0}
            onClick={props.onCancel}
            onKeyPress={props.onCancel}
            className="back-button d-flex-center-c"
          >
            <Icon name="times" />
          </div>
        )}
      </div>
    </div>
  );
}
