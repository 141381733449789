import { Modal } from 'antd';
import { Button } from 'semantic-ui-react';

import s from '@components/Modal/NewModel.module.scss';

const NewModal = ({
  title,
  visible,
  onCrossClick,
  onCancel,
  onSubmit,
  children,
  submitButtonText = 'Submit',
  cancelButtonText = 'Cancel',
  closable = true,
  onClick,
  maskClosable,
}) => {
  return (
    <Modal
      onClick={onClick}
      open={visible}
      onCancel={onCrossClick}
      centered
      destroyOnClose
      closable={closable}
      title={title}
      maskClosable={maskClosable}
      okText="text"
      footer={[
        <Button key="submit" onClick={onCancel}>
          {cancelButtonText}
        </Button>,
        <Button
          key="link"
          type="button"
          className={s.submitRemoveButton}
          onClick={onSubmit}
        >
          {submitButtonText}
        </Button>,
      ]}
    >
      {children}
    </Modal>
  );
};

export default NewModal;
