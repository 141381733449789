import { Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
// https://react-slick.neostack.com/docs/example/simple-slider
import { Carousel } from 'antd';
import { useState } from 'react';
import clsx from 'clsx';

import s from './styles.module.scss';

const Invoice = `${process.env.PUBLIC_URL}/invoice.png`;

const DocumentsHeader = ({ groupId }) => {
  const { t } = useTranslation();

  return (
    <div className={s.groupHeaderWrapper}>
      <div className={s.idNumber}>Group 33{groupId}</div>
      <div className="groupStatus">
        <Button primary type="button" className={s.statusButton}>
          {t('Processing')}
        </Button>
      </div>
    </div>
  );
};
/**
 * TODO: on img click we should show change pdf viewer page currently displaying
 * TODO: if status "Processing" then we can't click on img
 */

const DocumentsBody = ({ slides }) => {
  return (
    <div className={clsx('batchCarousel', s.groupBodyWrapper)}>
      <Carousel slidesToShow={4.4} draggable dots={false} infinite={false}>
        {slides.map((slide) => (
          <div key={slide}>
            <img src={Invoice} alt="invoice" style={{ width: 100 }} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

const Document = ({ slides, groupId }) => {
  return (
    <div className={s.documentWrapper}>
      <DocumentsHeader groupId={groupId} />
      <DocumentsBody slides={slides} />
    </div>
  );
};

const documentsMock = [{ id: 0, slides: [1, 2, 3, 4, 5, 6, 7, 8] }];

const Documents = () => {
  const [documents, setDocuments] = useState(documentsMock);
  const { t } = useTranslation();

  const handleAppGroup = () => {
    setDocuments((prev) => {
      return [...prev, { id: prev[prev.length - 1].id + 1, slides: [] }];
    });
  };

  return (
    <div className={s.groupsWrapper}>
      <div className={s.documentsWrapper}>
        {documents.map((document) => (
          <Document
            key={document.id}
            slides={document.slides}
            groupId={document.id}
          />
        ))}
      </div>
      <div className="buttonWrapper">
        <Button
          type="button"
          onClick={handleAppGroup}
          className={clsx('addGroupButton', s.addGroupButton)}
        >
          <Icon name="plus" size="small" />
          {t('Add group')}
        </Button>
      </div>
    </div>
  );
};

export default Documents;
