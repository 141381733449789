import { Icon, Table } from 'semantic-ui-react';
import { cloneDeep } from 'lodash';

import useHyperscience from '@hooks/useHyperscience';

import ActionsCell from './ActionsCell';
import HSTableCell from './HSTableCell';

const emptyCell = ({ id = null, rowId = null, pageId = null, name = '' }) => ({
  id,
  column_name: name,
  document_table_row_id: rowId,
  page_id: pageId,
  raw: null,
  normalized: '',
  exceptions: [],
  state: null,
});

export default function HSTableRow({ row, table, columnNames }) {
  const { selectedField, editable } = useHyperscience();
  const exceptions = row.cells.some((cell) => cell?.exceptions?.length > 0);
  const rowIsSelected =
    selectedField && row.cells.some((cell) => cell.id === selectedField);

  const fillEmptyCells = (_row) => {
    if (_row.cells.length === columnNames.length) return _row;
    const auxRow = cloneDeep(_row);
    const rowCellNames = auxRow.cells.map((cell) => cell.column_name);
    const missingRowCells = columnNames.filter(
      (name) => !rowCellNames.includes(name),
    );
    if (missingRowCells.length === 0) return _row;

    missingRowCells.forEach((name) => {
      const newCellParams = {
        id: `row_${auxRow.id}_cell_${name}`,
        rowId: auxRow.id,
        pageId: auxRow.page_id,
        name,
      };
      const newCell = emptyCell(newCellParams);
      auxRow.cells.push(newCell);
    });
    return auxRow;
  };

  const correctedRow = fillEmptyCells(row);

  const sortCells = (a, b) => {
    const firstNameIndex = columnNames.findIndex(
      (name) => name === a.column_name,
    );
    const secondNameIndex = columnNames.findIndex(
      (name) => name === b.column_name,
    );
    return firstNameIndex < secondNameIndex ? -1 : 1;
  };

  const sortedCells = correctedRow.cells.sort(sortCells);

  return (
    <Table.Row
      key={`row_${row.id}`}
      className={rowIsSelected ? 'selected-row' : null}
    >
      <Table.Cell>
        {!correctedRow.isNew && (
          <Icon
            name="circle"
            size="small"
            color={exceptions ? 'orange' : 'green'}
          />
        )}
      </Table.Cell>
      {sortedCells.map((cell) => (
        <HSTableCell key={`cell_${cell.id}`} cell={cell} table={table} />
      ))}
      {editable ? (
        <ActionsCell row={row} table={table} columnNames={columnNames} />
      ) : null}
    </Table.Row>
  );
}
