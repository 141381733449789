import { Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default function TableButton({ button }) {
  return (
    <Button
      primary={button.type === 'primary'}
      basic={button.type === 'secondary'}
      color="blue"
      disabled={button.disabled || button.loading}
      loading={button.loading}
      onClick={button.link ? undefined : button.handleClick}
      as={button.link ? Link : undefined}
      to={button.link ?? undefined}
    >
      {button.icon && <Icon name={button.icon} />}
      {button.label}
    </Button>
  );
}
