import Sidebar from '@components/aria/Sidebar/Sidebar';
import WithCurrentUser from '@services/WithCurrentUser';
import Dashboard from '@components/Dashboard';

const DocumentPage = () => {
  return (
    <WithCurrentUser>
      <Sidebar>
        <Dashboard />
      </Sidebar>
    </WithCurrentUser>
  );
};

export default DocumentPage;
