import { Dropdown } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

export default function TableDropdownItem({ item, action, setModalConfig }) {
  let link;
  if (action.link) {
    link = action.link.includes('{id}')
      ? action.link.replace('{id}', item.id)
      : action.link;
  }

  const handleClick = () => {
    if (link) return undefined;
    if (action.modal) {
      return setModalConfig({ ...action.modalConfig, item });
    }
    return action.onClick(item.id);
  };

  return (
    <Dropdown.Item
      icon={action.icon}
      text={action.label}
      onClick={handleClick}
      as={link ? Link : undefined}
      to={link}
    />
  );
}
