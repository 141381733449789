import { useState, useRef } from 'react';
import { Dropdown, Ref } from 'semantic-ui-react';

import TableDropdownItem from './TableDropdownItem';

const MENU_ITEM_HEIGHT = 40;

export default function TableDropdown({
  item,
  actions = [],
  containerRef,
  setModalConfig,
}) {
  const [upward, setUpward] = useState(false);
  const dropdownRef = useRef(null);

  const availableActions = actions.filter((action) => {
    return !action.condition || action.condition(item);
  });

  if (availableActions.length === 0) return null;

  const checkUpward = () => {
    if (containerRef?.current && dropdownRef.current) {
      const dropdownBottom = dropdownRef.current.getBoundingClientRect().bottom;
      const tableBottom = containerRef.current.getBoundingClientRect().bottom;
      const menuHeight = availableActions.length * MENU_ITEM_HEIGHT;

      const isNearBottom = dropdownBottom + menuHeight >= tableBottom;
      setUpward(isNearBottom);
    }
  };

  return (
    <Ref innerRef={dropdownRef}>
      <Dropdown
        icon="ellipsis vertical"
        direction="left"
        upward={upward}
        onOpen={checkUpward}
        onClick={(e) => e.stopPropagation()}
      >
        <Dropdown.Menu>
          {availableActions.map((action) => (
            <TableDropdownItem
              key={`${item.id}_${action.label}`}
              item={item}
              action={action}
              setModalConfig={setModalConfig}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Ref>
  );
}
