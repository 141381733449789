import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const PAGE_OPTIONS = [10, 50, 100].map((option) => {
  return {
    label: option,
    value: option,
  };
});

export const DEFAULT_PER_PAGE_VALUE = 50;

const PerPageSelector = ({ perPage, onChange }) => {
  const { t } = useTranslation();

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: '70px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '4px 4px 4px 0',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0',
      paddingLeft: '8px',
    }),
  };

  const value = perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE_VALUE;
  const dropdownValue = PAGE_OPTIONS.find((option) => option.value === value);

  const handleChange = (evt) => {
    onChange({
      'page[number]': 1,
      'page[size]':
        evt.value === DEFAULT_PER_PAGE_VALUE ? undefined : evt.value,
    });
  };

  return (
    <div className="per-page-selector">
      <span className="per-page-label">{`${t('PerPage')}: `}</span>
      <Select
        styles={customStyles}
        defaultValue={dropdownValue}
        options={PAGE_OPTIONS}
        onChange={handleChange}
        menuPlacement="auto"
      />
    </div>
  );
};

export default PerPageSelector;
