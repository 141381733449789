import {
  LOAD_GROUPING_BATCH_LOADING,
  LOAD_GROUPING_BATCH_SUCCESS,
  LOAD_GROUPING_WORK_ITEMS_LOADING,
  LOAD_GROUPING_WORK_ITEMS_SUCCESS,
  LOAD_GROUPING_WORK_ITEM_DOCUMENTS_LOADING,
  LOAD_GROUPING_WORK_ITEM_DOCUMENTS_SUCCESS,
  ON_DOCUMENT_DROP,
  ON_PAGE_DROP,
  SET_GROUPING_DATA,
  ADD_UNASSIGNED_DOCUMENT_SUCCESS,
  REMOVE_WORK_ITEM_SUCCESS,
  ADD_NEW_WORK_ITEM_SUCCESS,
  RESET_GROUPING,
  CHANGE_DOCUMENT_TYPE,
  REMOVE_DOCUMENT,
} from '@constants/action-types';
import {
  appendDocumentToWorkItem,
  appendPageToDocument,
  getWorkItemsLeftSide,
  getWorkItemsRightSide,
  removeDocumentById,
  removePageById,
} from '@components/GroupingNew/helpers';

const defaultState = {
  batch: {
    isLoading: true,
    data: {},
  },
  workItems: {
    isLoading: true,
    data: [],
  },
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOAD_GROUPING_BATCH_LOADING: {
      return { ...state, batch: { isLoading: true, data: {} } };
    }
    case LOAD_GROUPING_BATCH_SUCCESS: {
      return { ...state, batch: { isLoading: false, data: action.payload } };
    }
    case LOAD_GROUPING_WORK_ITEMS_LOADING: {
      return { ...state, workItems: { isLoading: true, data: [] } };
    }
    case LOAD_GROUPING_WORK_ITEMS_SUCCESS: {
      return {
        ...state,
        workItems: { isLoading: false, data: action.payload },
      };
    }
    case LOAD_GROUPING_WORK_ITEM_DOCUMENTS_LOADING: {
      return {
        ...state,
        workItems: {
          ...state.workItems,
          data: state.workItems.data.map((workItem) => {
            if (workItem.id === action.payload) {
              return { ...workItem, isLoading: true };
            }
            return workItem;
          }),
        },
      };
    }
    case LOAD_GROUPING_WORK_ITEM_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        workItems: {
          ...state.workItems,
          data: state.workItems.data.map((workItem) => {
            if (workItem.id === action.payload.id) {
              return { ...action.payload, isLoading: false };
            }
            return workItem;
          }),
        },
      };
    }
    case ON_DOCUMENT_DROP: {
      const { from, to, oldWorkItemId, documentId, newWorkItemId } =
        action.payload;
      const workItemsFrom =
        from === 'rightSide'
          ? state.workItems.data
          : state.batch.data.workItems.data;

      const workItemsTo =
        to === 'rightSide'
          ? state.workItems.data
          : state.batch.data.workItems.data;

      const draggedDocument = workItemsFrom
        .find((workItem) => workItem.id === oldWorkItemId)
        .attributes.documents.find((document) => document.id === documentId);

      const withRemovedDocument = removeDocumentById(
        workItemsFrom,
        oldWorkItemId,
        documentId,
      );
      const withAddedDocument = appendDocumentToWorkItem(
        from === to ? withRemovedDocument : workItemsTo,
        draggedDocument,
        newWorkItemId,
      );
      return {
        ...state,
        workItems: {
          ...state.workItems,
          data: getWorkItemsRightSide(
            to,
            from,
            withAddedDocument,
            withRemovedDocument,
            state.workItems.data,
          ),
        },
        batch: {
          ...state.batch,
          data: {
            ...state.batch.data,
            workItems: {
              ...state.batch.data.workItems,
              data: getWorkItemsLeftSide(
                to,
                from,
                withAddedDocument,
                withRemovedDocument,
                state.batch.data.workItems.data,
              ),
            },
          },
        },
      };
    }
    case ON_PAGE_DROP: {
      const {
        from,
        newDocumentId,
        newWorkItemId,
        oldDocumentId,
        oldWorkItemId,
        pageId,
        to,
      } = action.payload;
      const workItemsFrom =
        from === 'rightSide'
          ? state.workItems.data
          : state.batch.data.workItems.data;

      const workItemsTo =
        to === 'rightSide'
          ? state.workItems.data
          : state.batch.data.workItems.data;
      const draggedPage = workItemsFrom
        .find((workItem) => workItem.id === oldWorkItemId)
        .attributes.documents.find((document) => document.id === oldDocumentId)
        .pages.find((page) => page.id === pageId);

      // get array of pages in document where user drop page
      const pagesTo = workItemsTo
        .find((workItem) => workItem.id === newWorkItemId)
        .attributes.documents.find((document) => document.id === newDocumentId);

      // index for correct order page if user select order for page
      const replacePageIndex = pagesTo.pages.findIndex(
        (page) => page.id === action.payload.replaceThisPageTo,
      );

      const workItemsWithRemovedPage = removePageById(
        workItemsFrom,
        oldDocumentId,
        oldWorkItemId,
        pageId,
      );
      const workItemsWithAddedPage = appendPageToDocument(
        from === to ? workItemsWithRemovedPage : workItemsTo,
        newDocumentId,
        newWorkItemId,
        draggedPage,
        replacePageIndex,
      );

      return {
        ...state,
        workItems: {
          ...state.workItems,
          data: getWorkItemsRightSide(
            to,
            from,
            workItemsWithAddedPage,
            workItemsWithRemovedPage,
            state.workItems.data,
          ),
        },
        batch: {
          ...state.batch,
          data: {
            ...state.batch.data,
            workItems: {
              ...state.batch.data.workItems,
              data: getWorkItemsLeftSide(
                to,
                from,
                workItemsWithAddedPage,
                workItemsWithRemovedPage,
                state.batch.data.workItems.data,
              ),
            },
          },
        },
      };
    }
    case SET_GROUPING_DATA: {
      return {
        ...action.payload,
      };
    }
    case REMOVE_WORK_ITEM_SUCCESS: {
      const { from, id } = action.payload;
      const workItemsFrom =
        from === 'rightSide'
          ? state.workItems.data
          : state.batch.data.workItems.data;

      const newWorkItemsArray = workItemsFrom.filter((workItem) => {
        return workItem.id !== id;
      });
      return {
        ...state,
        workItems: {
          ...state.workItems,
          data: from === 'rightSide' ? newWorkItemsArray : state.workItems.data,
        },
        batch: {
          ...state.batch,
          data: {
            ...state.batch.data,
            workItems: {
              ...state.batch.data.workItems,
              data:
                from === 'leftSide'
                  ? newWorkItemsArray
                  : state.batch.data.workItems.data,
            },
          },
        },
      };
    }
    case ADD_NEW_WORK_ITEM_SUCCESS: {
      const { payload } = action;
      return {
        ...state,
        workItems: {
          ...state.workItems,
          data: [payload, ...state.workItems.data],
        },
      };
    }
    case RESET_GROUPING: {
      return defaultState;
    }
    case ADD_UNASSIGNED_DOCUMENT_SUCCESS:
    case REMOVE_DOCUMENT:
    case CHANGE_DOCUMENT_TYPE: {
      const { from, wiId, newWI } = action.payload;
      const workItemsFrom =
        from === 'rightSide'
          ? state.workItems.data
          : state.batch.data.workItems.data;

      const newWorkItemsArray = workItemsFrom.map((workItem) => {
        return workItem.id === wiId ? newWI : workItem;
      });
      return {
        ...state,
        workItems: {
          ...state.workItems,
          data: from === 'rightSide' ? newWorkItemsArray : state.workItems.data,
        },
        batch: {
          ...state.batch,
          data: {
            ...state.batch.data,
            workItems: {
              ...state.batch.data.workItems,
              data:
                from === 'leftSide'
                  ? newWorkItemsArray
                  : state.batch.data.workItems.data,
            },
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};
