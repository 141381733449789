import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { cookies } from '@constants/cookies';
import { AUTH_TOKEN } from '@constants';
import { getCurrentUser, saveSettings } from '@actions';
import api from '@services/axios';
import store from '@services/store';

export default function useCurrentUser() {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUserReducer.user);
  const userSettings = useSelector(
    (state) => state.currentUserReducer.settings,
  );
  const navigate = useNavigate();

  const setCurrentUser = () => {
    api
      .jsonAPI()
      .get('/users/me')
      .then(({ data }) => {
        dispatch(getCurrentUser(data));
      })
      // TODO: check if status 401
      .catch(async (err) => {
        cookies.remove(AUTH_TOKEN, { path: '/' });
        store.dispatch({ type: 'RESET_SESSION' });
        navigate('/login');
        console.error('err', err);
      });
  };

  const loadUserSettings = () => {
    api
      .jsonAPI()
      .get('/settings')
      .then(({ data }) => {
        dispatch(saveSettings(data));
      })
      .catch((err) => console.error(err));
  };

  const loadUsers = () => {
    api
      .jsonAPI()
      .get('/users')
      .then(({ data }) => {
        // eslint-disable-next-line no-console
        // dispatch(saveSettings(data));
      })
      .catch((err) => console.error(err));
  };

  return {
    currentUser,
    userSettings,
    setCurrentUser,
    loadUserSettings,
    loadUsers,
  };
}
