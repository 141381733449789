import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

export default function MultiSelectFilter({ onChange, options = [], value }) {
  const { t } = useTranslation();
  const selectValue = () => {
    if (!value || value.length === 0) return [];
    return options.filter((option) => value.includes(option.value));
  };

  const onChangeHandler = (selected) => {
    const selectedValues = selected?.length > 0 ? selected : null;
    onChange(selectedValues);
  };
  return (
    <Select
      mode="multiple"
      maxTagCount={1}
      style={{ width: '100%' }}
      dropdownStyle={{ minWidth: '150px' }}
      allowClear
      placeholder={t('SelectPlaceholder')}
      options={options}
      value={selectValue()}
      onChange={onChangeHandler}
    />
  );
}
