export const DROPDOWN_OPTIONS = [
  {
    key: 'reject',
    label: 'Reject',
  },
  {
    key: 'send_for_approval',
    label: 'Send for approval',
  },
  {
    key: 'pause',
    label: 'Pause',
  },
  {
    key: 'resume',
    label: 'Resume',
  },
  {
    key: 'restore',
    label: 'Restore',
  },
  {
    key: 'approve',
    label: 'Approve',
  },
  {
    key: 'factor',
    label: 'Factor',
  },
];

// serverOptions like [ "reject", "pause", "send_for_approval" ]
export const createMenuOptions = (serverOptions = []) => {
  // const arr = [];
  return DROPDOWN_OPTIONS.filter((option) =>
    serverOptions.includes(option.key),
  ).map((option) => ({
    key: option.key,
    label: option.label,
  }));
};
