export default function ResizeHandler(props) {
  const isHandlerSelected = props.resizedColumnIndex === props.index;
  return (
    <div
      role="button"
      tabIndex={0}
      aria-label="Resize"
      onMouseDown={() => props.setResizedColumnIndex(props.index)}
      className={`resize-handle ${isHandlerSelected ? 'active' : 'idle'}`}
    />
  );
}
