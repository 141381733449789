import { SET_ALERTS_LIST } from '@constants/action-types';

const initialState = { alerts: [] };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERTS_LIST:
      return {
        ...state,
        alerts: action.payload,
      };
    default:
      return state;
  }
};
