import { Dropdown } from 'antd';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { setWorkItem as setWorkItemAction } from '@actions';
import api from '@services/axios';

import { createMenuOptions } from './DropdownSettings';
import s from './styles.module.scss';

const DropdownMenu = ({ disabled }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const workItem = useSelector((state) => state.workItemReducer.workItem);
  const allowedActions = workItem?.allowedActions;

  const menuOptions = createMenuOptions(allowedActions);
  const onAction = (actionKey) => {
    api
      .jsonAPI()
      .patch(`/work_items/${id}/${actionKey}`)
      .then(({ data }) => {
        dispatch(setWorkItemAction(data));
      })
      .catch((err) => {
        err?.response?.data?.messages?.forEach((message) => {
          toast.error(message);
        });
      });
  };

  const onReject = () => {
    onAction('reject');
  };

  const onRestore = () => {
    onAction('restore');
  };

  const onPause = () => {
    onAction('pause');
  };

  const onResume = () => {
    onAction('resume');
  };

  const onSendForApproval = () => {
    onAction('send_for_approval');
  };

  const onApprove = () => {
    onAction('approve');
  };

  const onFactor = () => {
    onAction('factor');
  };

  const actions = {
    reject: onReject,
    send_for_approval: onSendForApproval,
    pause: onPause,
    resume: onResume,
    restore: onRestore,
    approve: onApprove,
    factor: onFactor,
  };

  const handleActions = ({ key }) => {
    const action = actions[key];
    action?.();
  };

  return (
    <Dropdown
      menu={{ items: menuOptions, onClick: handleActions }}
      trigger={['click']}
      disabled={disabled || !allowedActions.length}
    >
      <div
        className={clsx(
          'ui',
          'primary',
          'button',
          s.button,
          (disabled || !allowedActions.length) && s.disabled,
        )}
      >
        Action <Icon name="angle down" className={s.icon} />
      </div>
    </Dropdown>
  );
};

export default DropdownMenu;
