import { useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import Jsona from 'jsona';

import api from '@services/axios';

import s from './styles.module.scss';

// alerts: [{:id, :name}], checkedAlerts: [:id]
const createAlertsHash = (alerts, checkedAlerts) => {
  return alerts.map((alert) => {
    const isChecked = checkedAlerts.includes(alert.id);

    return { ...alert, checked: isChecked };
  });
};

const Alerts = ({
  alerts: alertsList,
  checkedAlerts,
  onAlertChange,
  readOnly,
}) => {
  const { id } = useParams();
  const dataFormatter = new Jsona();

  const [alerts, setAlerts] = useState(() =>
    createAlertsHash(alertsList, checkedAlerts),
  );

  // update local checkboxes' state
  const handleChange = (e, data) => {
    setAlerts((state) => {
      const checkbox = state.find((alert) => alert.name === data.label);
      checkbox.checked = data.checked;
      updateAlerts();
      return [...state];
    });
  };

  // update checkboxes at server side
  const updateAlerts = () => {
    const checkedCheckboxes = alerts.reduce((base, alert) => {
      if (alert.checked) {
        // base[alert.name] = true;
        base.push(alert.id);
        // base.push({ [alert.name]: true });
      }
      return base;
    }, []);

    const values = {
      type: 'alerts',
      alerts: checkedCheckboxes,
    };

    const JSONApiValues = dataFormatter.serialize({
      stuff: values,
    });

    api
      .jsonAPI()
      .put(`/work_items/${id}`, JSONApiValues)
      .then((res) => {
        onAlertChange(res?.data?.alerts);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  if (!alerts.length) return 'No alerts';

  return (
    <div className={s.alertsWrapper}>
      {alerts.map((alert) => (
        <div key={alert.name} className={s.alertWrapper}>
          <Checkbox
            label={alert.name}
            className={s.alert}
            checked={alert.checked}
            onChange={handleChange}
            disabled={readOnly}
          />
        </div>
      ))}
    </div>
  );
};

export default Alerts;
