import { useDispatch, useSelector } from 'react-redux';

import { setBreadcrumb as setReduxBreadcrumb } from '@actions';

export default function useBreadcrumb() {
  const breadcrumb = useSelector((state) => state.interfaceReducer.breadcrumb);
  const dispatch = useDispatch();

  const setBreadcrumb = (bc) => {
    dispatch(setReduxBreadcrumb(bc));
  };

  return { breadcrumb, setBreadcrumb };
}
