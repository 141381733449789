/* eslint-disable jsx-a11y/label-has-associated-control */
import { ErrorMessage, Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Input } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Upload } from 'antd';

import api from '@services/axios';
import styles from '@components/forms/NewEmailForm/NewEmailForm.module.scss';

export default function StampsForm({ stamp, loading }) {
  const navigate = useNavigate();
  const { id: stampId } = useParams();
  const { t } = useTranslation();
  const prepareData = (values) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('attachment', values.attachment[0].originFileObj);
    formData.append('type', 'stamps');
    if (stampId) {
      formData.append('id', stampId);
    }

    return formData;
  };

  const onSubmitHandler = (formValues, { setSubmitting }) => {
    const preparedData = prepareData(formValues);
    const saveFunction = stampId
      ? api.jsonAPI().put(`/stamps/${stampId}`, preparedData)
      : api.jsonAPI().post('stamps', preparedData);
    setSubmitting(false);
    saveFunction
      .then(() => {
        const toastText = stampId
          ? t('ItemSaved', { item: 'Stamp' })
          : t('TemplateCreated', { name: formValues.title });
        toast.success(toastText);
        navigate('/admin/stamps');
      })
      .catch((err) => {
        console.error('Error on form submission', { err });
        toast.error(t('ItemNotSaved', { item: 'stamps' }));
      })
      .finally(() => setSubmitting(false));
  };

  const displayError = (errors, touched, key) => {
    return errors[key] && touched[key];
  };

  return (
    <Formik
      initialValues={{
        title: stamp?.title ?? '',
        attachment: stamp?.attachment ?? [],
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(t('ItemRequired', { item: 'Title' })),
      })}
      enableReinitialize
      onSubmit={onSubmitHandler}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form
          loading={loading}
          onSubmit={handleSubmit}
          className="form-container"
        >
          <div className="users-form">
            <Form.Field
              required
              className="inline-field"
              error={displayError(errors, touched, 'title')}
            >
              <label>{t('Title')}</label>
              <div className="text-input">
                <Input
                  name="title"
                  onChange={handleChange}
                  placeholder={t('ItemAnPlaceholder', {
                    item: 'title',
                  })}
                  value={values.title}
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <Upload
              accept="image/svg+xml"
              name="attachment"
              onChange={({ fileList }) => setFieldValue('attachment', fileList)}
              showUploadList={false}
              beforeUpload={() => false}
            >
              <Button type="button" className={styles.button}>
                Upload
              </Button>
            </Upload>
            <div className="submit-button">
              <Button
                basic
                as={Link}
                to="/admin/stamps"
                disabled={isSubmitting}
              >
                {t('Cancel')}
              </Button>
              <Button
                primary
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {t('Submit')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
