import React, { useEffect } from 'react';
import { Select } from 'antd';
import { isArray } from 'lodash';

import s from '@components/GroupingNew/components/GroupingFilters/GroupingFilters.module.scss';

const GroupingFilters = ({
  batchOptions,
  emailsOptions,
  loadNumbersOptions,
  subjectLineOptions,
  updateFilters,
  filtersData,
}) => {
  const [filters, setFilters] = React.useState({
    'filter[batchId]': [],
    'filter[email]': [],
    'filter[loadNumber]': [],
    'filter[subjectLine]': [],
    ...filtersData,
  });

  const onFilterChange = (value, filterName) => {
    updateFilters({ ...filters, [filterName]: value });
    setFilters({
      ...filters,
      [filterName]: value,
    });
  };

  useEffect(() => {
    setFilters({
      ...filtersData,
    });
  }, [filtersData]);

  const onClearFilter = (name) => {
    updateFilters({ ...filters, [name]: [] });
  };
  return (
    <div className={s.filters}>
      <Select
        optionLabelProp="label"
        maxTagCount={1}
        placeholder="Please select batch"
        className={s.filterItem}
        value={
          isArray(filters?.['filter[batchId]'])
            ? filters['filter[batchId]']
            : [parseInt(filters['filter[batchId]'], 10) || null]
        }
        mode="multiple"
        size="middle"
        onChange={(value) => onFilterChange(value, 'filter[batchId]')}
        style={{ width: '100%' }}
        options={batchOptions}
        dropdownRender={(menu) => (
          <>
            <div className={s.selectMenu}>
              <button
                className={s.filterButton}
                type="button"
                onClick={() => onClearFilter('filter[batchId]')}
              >
                Clear
              </button>
            </div>
            {menu}
          </>
        )}
      />
      <Select
        maxTagCount={1}
        className={s.filterItem}
        mode="multiple"
        size="middle"
        placeholder="Please select email"
        value={filters?.['filter[email]'] || []}
        onChange={(value) => onFilterChange(value, 'filter[email]')}
        style={{ width: '100%' }}
        dropdownRender={(menu) => (
          <>
            <div className={s.selectMenu}>
              <button
                className={s.filterButton}
                type="button"
                onClick={() => onClearFilter('filter[email]')}
              >
                Clear
              </button>
            </div>
            {menu}
          </>
        )}
        options={emailsOptions}
      />
      <Select
        className={s.filterItem}
        mode="multiple"
        size="middle"
        maxTagCount={1}
        placeholder="Please select load number"
        value={filters?.['filter[loadNumber]'] || []}
        onChange={(value) => onFilterChange(value, 'filter[loadNumber]')}
        style={{ width: '100%' }}
        dropdownRender={(menu) => (
          <>
            <div className={s.selectMenu}>
              <button
                className={s.filterButton}
                type="button"
                onClick={() => onClearFilter('filter[loadNumber]')}
              >
                Clear
              </button>
            </div>
            {menu}
          </>
        )}
        options={loadNumbersOptions}
      />
      <Select
        className={s.filterItem}
        mode="multiple"
        size="middle"
        maxTagCount={1}
        placeholder="Please select subject"
        value={filters?.['filter[subjectLine]'] || []}
        onChange={(value) => onFilterChange(value, 'filter[subjectLine]')}
        style={{ width: '100%' }}
        dropdownRender={(menu) => (
          <>
            <div className={s.selectMenu}>
              <button
                className={s.filterButton}
                type="button"
                onClick={() => onClearFilter('filter[subjectLine]')}
              >
                Clear
              </button>
            </div>
            {menu}
          </>
        )}
        options={subjectLineOptions}
      />
    </div>
  );
};

export default GroupingFilters;
