import i18n from '@locales/i18n';
import { formatDate } from '@services/date';
import { capitalizeFirst, formatToAmericanCurrency } from '@services/string';
import { FILTER_TYPES } from '@components/aria/UI/Table/Filters/filterTypes';

import Status from './Status';

export const hsStatus = [
  'Completed',
  'Needs Attention',
  'Processing',
  'Ready',
  'Failed',
].map((status) => ({
  label: status,
  value: status,
}));

const paymentTypeOptions = ['ACH', 'Check'].map((option) => ({
  label: option,
  value: option,
}));

export const formatSubstate = (substate) => {
  if (!substate) return null;
  const splitSubstate = substate.split('_');
  splitSubstate[0] = capitalizeFirst(splitSubstate[0]);
  return splitSubstate.join(' ');
};

export const hsSubstatusOptions = [
  'manual_transcription',
  'manual_field_identification',
  'manual_table_identification',
  'manual_routing_transcription',
  'machine_routing_decision',
  'manual_document_organization',
  'flexible_extraction',
  'custom_supervision',
  'manual_supervision',
].map((option) => ({
  label: formatSubstate(option),
  value: option,
}));

const formatStatus = (status) => {
  return <Status status={status} />;
};

export const CashAppColumns = [
  {
    name: i18n.t('PaymentNumber'),
    key: 'payment_number',
    filter: FILTER_TYPES.TEXT,
    searchKey: 'paymentNumber',
  },
  {
    name: i18n.t('PaymentType'),
    key: 'payment_type',
    filter: FILTER_TYPES.SELECT,
    options: paymentTypeOptions,
    searchKey: 'paymentType',
  },
  {
    name: i18n.t('Payer'),
    key: 'payer',
    filter: FILTER_TYPES.SELECT_ENDPOINT,
    url: 'apps/{id}/external_services/getPayer',
    keys: {
      label: 'payer',
      value: 'payer',
    },
    applyFilters: true,
  },
  {
    name: i18n.t('BatchNumber'),
    key: 'batch_id',
    filter: FILTER_TYPES.TEXT,
    searchKey: 'batchId',
  },
  {
    name: i18n.t('Status'),
    key: 'status',
    filter: FILTER_TYPES.SELECT_ENDPOINT,
    url: 'apps/{id}/external_services/getStatus',
    keys: {
      label: 'status',
      value: 'status',
    },
    applyFilters: true,
    method: formatStatus,
  },
  { name: i18n.t('Exception'), key: 'exception', filter: FILTER_TYPES.TEXT },
  {
    name: i18n.t('CreationDate'),
    key: 'value_date',
    filter: FILTER_TYPES.DATE,
    method: formatDate,
    searchKey: 'createdAt',
  },
  {
    name: i18n.t('LastUpdated'),
    key: 'updated_at',
    filter: FILTER_TYPES.DATE,
    method: formatDate,
    searchKey: 'updatedAt',
  },
  {
    name: i18n.t('PayerId'),
    key: 'payer_id',
    filter: FILTER_TYPES.TEXT,
    searchKey: 'payerId',
  },
  {
    name: i18n.t('Amount'),
    key: 'payment_amount',
    filter: FILTER_TYPES.TEXT,
    method: formatToAmericanCurrency,
    searchKey: 'paymentAmount',
  },
  {
    name: i18n.t('AssignedTo'),
    key: 'user',
    filter: FILTER_TYPES.SELECT_ENDPOINT,
    method: (user) => user.name,
    url: '/users',
    keys: {
      label: 'name',
      value: 'uuid',
    },
  },
];

export const HyperScienceColumns = [
  {
    name: i18n.t('SubmissionID'),
    key: 'data.id',
    filter: FILTER_TYPES.TEXT,
    tableKey: 'submissionId',
    searchKey: 'dataId',
  },
  {
    name: i18n.t('Status'),
    key: 'status',
    filter: FILTER_TYPES.MULTI_SELECT,
    options: hsStatus,
    method: formatStatus,
  },
  {
    name: i18n.t('Substate'),
    key: 'subStatus',
    filter: FILTER_TYPES.MULTI_SELECT,
    method: formatSubstate,
    options: hsSubstatusOptions,
  },
  {
    name: i18n.t('CreatedAt'),
    key: 'createdAt',
    filter: FILTER_TYPES.DATE,
    method: formatDate,
  },
  {
    name: i18n.t('UpdatedAt'),
    key: 'updatedAt',
    filter: FILTER_TYPES.DATE,
    method: formatDate,
  },
  { name: i18n.t('Detail'), key: 'detail', filter: FILTER_TYPES.TEXT },
];

const returnInvoiceStatusColor = (status) => {
  switch (status?.toLowerCase()) {
    case 'correct':
      return 'green';
    case 'incorrect':
      return 'orange';
    default:
      return 'grey';
  }
};

export const InvoiceColumns = (callback, readOnly) => {
  const invColumns = [
    {
      name: '',
      key: 'status',
      config: {
        type: 'icon',
        icon: 'circle',
        color: returnInvoiceStatusColor,
      },
      width: '0fr',
    },
    { name: i18n.t('inv_number'), key: 'inv_number' },
    { name: i18n.t('ref_number'), key: 'ref_number' },
    { name: i18n.t('assignemnt'), key: 'assignment' },
    { name: i18n.t('store_number'), key: 'store_number' },
    {
      name: i18n.t('document_date'),
      key: 'document_date',
      method: (d) => formatDate(d, 'MM-dd-yyyy'),
    },
    {
      name: i18n.t('SAPAmount'),
      key: 'amount',
      icon: 'dollar',
      method: (amount) => formatToAmericanCurrency(amount),
    },
    { name: i18n.t('payer'), key: 'payer' },
    {
      name: i18n.t('amount_to_post'),
      key: 'amount_to_post',
      icon: 'dollar',
      method: (amount) => formatToAmericanCurrency(amount),
    },
    { name: i18n.t('reason_code'), key: 'reason_code' },
    { name: i18n.t('comment'), key: 'comment' },
    {
      name: '',
      key: 'custom_actions',
      config: {
        type: 'actions',
        actions: [
          { type: 'edit', icon: 'edit outline', color: 'grey' },
          { type: 'delete', icon: 'minus square outline', color: 'red' },
          { type: 'add', icon: 'plus square outline', color: 'green' },
        ],
        callback,
        key: 'id',
      },
      width: '0fr',
    },
  ];
  if (readOnly) {
    invColumns.pop();
  }
  return invColumns;
};
