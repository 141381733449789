import s from './styles.module.scss';

const ErrorScreen = () => {
  return (
    <div className={s.errorBox}>
      <div className={s.errorContent}>
        <div className={s.smile}>:(</div>
        <div className={s.text}>
          Your Application ran into a problem and you need to contact the Admin.
        </div>
      </div>
    </div>
  );
};

export default ErrorScreen;
