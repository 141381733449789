import { useTranslation } from 'react-i18next';

import { DEFAULT_PER_PAGE_VALUE } from './PerPageSelector';

export default function CurrentPageInfo({
  page = 1,
  perPage = DEFAULT_PER_PAGE_VALUE,
  totalItems,
}) {
  const { t } = useTranslation();
  const firstIndex = (page - 1) * perPage + 1;
  const lastElementOfPage = page * perPage;
  const lastIndex = totalItems
    ? Math.min(lastElementOfPage, totalItems)
    : lastElementOfPage;
  return (
    <span className="current-page-info">
      {t('ViewingPageItems', {
        first: firstIndex,
        last: lastIndex,
        total: totalItems ?? '-',
      })}
    </span>
  );
}
