const BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'transflo-dev.invokeinc.com'
    : window.location.host.toString();

export default {
  isDev: process.env.NODE_ENV === 'development',
  isProd: process.env.NODE_ENV === 'production',
  api: {
    url: {
      http: `https://${BASE_URL}`,
      ws: `wss://${BASE_URL}`,
    },
  },
};
