/* eslint-disable jsx-a11y/label-has-associated-control */
import { ErrorMessage, Formik } from 'formik';
import Jsona from 'jsona';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Checkbox, Form, Input } from 'semantic-ui-react';
import * as Yup from 'yup';

import api from '@services/axios';

export default function DocumentTypesForm({ documentType, loading }) {
  const navigate = useNavigate();
  const { id: documentTypeId } = useParams();
  const { t } = useTranslation();
  const dataFormatter = new Jsona();
  const prepareTemplateData = (templateData) => {
    const data = {
      name: templateData?.name,
      hyperscienceName: templateData.hyperscienceName,
      visible: templateData.visible,
    };
    return {
      type: 'editableDocumentType',
      id: documentType?.id ?? null,
      ...data,
    };
  };

  const onSubmitHandler = (formValues, { setSubmitting }) => {
    const preparedData = dataFormatter.serialize({
      stuff: prepareTemplateData(formValues),
    });
    const saveFunction = documentTypeId
      ? api
          .jsonAPI()
          .put(`/editable_document_types/${documentTypeId}`, preparedData)
      : api.jsonAPI().post('editable_document_types', preparedData);
    setSubmitting(true);
    saveFunction
      .then(() => {
        const toastText = documentTypeId
          ? t('ItemSaved', { item: 'Template' })
          : t('TemplateCreated', { name: formValues.title });
        toast.success(toastText);
        navigate('/admin/document-types');
      })
      .catch((err) => {
        console.error('Error on form submission', { err });
        toast.error(t('ItemNotSaved', { item: 'template' }));
      })
      .finally(() => setSubmitting(false));
  };

  const displayError = (errors, touched, key) => {
    return errors[key] && touched[key];
  };
  return (
    <Formik
      initialValues={{
        name: documentType?.name ?? '',
        hyperscienceName: documentType?.hyperscienceName ?? '',
        visible: documentType?.visible ?? false,
      }}
      validationSchema={Yup.object().shape({
        // title: Yup.string().required(t('ItemRequired', { item: 'Title' })),
        // template: Yup.string().required(
        //   t('ItemRequired', { item: 'Template' }),
        // ),
        // subject: Yup.string().required(t('ItemRequired', { item: 'Subject' })),
      })}
      enableReinitialize
      onSubmit={onSubmitHandler}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
      }) => (
        <Form
          loading={loading}
          onSubmit={handleSubmit}
          className="form-container"
        >
          <div className="users-form">
            <Form.Field
              required
              className="inline-field"
              error={displayError(errors, touched, 'hyperscienceName')}
            >
              <label>{t('Default name')}</label>
              <div className="text-input">
                <Input
                  name="hyperscienceName"
                  disabled
                  onChange={handleChange}
                  placeholder={t('ItemAnPlaceholder', {
                    item: 'hyperscienceName',
                  })}
                  value={values.hyperscienceName}
                />
                <ErrorMessage
                  name="hyperscienceName"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <Form.Field
              required
              className="inline-field"
              error={displayError(errors, touched, 'name')}
            >
              <label>{t('Custom name')}</label>
              <div className="text-input">
                <Input
                  name="name"
                  onChange={handleChange}
                  placeholder={t('ItemAnPlaceholder', {
                    item: 'name',
                  })}
                  value={values.name}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <Form.Field
              required
              className="inline-field"
              error={displayError(errors, touched, 'visible')}
            >
              <label>{t('Visible')}</label>
              <div className="text-input">
                <Checkbox
                  id="visible"
                  name="visible"
                  onChange={handleChange}
                  checked={values.visible}
                />
                <ErrorMessage
                  name="visible"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <div className="submit-button">
              <Button
                basic
                as={Link}
                to="/admin/document-types"
                disabled={isSubmitting}
              >
                {t('Cancel')}
              </Button>
              <Button
                primary
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {t('Submit')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
