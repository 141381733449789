/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import { Button, Icon, Input, Popup } from 'semantic-ui-react';
import { Button as AntdButton, Slider, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import { ReactComponent as StampIcon } from '@assets/icons/stamp.svg';
import s from '@components/aria/Application/Activity/Hyperscience/DocumentViewer/Viewer.module.scss';

export const PDF_VIEWER_ACTIONS = {
  crop: 'crop',
  stamp: 'stamp',
  contrast: 'contrast',
  brightness: 'brightness',
  rotate: 'rotate',
  zoomByLasso: 'zoomByLasso',
};

const DEFAULT_CLASSNAME = 'd-flex-center-sb pdf-toolbar';

const ZOOM_OPTIONS = [25, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300];

export default function Toolbar(props) {
  const {
    page,
    scale,
    setPage,
    setScale,
    nPages = 1,
    containerRef,
    imgRef,
    editMode,
    setEditMode,
    readOnly,
    onFilterValueChange,
    visualFilterValue,
    onFilterSave,
    file,
    stamps,
    onStampSelect,
    selectedStamp,
  } = props;
  const prevPage = () => setPage((prevPage) => prevPage - 1);
  const nextPage = () => setPage((prevPage) => prevPage + 1);

  const zoomIn = () => {
    const nextZoomOption = ZOOM_OPTIONS.filter(
      (option) => option > scale * 100,
    ).at(0);
    nextZoomOption && setScale(nextZoomOption / 100);
  };
  const zoomOut = () => {
    const prevZoomOption = ZOOM_OPTIONS.filter(
      (option) => option < scale * 100,
    ).at(-1);
    prevZoomOption && setScale(prevZoomOption / 100);
  };

  const goToFirstPage = () => setPage(1);
  const goToLastPage = () => setPage(nPages);

  const handlePageChange = (e, { value }) => {
    const parsedPage = parseInt(value, 10);
    setPage(parsedPage > nPages ? nPages : parsedPage);
  };

  const fitToHeight = () => {
    const containerHeight = containerRef.current.offsetHeight;
    const imgHeight = imgRef.current.naturalHeight;
    const initialScale = containerHeight / imgHeight;
    setScale(initialScale);
  };

  const fitToWidth = () => {
    const containerWidth = containerRef.current.offsetWidth;
    const imgWidth = imgRef.current.naturalWidth;
    const initialScale = containerWidth / imgWidth;
    setScale(initialScale);
  };

  const fitToBest = () => {
    const containerHeight = containerRef.current.offsetHeight;
    const containerWidth = containerRef.current.offsetWidth;
    const imgHeight = imgRef.current.naturalHeight;
    const imgWidth = imgRef.current.naturalWidth;
    const bestScaleByHeight = containerHeight / imgHeight;
    const bestScaleByWidth = containerWidth / imgWidth;
    setScale(imgWidth > imgHeight ? bestScaleByWidth : bestScaleByHeight);
  };

  const toolbarClassName = props.className
    ? `${DEFAULT_CLASSNAME} ${props.className}`
    : DEFAULT_CLASSNAME;
  return (
    <div className={toolbarClassName}>
      <div className="d-flex-center-sb">
        <Button
          onClick={goToFirstPage}
          disabled={page <= 1}
          className="full-width-button"
          icon
        >
          <Icon name="step backward" rotated="clockwise" />
        </Button>
        <div className="button_separator" />
        <Button icon="arrow up" onClick={prevPage} disabled={page <= 1} />
        <Input
          type="Number"
          min="1"
          max={nPages}
          size="mini"
          value={page}
          onChange={handlePageChange}
        />
        <span>{`of ${nPages}`}</span>
        <Button
          icon="arrow down"
          onClick={nextPage}
          disabled={page >= nPages}
        />
        <div className="button_separator" />
        <Button
          onClick={goToLastPage}
          disabled={page >= nPages}
          icon
          className="full-width-button"
        >
          <Icon name="step forward" rotated="clockwise" />
        </Button>
      </div>

      <div className="d-flex-center-sb">
        {(editMode === PDF_VIEWER_ACTIONS.brightness ||
          editMode === PDF_VIEWER_ACTIONS.contrast) && (
          <div className={s.toolbarSliderWrapper}>
            <Slider
              value={visualFilterValue}
              max={2}
              min={0.1}
              step={0.01}
              style={{ width: '250px' }}
              onChange={onFilterValueChange}
            />
            <div className={s.toolbarFilterButtons}>
              <AntdButton
                type="primary"
                className={s.filterButton}
                size="small"
                onClick={() =>
                  onFilterSave({ method: editMode, value: visualFilterValue })
                }
              >
                <CheckOutlined />
              </AntdButton>
              <AntdButton
                type="primary"
                danger
                className={clsx(s.filterButton, s.cancelButton)}
                size="small"
                onClick={() => setEditMode(editMode)}
              >
                <CloseOutlined />
              </AntdButton>
            </div>
          </div>
        )}

        {editMode !== PDF_VIEWER_ACTIONS.contrast &&
          editMode !== PDF_VIEWER_ACTIONS.brightness && (
            <>
              <Button
                icon="minus"
                type="button"
                onClick={zoomOut}
                disabled={!imgRef.current || scale <= 0.25 || !file}
              />
              <div className="button_separator" />
              <Button
                icon="add"
                type="button"
                onClick={zoomIn}
                disabled={!imgRef.current || scale >= 20 || !file}
              />
              <Input
                size="mini"
                value={imgRef.current ? `${scale.toFixed(4) * 100}%` : ''}
                disabled={!imgRef.current || !file}
              />
              <Tooltip
                placement="bottomRight"
                title="Zoom by selection"
                showArrow={false}
              >
                {/* zoom by lasso for next release */}
                <Button
                  primary={editMode === PDF_VIEWER_ACTIONS.zoomByLasso}
                  onClick={() => setEditMode(PDF_VIEWER_ACTIONS.zoomByLasso)}
                  disabled={!imgRef.current || !file}
                  icon="zoom"
                />
              </Tooltip>
              {!readOnly && (
                <>
                  <Button
                    primary={editMode === PDF_VIEWER_ACTIONS.crop}
                    onClick={() => setEditMode(PDF_VIEWER_ACTIONS.crop)}
                    disabled={!imgRef.current || !file}
                    icon="crop"
                  />
                  <Button
                    primary={editMode === PDF_VIEWER_ACTIONS.rotate}
                    onClick={() => setEditMode(PDF_VIEWER_ACTIONS.rotate)}
                    disabled={!imgRef.current || !file}
                    icon="redo"
                  />
                  <Popup
                    on="click"
                    position="bottom left"
                    trigger={
                      <Button
                        disabled={!imgRef.current || !file}
                        icon={<StampIcon />}
                      />
                    }
                    flowing
                    hoverable
                  >
                    <div className={s.stampsWrapper}>
                      {stamps?.length > 0 &&
                        stamps.map((stamp) => {
                          return (
                            <div
                              className={clsx(
                                s.stampItem,
                                selectedStamp?.id === stamp.id &&
                                  s.selectedStamp,
                              )}
                              key={stamp.id}
                              onClick={() => onStampSelect(stamp)}
                            >
                              <img
                                width={100}
                                src={stamp.attachment.url}
                                alt="stamp"
                              />
                            </div>
                          );
                        })}
                      {!stamps?.length && <p>No uploaded stamps found</p>}
                    </div>
                  </Popup>
                </>
              )}
              <Popup
                on="click"
                position="bottom center"
                trigger={
                  <Button
                    disabled={!imgRef.current || readOnly || !file}
                    icon="image"
                  />
                }
                flowing
                hoverable
              >
                <Tooltip
                  placement="bottomRight"
                  title="Contrast"
                  showArrow={false}
                >
                  <Button
                    icon="adjust"
                    primary={editMode === PDF_VIEWER_ACTIONS.contrast}
                    onClick={() => setEditMode(PDF_VIEWER_ACTIONS.contrast)}
                    disabled={!imgRef.current || !file}
                  />
                </Tooltip>
                <Tooltip
                  placement="bottomRight"
                  title="Brightness"
                  showArrow={false}
                >
                  <Button
                    icon="sun"
                    primary={editMode === PDF_VIEWER_ACTIONS.brightness}
                    onClick={() => setEditMode(PDF_VIEWER_ACTIONS.brightness)}
                    disabled={!imgRef.current || !file}
                  />
                </Tooltip>
              </Popup>
              <Popup
                on="click"
                position="bottom center"
                trigger={
                  <Button
                    disabled={!imgRef.current || !file}
                    icon="expand arrows alternate"
                  />
                }
                flowing
                hoverable
              >
                <Tooltip
                  placement="bottomRight"
                  title="Full height"
                  showArrow={false}
                >
                  <Button
                    icon="arrows alternate vertical"
                    onClick={fitToHeight}
                    disabled={!imgRef.current || !file}
                  />
                </Tooltip>
                <Tooltip
                  placement="bottomRight"
                  title="Full width"
                  showArrow={false}
                >
                  <Button
                    icon="arrows alternate horizontal"
                    onClick={fitToWidth}
                    disabled={!imgRef.current || !file}
                  />
                </Tooltip>
                <Tooltip
                  placement="bottomRight"
                  title="Full page"
                  showArrow={false}
                >
                  <Button
                    icon="arrows alternate"
                    onClick={fitToBest}
                    disabled={!imgRef.current || !file}
                  />
                </Tooltip>
              </Popup>
            </>
          )}
      </div>
    </div>
  );
}
