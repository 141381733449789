/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import Jsona from 'jsona';
import { useTranslation } from 'react-i18next';

import axios from '@services/axios';
import useHyperscience from '@hooks/useHyperscience';
import useApiGet from '@hooks/useApiGet';
import Events from '@components/aria/UI/Events/Events';
import DocumentEvents from '@components/DocumentEvents';
import { EVENTS_TYPES } from '@constants';

// import ActionCableContainer from '../../../Misc/ActionCableContainer';

import UpdateActivity from './UpdateActivity';
import HyperscienceView from './HyperscienceView';
// Note: Avoid to use the variable HyperscienceWidget, since it is used by the Hyperscience SDK.

export default function Hyperscience({
  app,
  activity,
  getActivity,
  showEventsTitle = false,
  eventsType = EVENTS_TYPES.transflo,
  withUpdateActivity = false,
  disableRightEvents = false,
  disableLeftEvents = false,
  tabsClassName,
  readOnly,
}) {
  const [events, getEvents] = useApiGet(
    `apps/${app.id}/activities/${activity.id}/events?sort=createdAt`,
  );
  const [sendingEvent, SetSendingEvent] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [document, setDocument] = useState(null);
  const { reset } = useHyperscience();
  const dataFormatter = new Jsona();
  const { t } = useTranslation();

  const showDocument = !!document;
  const [widget, toggleWidget] = useState(showDocument);

  useEffect(() => {
    // getEvents();
    setDocuments();
    return () => {
      reset();
    };
  }, []);

  const setDocuments = () => {
    const auxDocument = combineDocuments(activity?.documents);
    setDocument(auxDocument);
  };

  useEffect(
    (_) => {
      setUpdated(!updated);
    },
    [events],
  );

  const combineDocuments = (documents) => {
    const document_fields = [];
    const document_tables = [];
    const pages = [];
    const layouts = [
      ...new Set(documents.map((_document) => _document.layout_name)),
    ];

    documents.forEach((doc) => {
      const auxDocument = prepareDocument(doc);
      document_fields.push([...auxDocument.document_fields]);
      document_tables.push([...auxDocument.document_tables]);
      pages.push([...auxDocument.pages]);
    });

    const finalDocument = {
      document_fields: document_fields.flat(),
      document_tables: document_tables.flat(),
      pages: pages.flat(),
      layouts,
    };

    return finalDocument;
  };

  const prepareDocument = (doc) => {
    const fields = doc.document_fields.map((field) => {
      return appendDocumentInformation(field, doc);
    });
    const tables = doc.document_tables.map((table) => {
      return appendDocumentInformation(table, doc);
    });
    const newDocument = {
      document_fields: fields,
      document_tables: tables,
      pages: doc.pages,
    };
    return newDocument;
  };

  const appendDocumentInformation = (object, doc) => {
    const { id, layout_name } = doc;
    if (!layout_name) return object;
    return {
      ...object,
      document_id: id,
      layout_name,
    };
  };

  const addEventHandler = (message, name) => {
    const paramsToSend = {
      type: 'event',
      id: '',
      body: message,
      title: name,
    };
    const preparedData = dataFormatter.serialize({ stuff: paramsToSend });
    SetSendingEvent(true);
    axios
      .jsonAPI()
      .post(`apps/${app.id}/activities/${activity.id}/events`, preparedData)
      .then(({ data }) => {
        SetSendingEvent(false);
        getEvents();
      })
      .catch((err) => console.error(err));
  };

  return (
    <div>
      {/* <ActionCableContainer
        active={!!activity}
        channel={{ channel: 'ActivityChannel', activity_uuid: activity?.uuid }}
        onReceived={({ refresh }) => {
          if (refresh) getEvents();
        }}
      /> */}
      {withUpdateActivity && (
        <UpdateActivity
          app={app}
          activity={activity}
          widget={widget}
          toggleWidget={toggleWidget}
        />
      )}

      <div>
        <HyperscienceView
          widget={widget}
          submission_id={activity.hyperscienceData?.id}
          getActivity={getActivity}
          app={app}
          activity={activity}
          document={document}
          attachments={activity.attachments}
          disableLeftEvents={disableLeftEvents}
          tabsClassName={tabsClassName}
          readOnly={readOnly}
        />
        {/* {!disableRightEvents && (
          <div className="events-container hyperscience">
            {showEventsTitle && (
              <div className="companies-header companies-header-text">
                {t('Events')}
              </div>
            )}
            <EventsContainer
              eventsType={eventsType}
              events={events}
              addEventHandler={addEventHandler}
              sendingEvent={sendingEvent}
            />
          </div>
        )} */}
      </div>
    </div>
  );
}

const EventsContainer = ({
  eventsType,
  events,
  addEventHandler,
  sendingEvent,
}) => {
  if (eventsType === EVENTS_TYPES.transflo) return <DocumentEvents />;

  return (
    <Events
      events={events}
      addEventHandler={addEventHandler}
      sendingEvent={sendingEvent}
    />
  );
};
