import React, { useEffect, useState } from 'react';
import { Table } from 'semantic-ui-react';

import useHyperscience from '@hooks/useHyperscience';

import HSTableRow from './HSTableRow';

export default function HSTable({ hsDocument }) {
  const { selectedTable, newRows, editable } = useHyperscience();
  const [table, setTable] = useState(null);

  useEffect(() => {
    const currentTable = hsDocument.document_tables.find(
      (_table) => _table.id === selectedTable,
    );
    setTable(currentTable);
  }, [selectedTable]);

  if (!table) return <div />;

  const getColumnNames = () => {
    const names = [];
    table.rows.forEach((row) => {
      row.cells.forEach((cell) => {
        if (!names.includes(cell.column_name)) {
          names.push(cell.column_name);
        }
      });
    });
    return names;
  };

  const columnNames = getColumnNames();
  if (!columnNames?.length) return <div />;

  const rows = [...table.rows, ...newRows];

  return (
    <div style={{ padding: '14px 0 ' }}>
      <Table basic="very" className="hs-table">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            {columnNames?.map((name) => (
              <Table.HeaderCell key={`column_${name}`}>{name}</Table.HeaderCell>
            ))}
            {editable && <Table.HeaderCell />}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {rows.map((row) => (
            <HSTableRow
              key={`hs_row_${row.id}`}
              row={row}
              table={table}
              columnNames={columnNames}
            />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}
