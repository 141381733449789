import React from 'react';
import { isEmpty } from 'lodash';

import BoundingBox from './BoundingBox';

export default function BoundingBoxes({ dimensions, fields }) {
  if (!dimensions || !fields || isEmpty(fields)) return null;

  return fields.map((field) => (
    <BoundingBox key={field.id} field={field} dimensions={dimensions} />
  ));
}
