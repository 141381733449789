/* eslint-disable jsx-a11y/label-has-associated-control */
// ! REWRITE LABEL WHEN COMPILE APP
import { ErrorMessage, Formik } from 'formik';
import Jsona from 'jsona';
import { DateTime } from 'luxon';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button, Form, Input } from 'semantic-ui-react';
import * as Yup from 'yup';
import { string } from 'yup';
import { Select } from 'antd';
import { useSelector } from 'react-redux';

import api from '@services/axios';
import { USER_ROLES, USER_ROLES_OPTIONS } from '@constants';

export default function UserForm({ user, loading }) {
  const navigate = useNavigate();
  const { id: userId } = useParams();
  const { t } = useTranslation();
  const dataFormatter = new Jsona();
  const isSuperAdmin =
    useSelector((state) => state?.currentUserReducer?.user?.roles)?.includes(
      USER_ROLES?.super_admin,
    ) || false;
  const prepareUserData = (users) => {
    const userData = {
      name: users.name,
      email: users.email,
      roles: users.roles,
      timezone: DateTime.local().zoneName,
      permissions: users.permissions,
    };
    if (users.password) {
      userData.password = users.password;
    }
    return {
      type: 'user',
      id: user?.id ?? null,
      ...userData,
    };
  };

  const onSubmitHandler = (formValues, { setSubmitting }) => {
    const preparedData = dataFormatter.serialize({
      stuff: prepareUserData(formValues),
    });
    const saveFunction = userId
      ? api.jsonAPI().put(`/users/${userId}`, preparedData)
      : api.jsonAPI().post('users', preparedData);

    setSubmitting(true);
    saveFunction
      .then(() => {
        const toastText = userId
          ? t('ItemSaved', { item: 'User' })
          : t('UserCreated', { name: formValues.name });
        toast.success(toastText);
        navigate('/admin/users');
      })
      .catch((err) => {
        console.error('Error on form submission', { err });
        toast.error(t('ItemNotSaved', { item: 'user' }));
      })
      .finally(() => setSubmitting(false));
  };

  const displayError = (errors, touched, key) => {
    return errors[key] && touched[key];
  };
  // console.log(
  //   user?.permissions &&
  //     Object.entries(values.permissions)
  //       .filter(([, value]) => value)
  //       .map(([permission]) => permission),
  // );
  return (
    <Formik
      initialValues={{
        name: user?.name ?? '',
        email: user?.email ?? '',
        roles: user?.roles ?? [],
        permissions: user?.permissions ?? {},
        password: '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('ItemRequired', { item: 'Name' })),
        email: Yup.string()
          .email(t('EmailNotValid'))
          .required(t('ItemRequired', { item: 'Email' })),
        roles: Yup.array()
          .required(t('ItemRequired', { item: 'Role' }))
          .min(1, 'Roles is required field'),
        password:
          !userId &&
          string()
            .required('Password is required field')
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/,
              'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
            ),
      })}
      enableReinitialize
      onSubmit={onSubmitHandler}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setTouched,
      }) => (
        <Form
          loading={loading}
          onSubmit={handleSubmit}
          className="form-container"
        >
          <div className="users-form">
            <Form.Field
              required
              className="inline-field"
              error={displayError(errors, touched, 'name')}
            >
              <label>{t('Name')}</label>
              <div className="text-input">
                <Input
                  name="name"
                  onChange={handleChange}
                  placeholder={t('ItemPlaceholder', { item: 'name' })}
                  value={values.name}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <Form.Field
              required
              className="inline-field"
              error={displayError(errors, touched, 'email')}
            >
              <label>{t('Email')}</label>
              <div className="text-input">
                <Input
                  name="email"
                  onChange={handleChange}
                  placeholder={t('ItemAnPlaceholder', { item: 'email' })}
                  value={values.email}
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <Form.Field
              required
              className="inline-field"
              error={displayError(errors, touched, 'password')}
            >
              <label>{userId ? 'New Password' : t('Password')}</label>
              <div className="text-input">
                <Input
                  name="password"
                  onChange={handleChange}
                  placeholder={t('ItemAnPlaceholder', {
                    item: `${userId ? 'new ' : ''}password`,
                  })}
                  value={values.password}
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <Form.Field
              className="inline-field"
              error={displayError(errors, touched, 'roles')}
            >
              <label>{t('UserRoles')}</label>
              <div className="text-input">
                <Select
                  onChange={(e) => {
                    setFieldValue('roles', e);
                    setTimeout(
                      () => setTouched({ ...touched, roles: true }),
                      1000,
                    );
                  }}
                  value={values.roles}
                  options={USER_ROLES_OPTIONS.map((option) => {
                    if (
                      !isSuperAdmin &&
                      option.value === USER_ROLES.super_admin
                    ) {
                      return {
                        ...option,
                        disabled: true,
                      };
                    }
                    return option;
                  })}
                  style={{ width: '100%' }}
                  mode="multiple"
                />
                <ErrorMessage
                  name="roles"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <Form.Field
              className="inline-field"
              error={displayError(errors, touched, 'permissions')}
            >
              <label>{t('Permissions')}</label>
              <div className="text-input">
                <Select
                  disabled={!isSuperAdmin}
                  onChange={(permissionsList) => {
                    const newPermissions = Object.fromEntries(
                      Object.entries(values.permissions).map(
                        ([permissionName]) => {
                          if (!permissionsList.includes(permissionName)) {
                            return [permissionName, false];
                          }
                          return [permissionName, true];
                        },
                      ),
                    );
                    setFieldValue('permissions', newPermissions);
                    setTimeout(
                      () => setTouched({ ...touched, permissions: true }),
                      1000,
                    );
                  }}
                  value={
                    values?.permissions &&
                    Object.entries(values.permissions)
                      .filter(([, value]) => value)
                      .map(([permission]) => permission)
                  }
                  options={
                    user?.permissions &&
                    Object.keys(user?.permissions).map((permission) => {
                      return {
                        label: permission,
                        value: permission,
                      };
                    })
                  }
                  style={{ width: '100%' }}
                  mode="multiple"
                />
                <ErrorMessage
                  name="permissions"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <div className="submit-button">
              <Button basic as={Link} to="/admin/users" disabled={isSubmitting}>
                {t('Cancel')}
              </Button>
              <Button
                primary
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {t('Submit')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
