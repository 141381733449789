import i18n from '@locales/i18n';

const STATUS = [
  { label: i18n.t('Completed'), class: 'success' },
  { label: i18n.t('Processing'), class: 'pending' },
  { label: i18n.t('NeedsAttention'), class: 'locked-item' },
  { label: i18n.t('Failed'), class: 'fail' },
  { label: i18n.t('Ready'), class: 'success' },
  { label: i18n.t('Complete'), class: 'success' },
  { label: i18n.t('OnHold'), class: 'locked-item' },
  { label: i18n.t('Manual'), class: 'fail' },
];

export default function Status({ status }) {
  if (!status) return null;

  const statusClassName = STATUS.find((st) => st.label === status)?.class;
  return <span className={`item-status ${statusClassName}`}>{status}</span>;
}
