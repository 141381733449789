import { cloneDeep, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import api from '@services/axios';

const menuExtra = {
  menu: (styles) => ({ ...styles, zIndex: 100 }),
};

const SelectFromEndpoint = (props) => {
  const {
    value,
    setValue,
    endpoint,
    disabled = false,
    keys,
    loadOnInit = false,
    ...otherProps
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [optionsLoaded, setOptionsLoaded] = useState(false);
  const filters = useSelector((state) => state.filtersReducer.filters);
  const { t } = useTranslation();

  const loadOptions = () => {
    setIsLoading(true);
    promiseOptions();
  };

  useEffect(() => {
    if (loadOnInit) {
      loadOptions();
    }
  }, []);

  useEffect(() => {
    if (value && !optionsLoaded) {
      setPlaceholderOption(value);
    }
  }, [value]);

  useEffect(() => {
    if (otherProps.applyFilters) {
      setCurrentOptions([]);
      setOptionsLoaded(false);
    }
  }, [filters]);

  const setPlaceholderOption = (val) => {
    if (typeof val === 'string') {
      setCurrentOptions([{ label: val, value: val }]);
    } else if (typeof val === 'object') {
      setCurrentOptions([val]);
    }
  };

  const promiseOptions = () =>
    new Promise((resolve) => {
      resolve(getExternalOptions());
    });

  const searchParams = () => {
    if (!otherProps.applyFilters || !filters) return undefined;
    const params = cloneDeep(filters);
    const propertiesToRemove = ['page[number]', 'page[size]'];
    if (otherProps.filterToRemove) {
      propertiesToRemove.push(`filter[${otherProps.filterToRemove}]`);
    }
    propertiesToRemove.forEach((key) => {
      if (params[key]) delete params[key];
    });

    return isEmpty(params) ? undefined : { params };
  };

  const getExternalOptions = () => {
    api
      .jsonAPI()
      .get(endpoint, searchParams())
      .then(({ data }) => {
        if (keys) {
          const auxOptions = data.map((dataItem) => ({
            label: dataItem[keys.label],
            value: dataItem[keys.value],
          }));
          setCurrentOptions(auxOptions);
        } else {
          setCurrentOptions(data);
        }
        setIsLoading(false);
        setOptionsLoaded(true);
      })
      .catch((err) => console.error(err));
  };

  const selectValue =
    typeof value === 'object'
      ? value
      : currentOptions.find((option) => option.value === value);

  return (
    <Select
      {...otherProps}
      styles={menuExtra}
      isDisabled={disabled}
      className="react-select-custom"
      placeholder={t('StartTyping')}
      value={selectValue}
      onChange={setValue}
      options={currentOptions}
      isLoading={isLoading}
      onMenuOpen={() => {
        if (isEmpty(currentOptions) || !optionsLoaded) {
          loadOptions();
        }
      }}
    />
  );
};
export default SelectFromEndpoint;
