import Select from 'react-select';

export default function SingleSelectFilter({ onChange, options = [], value }) {
  const selectValue = value
    ? options.find((option) => option.value === value)
    : null;

  return (
    <Select
      options={options}
      value={selectValue}
      noOptionsMessage={() => 'No options'}
      onChange={(select) => onChange(select?.value)}
      isClearable
    />
  );
}
