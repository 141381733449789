import { Loader } from 'semantic-ui-react';

import NavButton from './NavButton';

export default function NavButtons(props) {
  const { prevItem, onPrevClick, nextItem, onNextClick, loading } = props;
  let content = null;

  if (loading) {
    content = (
      <Loader active inline size="small" className="centering-loader" />
    );
  } else if (prevItem || nextItem) {
    content = (
      <>
        {prevItem && (
          <NavButton popupText={prevItem.name} onClick={onPrevClick} />
        )}
        {nextItem && (
          <NavButton next popupText={nextItem.name} onClick={onNextClick} />
        )}
      </>
    );
  }

  if (!content) return null;
  return <div className="nav-buttons">{content}</div>;
}
