import { combineReducers } from 'redux';

import groupingReducer from '@reducers/groupingReducer';

import applicationsReducer from './applicationsReducer';
import currentUserReducer from './currentUserReducer';
import filtersReducer from './filtersReducer';
import hyperscienceReducer from './hyperscienceReducer';
import interfaceReducer from './interfaceReducer';
import usersReducer from './usersReducer';
import workItemReducer from './workItemReducer';
import batchReducer from './batchReducer';
import alertsReducer from './alertsReducer';
import documentTypesReducer from './documentTypesReducer';

const defaultState = {
  applicationsReducer: { apps: [] },
  currentUserReducer: { user: null, settings: [] },
  filtersReducer: { appUuid: null, filters: null },
  hyperscienceReducer: {
    editable: false,
    selectedField: null,
    selectedTable: null,
    editedFields: {},
    filters: {
      name: '',
      exceptions: false,
    },
    newRows: [],
  },
  interfaceReducer: {},
  usersReducer: {},
  workItemReducer: { workItem: {} },
  batchReducer: {},
  groupingReducer: {},
  alerts: [],
  documentTypesReducer: { documentTypes: [] },
};

const appReducer = combineReducers({
  currentUserReducer,
  usersReducer,
  interfaceReducer,
  hyperscienceReducer,
  filtersReducer,
  applicationsReducer,
  workItemReducer,
  batchReducer,
  alertsReducer,
  documentTypesReducer,
  groupingReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_SESSION') {
    state = defaultState;
  }

  return appReducer(state, action);
};

export default rootReducer;
