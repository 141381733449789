import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

import useAuthToken from '@hooks/useAuthToken';
import { AUTH_TOKEN } from '@constants';
import useCurrentUser from '@hooks/useCurrentUser';
import api from '@services/axios';
import store from '@services/store';

import Avatar from './Avatar';
import { SidebarWidth } from './Sidebar';

export default function UserSection({ width }) {
  const { currentUser } = useCurrentUser();
  const navigate = useNavigate();
  const { cookies } = useAuthToken();

  const onLogout = () => {
    api
      .jsonAPI()
      .post('/users/sign_out')
      .then(() => {
        cookies.remove(AUTH_TOKEN, { path: '/' });
        store.dispatch({ type: 'RESET_SESSION' });
        navigate('/login');
      })
      .catch((err) => console.error(err));
  };

  const dropdownMenu = (
    <Dropdown.Menu direction="right" className="collapsed-user-menu">
      {/* <Dropdown.Item icon="user" content="Profile" as={Link} to="settings" /> */}
      <Dropdown.Item icon="sign out" content="Log out" onClick={onLogout} />
    </Dropdown.Menu>
  );

  if (width === SidebarWidth.VeryThin) {
    const avatar = (
      <div>
        <Avatar imageUrl={currentUser?.avatar} />
      </div>
    );
    return (
      <Dropdown
        trigger={avatar}
        className="current-user-section collapsed handcursor"
        icon={null}
      >
        {dropdownMenu}
      </Dropdown>
    );
  }

  return (
    <div className="current-user-section expanded">
      <Avatar name={currentUser?.name} imageUrl={currentUser?.avatar} />
      <div>
        <Dropdown icon="ellipsis horizontal">{dropdownMenu}</Dropdown>
      </div>
    </div>
  );
}
