import styles from '@components/EmailClient/components/UploadedFile/UploadedFile.module.scss';
import { ReactComponent as UploadIcon } from '@assets/icons/uploadFileIcon.svg';

const UploadedFile = ({ file, icon }) => {
  return (
    <div className={styles.fileItem}>
      <div className={styles.fileIcon}>
        <UploadIcon />
      </div>
      <div className={styles.fileName}>{file.name || file.label}</div>
      {icon}
    </div>
  );
};

export default UploadedFile;
