import CenteredLoader from '@components/aria/UI/CenteredLoader';

import HSWidget from './HSWidget';
import Document from './Document';

export default function HyperscienceView({
  activity,
  widget,
  disableLeftEvents,
  tabsClassName,
  readOnly,
  ...props
}) {
  if (!props.document) return <CenteredLoader text="Loading activity..." />;

  if (widget) {
    return (
      <HSWidget
        app={props.app}
        submission_id={activity.hyperscienceData?.id}
        getActivity={props.getActivity}
      />
    );
  }

  return (
    <Document
      activity={activity}
      hsDocument={props.document}
      attachments={activity.attachments}
      disableLeftEvents={disableLeftEvents}
      tabsClassName={tabsClassName}
      readOnly={readOnly}
    />
  );
}
