import { isEmpty } from 'lodash';

import {
  HS_ADD_ROW,
  HS_EDIT_FIELD,
  HS_REMOVE_ROW,
  HS_RESET_DOCUMENT,
  HS_SELECT_ITEM,
  HS_SET_EDITABLE,
  HS_SET_FILTERS,
} from '@constants/action-types';

const initialState = {
  editable: false,
  selectedField: null,
  selectedTable: null,
  editedFields: {},
  filters: {
    name: '',
    exceptions: false,
  },
  newRows: [],
};

const hyperscienceReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case HS_SET_EDITABLE:
      return setEditable(state, payload);
    case HS_SELECT_ITEM:
      return selectItem(state, payload);
    case HS_EDIT_FIELD:
      return updateHSField(state, payload);
    case HS_SET_FILTERS:
      return setFilters(state, payload);
    case HS_ADD_ROW:
      return addRow(state, payload);
    case HS_REMOVE_ROW:
      return removeRow(state, payload);
    case HS_RESET_DOCUMENT:
      return initialState;
    default:
      return state;
  }
};

const setEditable = (state, editable) => {
  if (typeof editable !== 'boolean') return state;
  return {
    ...state,
    editable,
  };
};

const selectItem = (state, payload) => {
  const newState = {
    ...state,
  };
  if (payload.field !== undefined) {
    newState.selectedField = payload.field;
  }
  if (payload.table !== undefined) {
    newState.selectedTable = payload.table;
  }
  return newState;
};

const updateHSField = (state, field) => {
  if (!field) return state;

  const { id, ...fieldInfo } = field;

  const auxFields = { ...state.editedFields };
  auxFields[id] = { ...fieldInfo };
  return {
    ...state,
    editedFields: auxFields,
  };
};

const setFilters = (state, filters) => {
  if (!filters) return state;

  const { name, exceptions } = filters;
  const auxFilters = { ...state.filters };
  if (name !== undefined) {
    auxFilters.name = name;
  }
  if (exceptions !== undefined) {
    auxFilters.exceptions = exceptions;
  }

  return {
    ...state,
    filters: auxFilters,
  };
};

const addRow = (state, row) => {
  if (!row || isEmpty(row)) return state;
  const auxRows = [...state.newRows, row];

  // add new cells to editedFields
  const auxEditedFields = { ...state.editedFields };
  row.cells.forEach((cell) => {
    auxEditedFields[cell.id] = {
      type: 'table',
      table_id: row.table_id,
      document_table_row_id: row.id,
      normalized: '',
    };
  });
  return {
    ...state,
    newRows: auxRows,
    editedFields: auxEditedFields,
  };
};

const removeRow = (state, rowId) => {
  if (!rowId) return state;

  const auxRows = [...state.newRows];
  const filteredRows = auxRows.filter((row) => row.id !== rowId);
  // we have to remove the cells from the editedFields too
  const auxEditedFields = { ...state.editedFields };
  const editedFieldsToDelete = Object.entries(auxEditedFields)
    .filter(([, field]) => {
      return field.document_table_row_id === rowId;
    })
    .map(([key]) => {
      return key;
    });

  editedFieldsToDelete.forEach((key) => delete auxEditedFields[key]);
  return {
    ...state,
    editedFields: auxEditedFields,
    newRows: filteredRows,
  };
};

export default hyperscienceReducer;
