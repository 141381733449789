import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import Breadcrumb from './Breadcrumb';
import s from './styles.module.scss';

export default function NavigationBar({
  breadcrumb,
  hasCloseIcon = false,
  onWorkItemChange,
}) {
  return (
    <div className={s.topbar}>
      <Breadcrumb breadcrumb={breadcrumb} />
      <div>
        {onWorkItemChange && (
          <Tooltip title="Previous Work Item">
            <ArrowLeftOutlined
              style={{
                fontSize: 15,
                marginRight: 10,
              }}
              onClick={() => onWorkItemChange('prev')}
            />
          </Tooltip>
        )}
        {onWorkItemChange && (
          <Tooltip title="Next Work Item">
            <ArrowRightOutlined
              style={{
                fontSize: 15,
                marginRight: 10,
              }}
              onClick={() => onWorkItemChange('next')}
            />
          </Tooltip>
        )}
        {hasCloseIcon && (
          <Link style={{ textDecoration: 'none', color: 'unset' }} to="/">
            <Icon name="close" />
          </Link>
        )}
      </div>
    </div>
  );
}
