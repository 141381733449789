import { SET_DOCUMENT_TYPES } from '@constants/action-types';

const initialState = { documentTypes: [] };

export default function documentTypesReducer(state = initialState, action) {
  switch (action.type) {
    case SET_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: action.payload,
      };
    default:
      return state;
  }
}
