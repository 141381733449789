import { Checkbox, Table } from 'semantic-ui-react';

import { CHECKBOX_WIDTH } from '@ui/Table/constants';

import FilterCell from './FilterCell';

export default function TableFilters(props) {
  return (
    <Table.Row>
      {props.onSelectAll && (
        <Table.HeaderCell
          className="select-all"
          style={{ width: `${CHECKBOX_WIDTH}px` }}
        >
          {props.onSelectAll && (
            <Checkbox onClick={props.onSelectAll} checked={props.selectedAll} />
          )}
        </Table.HeaderCell>
      )}
      {props.columns.map((column) => (
        <FilterCell
          key={`search_${column.tableKey ?? column.key}`}
          column={column}
          filters={props.filters}
          updateFilters={props.updateFilters}
          selectedAll={props.selectedAll}
          onSelectAll={props.onSelectAll}
        />
      ))}
    </Table.Row>
  );
}
