import s from './ApplicationConfig.module.scss';

const ApplicationConfig = ({ settings }) => {
  return (
    <div>
      <div className={s.field}>
        <div className={s.configName}>Tenant Name</div>
        <div className={s.configValue}>{settings.subdomain}</div>
      </div>
      <div className={s.field}>
        <div className={s.configName}>System Email Box</div>
        <div className={s.configValue}>{settings.email}</div>
      </div>
      <div className={s.field}>
        <div className={s.configName}>Customer Client/Debtor Lookup Schema</div>
        <div className={s.configValue}>
          {JSON.stringify(settings.external_mysql)}
        </div>
      </div>
      <div className={s.field}>
        <div className={s.configName}>SQS Output Queue</div>
        <div className={s.configValue}>
          {JSON.stringify(settings.amazonsqs)}
        </div>
      </div>
      <div className={s.field}>
        <div className={s.configName}>S3 Bucket</div>
        <div className={s.configValue}>
          {JSON.stringify(settings.s3_bucket)}
        </div>
      </div>
      <div className={s.field}>
        <div className={s.configName}>Input Queue</div>
        <div className={s.configValue}>{JSON.stringify(settings.rabbitmq)}</div>
      </div>
      <div className={s.field}>
        <div className={s.configName}>Output Queue</div>
        <div className={s.configValue}>
          {JSON.stringify(settings.amazonsqs)}
        </div>
      </div>
      <div className={s.field}>
        <div className={s.configName}>Private IP Address</div>
        <div className={s.configValue}>{settings.private_ip}</div>
      </div>
      <div className={s.field}>
        <div className={s.configName}>Rules and Validation</div>
        <div className={s.configValue}>
          {JSON.stringify(settings.skip_rules_check)}
        </div>
      </div>
      <div className={s.field}>
        <div className={s.configName}>Backend branch</div>
        <div className={s.configValue}>{settings.backend_branch}</div>
      </div>
      <div className={s.field}>
        <div className={s.configName}>Frontend branch</div>
        <div className={s.configValue}>{settings.frontend_branch}</div>
      </div>
      <div className={s.field}>
        <div className={s.configName}>Application version</div>
        <div className={s.configValue}>{settings.version}</div>
      </div>
    </div>
  );
};

export default ApplicationConfig;
