import { useDrop } from 'react-dnd';
import clsx from 'clsx';

import s from '@components/AddDocumentButton/AddDocumentButton.module.scss';

const AddDocumentButton = ({
  onClick,
  onMovePageToNewDocument,
  wiId,
  from,
}) => {
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'page',
    drop: (item) =>
      onMovePageToNewDocument({
        pageId: item.id,
        documentId: item.documentId,
        wiId,
        from,
        to: item.from,
      }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));
  const isActive = isOver && canDrop;

  return (
    <div
      className={clsx(s.wrapper, isActive && s.active)}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      ref={drop}
    >
      + Add document
    </div>
  );
};

export default AddDocumentButton;
