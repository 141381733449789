/* eslint-disable jsx-a11y/label-has-associated-control */
import { Button } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';

import useHyperscience from '@hooks/useHyperscience';

import { formatSubstate } from '../../ApplicationConfiguration/ApplicationColumns';

export default function UpdateActivity({
  app,
  activity,
  widget,
  toggleWidget,
}) {
  const { t } = useTranslation();
  const { editedFields, updateHSData, submitting, editable } =
    useHyperscience();

  const subStatusOption = activity.subStatus
    ? { label: formatSubstate(activity.subStatus), value: activity.subStatus }
    : null;

  const onCorrectClick = () => {
    updateHSData(activity, app.id);
  };

  const onCorrectDocumentHandler = () => {
    toggleWidget((prevToggle) => !prevToggle);
  };

  const noDocument = !activity.documents || activity.documents.length === 0;

  return (
    <div className="update-activity">
      <div className="item-container">
        <label>{t('Substate')}</label>
        <Select
          options={subStatusOption}
          defaultValue={subStatusOption}
          isDisabled
          placeholder={t('None')}
          classNamePrefix="activity-select"
        />
      </div>
      <div className="item-container">
        {activity.status === 'Needs Attention' && (
          <Button
            primary
            disabled={noDocument}
            onClick={onCorrectDocumentHandler}
          >
            {widget ? t('ViewDocument') : t('CorrectDocument')}
          </Button>
        )}
        {editable && (
          <Button
            primary
            disabled={isEmpty(editedFields) || submitting}
            loading={submitting}
            onClick={onCorrectClick}
          >
            {t('Save')}
          </Button>
        )}
      </div>
    </div>
  );
}
