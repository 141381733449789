import { Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';

import i18n from '@locales/i18n';
import { statusColors, STATUS_CARDS, WORK_ITEM_STATUSES } from '@constants';

const FILTER_TYPES = {
  DATE: 'DATE',
  SELECT: 'SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
  TEXT: 'TEXT',
  SELECT_ENDPOINT: 'SELECT_ENDPOINT',
};

const formatClientName = (user) => {
  if (!Object.keys(user).length) return null;
  return (
    <Tooltip title={user?.email}>
      <Tag
        style={{
          width: '100%',
          textAlign: 'center',
          padding: '3px 2px',
          borderRadius: '4px',
        }}
      >
        {user?.name}
      </Tag>
    </Tooltip>
  );
};

const formatStatus = (status) => {
  const color = statusColors.get(status.name);
  return (
    <Tag
      style={{
        width: '100%',
        textAlign: 'center',
        padding: '3px 2px',
        borderRadius: '4px',
      }}
      color={color}
    >
      {status.caption}
    </Tag>
  );
};

const formatTags = (tags) => {
  if (!tags) return null;
  return tags.map((tag) => (
    <Tag
      key={tag}
      style={{
        textAlign: 'center',
        borderRadius: '4px',
      }}
      color={tag.color}
    >
      {tag}
    </Tag>
  ));
};

const formatDate = (date) => <div style={{ maxWidth: 90 }}>{date}</div>;

const formatWIid = (id) => {
  return <div>{id}</div>;
};

export const hsStatus = STATUS_CARDS.map(({ title }) => ({
  label: title,
  value: title,
}));

export const workItemsColumns = [
  {
    name: i18n.t('Email date'),
    key: 'emailDate',
    filter: FILTER_TYPES.DATE,
    tableKey: 'emailDate',
    searchKey: 'emailDate',
    method: formatDate,
    width: 'auto',
  },
  {
    name: i18n.t('In Use'),
    key: 'inUse',
    filter: FILTER_TYPES.TEXT,
    tableKey: 'inUse',
    searchKey: 'inUse',
    method: formatClientName,
    width: 'auto',
  },
  {
    name: i18n.t('Client Name'),
    key: 'clientName',
    filter: FILTER_TYPES.TEXT,
    tableKey: 'clientName',
    searchKey: 'clientName',
    width: 'auto',
  },
  {
    name: i18n.t('Debtor Name'),
    key: 'debtorName',
    filter: FILTER_TYPES.TEXT,
    tableKey: 'debtorName',
    searchKey: 'debtorName',
    width: 'auto',
  },
  {
    name: i18n.t('Received At'),
    key: 'receivedAt',
    filter: FILTER_TYPES.DATE,
    tableKey: 'receivedAt',
    searchKey: 'receivedAt',
    method: formatDate,
    width: 'auto',
  },
  {
    name: i18n.t('Status'),
    key: 'status',
    filter: FILTER_TYPES.MULTI_SELECT,
    options: hsStatus, // options for filter menu
    method: formatStatus, // same as render in antd. can get current cell item
    width: 'auto',
  },
  {
    name: i18n.t('Load Number'),
    key: 'loadNumber',
    filter: FILTER_TYPES.TEXT,
    width: 'auto',
  },
  {
    name: i18n.t('Email From'),
    key: 'email',
    filter: FILTER_TYPES.TEXT,
    tableKey: 'email',
    searchKey: 'email',
    width: 'auto',
  },
  {
    name: i18n.t('Subject Line'),
    key: 'subjectLine',
    filter: FILTER_TYPES.TEXT,
    tableKey: 'subjectLine',
    searchKey: 'subjectLine',
    width: 'auto',
  },
  {
    name: i18n.t('Payment Type'),
    key: 'paymentType',
    filter: FILTER_TYPES.TEXT,
    tableKey: 'paymentType',
    searchKey: 'paymentType',
    width: 'auto',
  },
  {
    name: i18n.t('Tag'),
    key: 'tags',
    filter: FILTER_TYPES.TEXT,
    tableKey: 'tags',
    searchKey: 'tags',
    method: formatTags, // same as render in antd. can get current cell item
    width: 'auto',
  },
  {
    name: i18n.t('Batch #'),
    key: 'batchId',
    filter: FILTER_TYPES.TEXT,
    tableKey: 'batchId',
    searchKey: 'batchId',
    width: 'auto',
  },
  {
    name: i18n.t('Work Item #'),
    key: 'workItemId',
    filter: FILTER_TYPES.TEXT,
    tableKey: 'workItemId',
    searchKey: 'workItemId',
    method: formatWIid,
    width: 'auto',
  },
];

export const createTableData = (tableData) => {
  if (!tableData.length) return [];

  return tableData.map((workItem) => {
    const clientItem = workItem?.outputFields;
    const date = dayjs(workItem?.batch?.data?.attributes?.receivedAt).format(
      'MM/DD/YYYY, HH:mm',
    );
    const emailDate = dayjs(workItem?.emailedAt).format('MM/DD/YYYY, HH:mm');

    const clientName = clientItem?.client?.name;
    const debtorName = clientItem?.debtor?.name;
    const email = workItem?.batch?.data?.attributes?.email;
    const id =
      workItem?.status?.name === WORK_ITEM_STATUSES.inProcess
        ? null
        : workItem?.id;
    return {
      key: workItem?.id,
      clientName,
      debtorName,
      receivedAt: date,
      emailDate,
      status: workItem?.status, // should be {}
      workItemId: id,
      id: workItem?.id,
      tags: workItem?.tags,
      batchId: workItem?.batch?.data?.id,
      email,
      subjectLine: workItem?.batch?.data?.attributes?.subjectLine,
      loadNumber: workItem?.loadNumber,
      paymentType: workItem?.outputFields?.payment?.type,
      inUse: workItem?.locked?.user || {},
    };
  });
};
