import { cloneDeep } from 'lodash';

import config from '@config';
import {
  createImageFile,
  generatePdfFromImages,
} from '@components/WorkItemDocument/pdfHelper';

export const getFiltersOptions = (workItems) => {
  const batchOptions = [
    ...new Set(workItems?.map((item) => item.attributes.batch.data.id)),
  ]
    .filter((item) => item)
    .map((item) => ({
      key: item,
      value: parseInt(item, 10),
      label: `Batch ${item}`,
    }));

  const emailOptions = [
    ...new Set(
      workItems?.map((item) => item.attributes.batch.data.attributes.email),
    ),
  ]
    .filter((item) => item)
    .map((item) => ({
      key: item,
      value: item,
    }));

  const subjectLineOptions = [
    ...new Set(
      workItems?.map(
        (item) => item.attributes.batch.data.attributes.subjectLine,
      ),
    ),
  ]
    .filter((item) => item)
    .map((item) => ({
      key: item,
      value: item,
    }));

  const loadNumbersOptions = [
    ...new Set(workItems?.map((item) => item.attributes.loadNumber)),
  ]
    .filter((item) => item)
    .map((item) => ({
      key: item,
      value: item,
    }));

  emailOptions.unshift({ key: 'N/A', value: 'N/A', label: 'N/A' });
  loadNumbersOptions.unshift({ key: 'N/A', value: 'N/A', label: 'N/A' });
  subjectLineOptions.unshift({ key: 'N/A', value: 'N/A', label: 'N/A' });
  return {
    batchOptions,
    emailOptions,
    loadNumbersOptions,
    subjectLineOptions,
  };
};

export const removeDocumentById = (workItemsArray, workItemId, documentId) => {
  const workItemIndex = workItemsArray.findIndex(
    (item) => item.id === workItemId,
  );
  const newDocuments = workItemsArray[
    workItemIndex
  ]?.attributes.documents.filter((document) => document.id !== documentId);
  const newWorkItems = cloneDeep(workItemsArray);
  newWorkItems[workItemIndex].attributes.documents = newDocuments;
  return newWorkItems;
};

export const appendDocumentToWorkItem = (
  workItemsArray,
  document,
  workItemId,
) => {
  const workItemIndex = workItemsArray.findIndex(
    (item) => item.id === workItemId,
  );
  if (workItemsArray[workItemIndex].attributes.documents) {
    const newDocuments =
      workItemsArray[workItemIndex].attributes.documents.concat(document);
    const newWorkItems = cloneDeep(workItemsArray);
    newWorkItems[workItemIndex].attributes.documents = newDocuments;
    return newWorkItems;
  }
  return workItemsArray;
};

export const removePageById = (
  workItemsArray,
  oldDocumentId,
  oldWorkItemId,
  pageId,
) => {
  const workItemIndex = workItemsArray.findIndex(
    (item) => item.id === oldWorkItemId,
  );
  const documentIndex = workItemsArray[
    workItemIndex
  ].attributes.documents.findIndex((document) => document.id === oldDocumentId);
  const newPages = workItemsArray[workItemIndex].attributes.documents[
    documentIndex
  ].pages.filter((page) => page.id !== pageId);
  const newDocuments = cloneDeep(
    workItemsArray[workItemIndex].attributes.documents,
  );
  newDocuments[documentIndex].pages = newPages;
  const newWorkItem = cloneDeep(workItemsArray);
  newWorkItem[workItemIndex].attributes.documents = newDocuments;
  return newWorkItem;
};

export const appendPageToDocument = (
  workItems,
  newDocumentId,
  newWorkItemId,
  draggedPage,
  appendIndex,
) => {
  const workItemIndex = workItems.findIndex(
    (workItem) => workItem.id === newWorkItemId,
  );
  const documentIndex = workItems[workItemIndex].attributes.documents.findIndex(
    (document) => document.id === newDocumentId,
  );
  let newPages = [];

  // if appendIndex = -1, it means that the page is being appended to the end of the document
  if (appendIndex >= 0) {
    newPages = [
      ...workItems[workItemIndex].attributes.documents[
        documentIndex
      ].pages.slice(0, appendIndex),
      draggedPage,
      ...workItems[workItemIndex].attributes.documents[
        documentIndex
      ].pages.slice(appendIndex),
    ];
  } else {
    newPages =
      workItems[workItemIndex].attributes.documents[documentIndex].pages.concat(
        draggedPage,
      );
  }

  const newDocuments = cloneDeep(workItems[workItemIndex].attributes.documents);
  newDocuments[documentIndex].pages = newPages;
  const newWorkItem = cloneDeep(workItems);
  newWorkItem[workItemIndex].attributes.documents = newDocuments;
  return newWorkItem;
};

export const getWorkItemsRightSide = (
  to,
  from,
  withAddedDocument,
  withRemovedDocument,
  initialWorkItems,
) => {
  if (to === 'rightSide' && from === 'rightSide') {
    return withAddedDocument;
  }
  if (to === 'rightSide' && from === 'leftSide') {
    return withAddedDocument;
  }
  if (to === 'leftSide' && from === 'rightSide') {
    return withRemovedDocument;
  }
  if (to === 'leftSide' && from === 'leftSide') {
    return initialWorkItems;
  }
  return [];
};

export const getWorkItemsLeftSide = (
  to,
  from,
  withAddedDocument,
  withRemovedDocument,
  initialWorkItems,
) => {
  if (to === 'rightSide' && from === 'rightSide') {
    return initialWorkItems;
  }
  if (to === 'rightSide' && from === 'leftSide') {
    return withRemovedDocument;
  }
  if (to === 'leftSide' && from === 'rightSide') {
    return withAddedDocument;
  }
  if (to === 'leftSide' && from === 'leftSide') {
    return withAddedDocument;
  }
  return [];
};

export const getImageFilesFromUrl = async (imageUrl, wiId) => {
  const baseUrl = config.api.url.http || '';
  const fileUrl = `${baseUrl}/api/v1/work_items/${wiId}/attachments?url=${imageUrl}`;
  const imageFile = imageUrl.includes('https')
    ? await createImageFile(imageUrl)
    : await createImageFile(fileUrl);
  return imageFile;
};

export const generatePdfFromDoc = async ({ document, wiId }) => {
  const promises = document.pages.map(async (page) => {
    const imageFile = await getImageFilesFromUrl(
      page.corrected_image_url,
      wiId,
    );
    return imageFile;
  });
  const documentImageFiles = await Promise.all(promises);
  generatePdfFromImages({
    pdfName: document.layout_name,
    images: documentImageFiles,
  });
};
