import { useTranslation } from 'react-i18next';
import { Icon, Message } from 'semantic-ui-react';

export default function ErrorMessage({ text = 'SomethingWentWrong' }) {
  const { t } = useTranslation();
  return (
    <Message negative>
      <Message.Content>
        <Icon name="warning sign" /> {t(text)}
      </Message.Content>
    </Message>
  );
}
