import { Button, Modal, TransitionablePortal } from 'semantic-ui-react';

const ANIMATION_MS = 500;

export default function TableModal({
  open,
  toggleOpen,
  modalConfig = {},
  setModalConfig,
}) {
  const handleClose = () => {
    toggleOpen(false);
    // timeout so the modal does not look weird empty while it is being closed
    setTimeout(() => {
      setModalConfig(null);
    }, ANIMATION_MS);
  };

  const handleYesClick = () => {
    modalProps.onClick(modalProps.item.id);
    handleClose();
  };

  const modalProps = {
    header: 'Default header',
    text: 'Default text',
    noButton: 'No',
    yesButton: 'Yes',
  };

  Object.assign(modalProps, modalConfig);

  return (
    <TransitionablePortal
      open={open}
      transition={{
        animation: 'scale',
        duration: ANIMATION_MS,
      }}
    >
      <Modal open onClose={handleClose} closeOnDimmerClick>
        <Modal.Header>{modalProps?.header}</Modal.Header>
        <Modal.Content>{modalProps?.text}</Modal.Content>
        <Modal.Actions>
          <Button basic onClick={handleClose}>
            {modalProps.noButton}
          </Button>
          <Button negative onClick={handleYesClick}>
            {modalProps.yesButton}
          </Button>
        </Modal.Actions>
      </Modal>
    </TransitionablePortal>
  );
}
