import { Icon } from 'semantic-ui-react';

import { SidebarWidth } from './Sidebar';

export default function CollapseButton({ onClick, width }) {
  const iconDirection = width === SidebarWidth.Thin ? 'left' : 'right';
  return (
    <div
      className={`collapse-menu-button ${width}`}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex="0"
    >
      <Icon name={`caret ${iconDirection}`} />
    </div>
  );
}
