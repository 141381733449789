/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';
import { Button } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Draggable from 'react-draggable';
import clsx from 'clsx';
import { Resizable } from 'react-resizable';

import s from '@components/aria/Application/Activity/Hyperscience/DocumentViewer/Viewer.module.scss';
import ErrorMessage from '@ui/ErrorMessage';
import ViewerWarning from '@ui/ViewerWarning';
import { PDF_VIEWER_ACTIONS } from '@components/aria/Application/Activity/Hyperscience/DocumentViewer/Toolbar';

import BoundingBoxes from './BoundingBox/BoundingBoxes';

export default function Viewer({
  file,
  documentReady,
  imgRef,
  onImageLoad,
  dimensions,
  currentPageFields,
  documentsCount,
  editMode,
  onCancelCrop,
  onCrop,
  isLoadingNewData,
  scale,
  onStamp,
  onCancelStamping,
  stampImage,
  appliedFilterValue,
  onSelectAreaByLasso,
}) {
  const [error, setError] = useState(false);
  const [stampOriginSize, setStampOriginSize] = useState({});
  const [crop, setCrop] = useState({});
  const [resizeScales, setResizeScales] = useState({
    width: 100 / scale,
    height: 100 / scale,
  });
  const [isResizing, setIsResizing] = useState(false);
  const [stamp, setStamp] = useState({
    position: { x: 0, y: 0 },
  });

  const onResize = (event, { size }) => {
    setIsResizing(true);
    setResizeScales({ width: size.width, height: size.height });
  };

  const onStampLoad = ({ target: img }) => {
    setTimeout(() => {
      const { naturalWidth, naturalHeight } = img;
      setStampOriginSize({
        width: naturalWidth,
        height: naturalHeight,
      });
    }, 50);
  };
  const onOriginImageLoad = ({ target: img }) => {
    onImageLoad({ target: img });
  };
  const onMoveStamp = (e, target) => {
    e.stopPropagation();
    setStamp({
      position: {
        x: target.x,
        y: target.y,
      },
    });
  };

  const onStampConfirm = () => {
    onStamp({
      position: {
        x1: stamp.position.x,
        y1: stamp.position.y,
        x2: stamp.position.x + resizeScales.width,
        y2: stamp.position.y + resizeScales.height,
      },
    });
  };

  const { t } = useTranslation();
  useEffect(() => {
    if (
      editMode === PDF_VIEWER_ACTIONS.crop ||
      editMode === PDF_VIEWER_ACTIONS.zoomByLasso
    ) {
      setCrop({});
    }
  }, [editMode]);

  useEffect(() => {
    setError(false);
  }, [file]);

  if (file?.empty) {
    return (
      <ViewerWarning text="This document doesn’t contain any pages to display" />
    );
  }
  if (!documentsCount) {
    return (
      <ViewerWarning text="Current Work Item don’t have documents to be displayed here" />
    );
  }

  if (!file) {
    return (
      <ViewerWarning text="Please, select a document type to be displayed here" />
    );
  }

  if (error) {
    return <ErrorMessage text="ImageNotLoaded" />;
  }
  // eslint-disable-next-line consistent-return
  const renderContentByMode = () => {
    switch (editMode) {
      case PDF_VIEWER_ACTIONS?.crop: {
        if (!isLoadingNewData) {
          return (
            <ReactCrop
              style={{
                zIndex: 1000,
                width: '100%',
                opacity: documentReady && !isLoadingNewData ? '1' : '0',
              }}
              crop={crop}
              onChange={(p, percentageCrop) => setCrop(percentageCrop)}
              renderSelectionAddon={() => (
                <div className={s.controlWrapper}>
                  <Button
                    type="primary"
                    className={s.filterButton}
                    onClick={() => onCrop(crop)}
                  >
                    <CheckOutlined />
                  </Button>
                  <Button
                    type="primary"
                    danger
                    className={s.filterButton}
                    onClick={onCancelCrop}
                  >
                    <CloseOutlined />
                  </Button>
                </div>
              )}
            >
              <img
                src={file}
                className="hs-document"
                ref={imgRef}
                onLoad={onOriginImageLoad}
                onError={(evt) => {
                  console.error(evt);
                  setError(true);
                }}
                alt="document page"
                style={{
                  opacity: documentReady && !isLoadingNewData ? '1' : '0',
                }}
              />
            </ReactCrop>
          );
        }
        break;
      }
      case PDF_VIEWER_ACTIONS.stamp: {
        if (!isLoadingNewData) {
          return (
            <div>
              <img
                src={file}
                className="hs-document"
                ref={imgRef}
                alt="document page"
                onLoad={onOriginImageLoad}
                onError={(evt) => {
                  console.error(evt);
                  setError(true);
                }}
                style={{ opacity: documentReady ? '1' : '0' }}
              />
              <Resizable
                handle={
                  <div
                    className={s.resizeHandler}
                    style={{
                      top: stamp.position.y + resizeScales.height - 11,
                      left: stamp.position.x + resizeScales.width - 11,
                    }}
                  />
                }
                height={resizeScales.height}
                width={resizeScales.width}
                onResize={onResize}
                onResizeStop={() => setIsResizing(false)}
              >
                <div
                  style={{
                    width: `${resizeScales.width}px`,
                    height: `${resizeScales.height}px`,
                  }}
                >
                  <Draggable
                    disabled={isResizing}
                    bounds={{
                      right: dimensions.width - resizeScales.width,
                      bottom: dimensions.height - resizeScales.height,
                      left: 0,
                      top: 0,
                    }}
                    onDrag={onMoveStamp}
                    position={stamp.position}
                  >
                    <div
                      style={{
                        width: `${resizeScales.width}px`,
                        height: `${resizeScales.height}px`,
                        opacity: stampOriginSize.width ? '1' : '0',
                      }}
                      className={s.stamp}
                    >
                      <img
                        draggable={false}
                        style={{
                          width: `${resizeScales.width}px`,
                          height: `${resizeScales.height}px`,
                        }}
                        onLoad={onStampLoad}
                        src={stampImage}
                        alt=""
                      />
                      <div className={clsx(s.controlWrapper, s.stampButtons)}>
                        <Button
                          type="primary"
                          className={s.saveButton}
                          onClick={onStampConfirm}
                        >
                          <CheckOutlined />
                        </Button>
                        <Button
                          type="primary"
                          danger
                          className={s.saveButton}
                          onClick={onCancelStamping}
                        >
                          <CloseOutlined />
                        </Button>
                      </div>
                    </div>
                  </Draggable>
                </div>
              </Resizable>
            </div>
          );
        }
        return null;
      }
      case PDF_VIEWER_ACTIONS.brightness:
      case PDF_VIEWER_ACTIONS.contrast: {
        if (!isLoadingNewData) {
          return (
            <img
              src={file}
              className="hs-document"
              ref={imgRef}
              alt="document page"
              onLoad={onOriginImageLoad}
              onError={(evt) => {
                console.error(evt);
                setError(true);
              }}
              style={{
                opacity: documentReady ? '1' : '0',
                filter: `${editMode}(${appliedFilterValue * 100}%`,
              }}
            />
          );
        }
        break;
      }
      case PDF_VIEWER_ACTIONS?.zoomByLasso: {
        if (!isLoadingNewData) {
          return (
            <ReactCrop
              style={{
                zIndex: 1000,
                width: '100%',
                opacity: documentReady && !isLoadingNewData ? '1' : '0',
              }}
              crop={crop}
              onChange={(pixels) => setCrop(pixels)}
              onComplete={(pixels) => onSelectAreaByLasso(pixels)}
            >
              <img
                src={file}
                className="hs-document"
                ref={imgRef}
                onLoad={onOriginImageLoad}
                onError={(evt) => {
                  console.error(evt);
                  setError(true);
                }}
                alt="document page"
                style={{
                  opacity: documentReady && !isLoadingNewData ? '1' : '0',
                }}
              />
            </ReactCrop>
          );
        }
        break;
      }
      default: {
        if (!editMode && !isLoadingNewData) {
          return (
            <>
              <img
                src={file}
                className="hs-document"
                ref={imgRef}
                alt="document page"
                onLoad={onOriginImageLoad}
                onError={(evt) => {
                  console.error(evt);
                  setError(true);
                }}
                style={{ opacity: documentReady ? '1' : '0' }}
              />
            </>
          );
        }

        return null;
      }
    }
  };

  return (
    <>
      {(!documentReady || isLoadingNewData) && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '240px',
          }}
        >
          {!documentReady && (
            <Loader inline active content={t('LoadingImage')} />
          )}
          {isLoadingNewData && (
            <Loader inline active content={t('UpdatingImage')} />
          )}
        </div>
      )}
      {renderContentByMode()}
      {documentReady && !isLoadingNewData && !editMode && (
        <BoundingBoxes dimensions={dimensions} fields={currentPageFields} />
      )}
    </>
  );
}
