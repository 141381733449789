import clsx from 'clsx';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Button, Spin } from 'antd';

import ErrorIcon from '@assets/icons/error_circle.svg';
import api from '@services/axios';
import { ReactComponent as Logo } from '@assets/icons/login_logo.svg';
import s from '@components/ResetPassword/ResetPassword.module.scss';

const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter a Email address')
      .required('Please enter email address'),
  });

  const handleSubmit = (values, { setSubmitting, setStatus }) => {
    api
      .restAPI()
      .post('/users/reset_password', {
        email: values.email,
      })
      .then(() => {
        setSuccess(true);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.warn('[Login] err.message: ', err);
        setStatus({
          serverError: err?.response?.data?.error || 'Something went wrong',
        });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Fragment>
      <div className={s.authWrapper}>
        <div className={s.auth}>
          <div className={s.logoWrapper}>
            <Logo />
          </div>
          {!success && (
            <Fragment>
              <div className={s.authTitle}>Reset password</div>
              <div className={s.authDetails}>Please enter your email.</div>
            </Fragment>
          )}
          {success && (
            <div className={s.success}>
              <div className={s.successDetails}>
                Please check your email for a password reset link <br />
                and follow the instructions provided
              </div>
            </div>
          )}
          {!success && (
            <div className={s.authForm}>
              <Formik
                initialValues={{
                  email: '',
                }}
                validationSchema={loginSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, isValid, errors, touched, status }) => {
                  return (
                    <Form>
                      <div
                        className={clsx(s.fieldWrapper, {
                          [s.fieldError]: errors.email && touched.email,
                        })}
                      >
                        <Field
                          type="email"
                          name="email"
                          className={s.email}
                          placeholder={t('Enter your email')}
                        />
                      </div>
                      <ErrorMessage
                        name="email"
                        component="div"
                        className={s.errorText}
                      />
                      <div>
                        <div className={s.serverErrorWrapper}>
                          {status?.serverError && (
                            <img
                              src={ErrorIcon}
                              alt="error icon"
                              className={s.errorIcon}
                            />
                          )}

                          <div className={s.errorText}>
                            {status?.serverError && status.serverError}
                          </div>
                        </div>
                        <button
                          type="submit"
                          className={s.submitButton}
                          disabled={!isValid || isSubmitting}
                        >
                          {isSubmitting ? <Spin /> : t('Reset')}
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          )}
          <div className={clsx(s.backToSignIn)}>
            <Button
              type="button"
              className={clsx(s.backToSignInButton)}
              onClick={() => navigate('/login')}
            >
              {t('Back to Sign in')}
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ResetPassword;
