import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';

import { resetBatch } from '@actions';
import Documents from '@components/Documents';
import PDFViewerWrapper from '@components/WorkItemDetailed/PDFViewerWrapper';

import s from './styles.module.scss';

const BatchHeader = ({ batchId, workItemId }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(`/workitems/${workItemId}`);
  };

  return (
    <div className={s.headerWrapper}>
      <Button type="button" className={s.backButton} onClick={handleBack}>
        Back
      </Button>
      <div className={s.batchId}>Batch # {batchId}</div>
    </div>
  );
};

const BatchInfo = () => {
  return (
    <div className={s.batchInfoWrapper}>
      <div>
        <div className={s.clientName}>GUCCI LOGISTICS INC</div>
        <div className={s.clientEmail}>email@gl.com</div>
      </div>
    </div>
  );
};

const BatchDetailed = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const workItem = useSelector((state) => state.batchReducer?.batch?.workItem);

  useEffect(() => {
    return () => dispatch(resetBatch());
  }, []);

  if (!workItem) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className={s.contentWrapper}>
      <BatchHeader workItemId={workItem.id} batchId={id} />
      <BatchInfo />
      <div className={s.topButtonWrapper}>
        <Button type="button" className={s.topButton}>
          Groups
        </Button>
      </div>
      <div className={s.innerContent}>
        <Documents />
        <div className={s.pdfContent}>
          <PDFViewerWrapper
            workItem={workItem}
            disableRightEvents
            disableLeftEvents
            tabsClassName="batchPDFviewerTabs"
          />
        </div>
      </div>
    </div>
  );
};

export default BatchDetailed;
