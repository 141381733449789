import { useState } from 'react';

export default function useFilters(initialFilters = {}) {
  const [filters, setFilters] = useState(initialFilters);

  const updateFilters = (entries) => {
    const shouldUpdate = Object.entries(entries).some(
      ([key, value]) => value !== filters[key],
    );

    if (shouldUpdate) {
      setFilters((prevState) => {
        const auxFilters = { ...prevState };
        Object.entries(entries).forEach(([key, value]) => {
          if (!value) {
            delete auxFilters[key];
          } else {
            auxFilters[key] = value;
          }
        });
        return auxFilters;
      });
    }
  };

  return [filters, updateFilters];
}
