import api from '@services/axios';

export const clientScoreBoardDataRequest = async (query = {}, params) => {
  return api
    .restAPI()
    .get(`/reports/statuses_by_client?${query}`, params)
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
};
export const valueOfCompletedWIRequest = async (query = {}, params) => {
  return api
    .restAPI()
    .get(`/reports/value_of_complete_by_client?${query}`, params)
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
};

export const userProductivityRequest = async (query = {}, params) => {
  return api
    .restAPI()
    .get(`reports/user_productivity?${query}`, params)
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
};
export const systemThroughputRequest = async (query = {}, params) => {
  return api
    .restAPI()
    .get(`reports/system_throughput?${query}`, params)
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
};

export const analyticsCardRequest = async (query = {}, params) => {
  return api
    .restAPI()
    .get(`reports/cards`, params)
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
};
