import config from '@config';

const actionCableActions = {
  status_change: 'status_change',
  lock: 'lock',
  unlock: 'unlock',
};

export const getActionCableUrl = (token) => {
  const defaultUrl = config.api.url.ws;
  return `${defaultUrl}/cable?token=${token}`;
};

export default {
  actionCableActions,
  getActionCableUrl,
};
