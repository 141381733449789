import React, { useEffect } from 'react';

import { getSettings } from '@components/Config/shared/api/api';
import ApplicationLoader from '@ui/ApplicationLoader';
import ApplicationConfig from '@components/Config/shared/components/ApplicationConfig/ApplicationConfig';

const Config = () => {
  const [settings, setSettings] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setIsLoading(true);
    getSettings()
      .then((data) => setSettings(data))
      .then(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <ApplicationLoader />;
  }

  return (
    <div>
      <ApplicationConfig settings={settings} />
    </div>
  );
};

export default Config;
