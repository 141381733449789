import { DateTime } from 'luxon';

import store from './store';

const DATE_FORMAT = "MMMM dd',' yyyy '-' t";

export const formatDate = (date, format = DATE_FORMAT) => {
  if (!date) return '';
  const userTimezone =
    store.getState().currentUserReducer.user.timezone ||
    DateTime.local().zoneName;
  const parsedDate = DateTime.fromISO(date, { zone: 'UTC' }).setZone(
    userTimezone,
  );
  if (!parsedDate.isValid) return '';
  return parsedDate.toFormat(format);
};

export const convertToDatePickerFormat = (format) => {
  let correctedFormat = format.replace('dd', 'DD');
  correctedFormat = correctedFormat.replace('yyyy', 'YYYY');
  return correctedFormat;
};
