import { Navigate, Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import DocumentTypes from '@components/aria/Admin/DocumentTypes/DocumentTypes';
import DocumentType from '@components/aria/Admin/DocumentTypes/DocumentType';
import OutputFieldsManager from '@components/OutputFieldsManager';
import Alerts from '@components/aria/Admin/Alerts/Alerts';
import Alert from '@components/aria/Admin/Alerts/Alert';
import Tags from '@components/aria/Admin/Tags/Tags';
import Tag from '@components/aria/Admin/Tags/Tag';
import Config from '@components/Config';
import Stamps from '@components/aria/Admin/Stamps/Stamps';
import Stamp from '@components/aria/Admin/Stamps/Stamp';
import EmailAddresses from '@components/aria/Admin/EmailAddresses/EmailAddresses';
import EmailAddress from '@components/aria/Admin/EmailAddresses/EmailAddress';

import AdminMain from './AdminMain';
import AdminUpperbar from './AdminUpperbar';
import User from './Users/User';
import Users from './Users/Users';
import EmailTemplates from './EmailTemplates/EmailTemplates';
import EmailTemplate from './EmailTemplates/EmailTemplate';

export default function Admin() {
  const currentUser = useSelector((state) => state?.currentUserReducer?.user);
  const isAdmin = currentUser?.roles?.includes('admin');
  if (!isAdmin) return <Navigate to="/" replace />;
  return (
    <>
      <AdminUpperbar />
      <div style={{ marginTop: '14px' }}>
        <Routes>
          <Route index element={<AdminMain />} />
          <Route path="users">
            <Route index element={<Users />} />
            <Route path="new" element={<User />} />
            <Route path=":id" element={<User />} />
          </Route>
          <Route path="templates">
            <Route index element={<EmailTemplates />} />
            <Route path="new" element={<EmailTemplate />} />
            <Route path=":id" element={<EmailTemplate />} />
          </Route>
          <Route path="document-types">
            <Route index element={<DocumentTypes />} />
            <Route path="new" element={<DocumentType />} />
            <Route path=":id" element={<DocumentType />} />
          </Route>
          <Route path="alerts">
            <Route index element={<Alerts />} />
            <Route path="new" element={<Alert />} />
            <Route path=":id" element={<Alert />} />
          </Route>
          <Route path="tags">
            <Route index element={<Tags />} />
            <Route path="new" element={<Tag />} />
            <Route path=":id" element={<Tag />} />
          </Route>
          <Route path="config" element={<Config />} />
          <Route path="stamps">
            <Route index element={<Stamps />} />
            <Route path="new" element={<Stamp />} />
            <Route path=":id" element={<Stamp />} />
          </Route>
          <Route path="config" element={<Config />} />
          <Route path="output-fields" element={<OutputFieldsManager />} />
          <Route path="email-addresses">
            <Route index element={<EmailAddresses />} />
            <Route path="new" element={<EmailAddress />} />
            <Route path=":id" element={<EmailAddress />} />
          </Route>
          {/* <Route path="teams"> */}
          {/*  <Route index element={<Teams />} /> */}
          {/*  <Route path="new" element={<TeamsForm />} /> */}
          {/*  <Route path=":id" element={<TeamsForm />} /> */}
          {/* </Route> */}
          {/* <Route path="dashboard/*" index element={<Dashboard />} /> */}
        </Routes>
      </div>
    </>
  );
}
