import SorterItem from './SorterItem';

const List = (props) => {
  return (
    <div
      className="sorter-container"
      style={{ top: props.position ?? undefined }}
    >
      <ul className="sorter">
        {props.items.map((item, index) => (
          <SorterItem
            key={`sorter_${item[props.itemKey]}`}
            element={item}
            index={index}
            {...props}
          />
        ))}
      </ul>
    </div>
  );
};

export default List;
