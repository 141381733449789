const getFieldsFromDocument = (doc) => {
  if (!doc) return [];

  const formattedFields = doc.document_fields.map((field) => ({
    ...field,
    type: 'field',
    coordinates: getFieldCoordinates(field),
  }));

  return formattedFields;
};

const getFieldCoordinates = (field) => {
  const { field_image_url: url } = field;
  if (!url) return {};
  const coordinatesinURL = url.split('=');
  const coordinates = {
    startX: coordinatesinURL[1].split('&')[0],
    startY: coordinatesinURL[2].split('&')[0],
    endX: coordinatesinURL[3].split('&')[0],
    endY: coordinatesinURL[4].split('&')[0],
  };
  return coordinates;
};

export { getFieldCoordinates, getFieldsFromDocument };
