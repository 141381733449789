import {
  GET_CURRENT_USER,
  LOGIN,
  LOGOUT,
  SETTINGS,
  UPDATE_SAVED_TAGS,
} from '@constants/action-types';

const initialState = {
  user: null,
  settings: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
    case GET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case LOGOUT:
      return initialState;
    case SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case UPDATE_SAVED_TAGS: {
      return { ...state, user: { ...state.user, indexTags: action.payload } };
    }
    default:
      return state;
  }
};
