import { SET_BATCH_WORK_ITEM, RESET_BATCH } from '@constants/action-types';

const defaultState = {};

export default (state = defaultState, action) => {
  if (action.type === SET_BATCH_WORK_ITEM) {
    return {
      ...state,
      batch: {
        ...state?.batch,
        workItem: action.payload,
      },
    };
  }

  if (action.type === RESET_BATCH) {
    return { ...defaultState };
  }

  return state;
};
