import Jsona from 'jsona';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  hsAddRow,
  hsRemoveRow,
  hsSetEditable,
  resetHsState,
  updateHSField,
  updateHSFilters,
  updateHsState,
} from '@actions';
import api from '@services/axios';

export default function useHyperscience() {
  const dispatch = useDispatch();
  const {
    selectedField,
    selectedTable,
    editedFields,
    filters,
    editable,
    newRows,
  } = useSelector((state) => state.hyperscienceReducer);
  const [submitting, setSubmitting] = useState(false);
  const dataFormatter = new Jsona();
  const { t } = useTranslation();
  const { name: nameFilter, exceptions: filterExceptions } = filters;

  const selectField = (fieldId) => {
    dispatch(updateHsState({ field: fieldId }));
  };

  const selectTable = (tableId) => {
    dispatch(updateHsState({ table: tableId }));
  };

  const editField = ({ id, type, value }) => {
    const auxField = {
      id,
      type,
      normalized: value,
    };
    dispatch(updateHSField(auxField));
  };

  const setNameFilter = (filter) => {
    dispatch(updateHSFilters({ name: filter }));
  };

  const setFilterExceptions = (filter) => {
    dispatch(updateHSFilters({ exceptions: filter }));
  };

  const reset = () => dispatch(resetHsState());

  const updateHSData = (activity, appId) => {
    if (isEmpty(editedFields)) {
      toast.error('NoChangesError');
    } else if (anyNewRowEmpty()) {
      toast.error('NoEmptyRowsError');
    } else {
      const fieldsToUpdate = Object.entries(editedFields)
        .filter(([, value]) => value.type === 'field')
        .map(([key, value]) => {
          return {
            id: parseId(key),
            transcription: { normalized: value.normalized },
          };
        });
      const cellsToUpdate = Object.entries(editedFields)
        .filter(([, value]) => value.type === 'table')
        .map(([key, value]) => {
          return {
            id: parseId(key),
            ...value,
          };
        });
      const tablesForDocument = buildTableFromCells(cellsToUpdate);
      const documentToUpdate = { id: activity?.documents[0]?.id };
      if (!isEmpty(fieldsToUpdate)) {
        documentToUpdate.document_fields = fieldsToUpdate;
      }
      if (!isEmpty(tablesForDocument)) {
        documentToUpdate.document_tables = tablesForDocument;
      }
      const dataToUpdate = {
        output: {
          documents: [documentToUpdate],
        },
      };
      const preparedData = {
        type: 'activity',
        id: activity.id,
        data: dataToUpdate,
      };
      const JSONApiData = dataFormatter.serialize({ stuff: preparedData });

      setSubmitting(true);
      api
        .jsonAPI()
        .put(`/apps/${appId}/activities/${activity.id}`, JSONApiData)
        .then(() => {
          toast.success(t('ItemSaved', { item: 'Activity' }));
        })
        .catch((error) => {
          toast.error(t('ItemNotSaved', { item: 'activity' }));
          console.error(error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const buildTableFromCells = (cells) => {
    const tableIds = [...new Set(cells.map((cell) => cell.table_id))];
    const tables = tableIds.map((tableId) => {
      const tableCells = cells.filter((cell) => cell.table_id === tableId);
      const tableRowsIds = [
        ...new Set(tableCells.map((cell) => cell.document_table_row_id)),
      ];
      const auxRows = tableRowsIds.map((rowId) => {
        const rowCells = tableCells.filter(
          (cell) => cell.document_table_row_id === rowId,
        );
        const auxCells = rowCells.map((cell) => ({
          id: parseId(cell.id),
          document_table_row_id: rowId,
          normalized: cell.normalized,
        }));
        return { id: parseId(rowId), cells: auxCells };
      });
      return { id: parseId(tableId), rows: auxRows };
    });
    return tables;
  };

  const parseId = (id) => {
    return typeof id === 'string' ? parseInt(id, 10) : id;
  };

  const setEditable = (value) => {
    dispatch(hsSetEditable(value));
  };

  const addRow = (row) => {
    dispatch(hsAddRow(row));
  };

  const removeRow = (rowId) => {
    dispatch(hsRemoveRow(rowId));
  };

  const anyNewRowEmpty = () => {
    return newRows.some((row) => {
      return row.cells.every((cell) => !editedFields[cell.id].normalized);
    });
  };

  return {
    selectedField,
    selectedTable,
    editedFields,
    nameFilter,
    filterExceptions,
    editable,
    newRows,
    selectField,
    selectTable,
    editField,
    setNameFilter,
    setFilterExceptions,
    reset,
    updateHSData,
    setEditable,
    addRow,
    removeRow,
    anyNewRowEmpty,
    submitting,
  };
}
