import { Pagination } from 'semantic-ui-react';

import CurrentPageInfo from './CurrentPageInfo';
import PerPageSelector from './PerPageSelector';

export default function PaginationSection(props) {
  const { page = 1, totalPages = 1, perPage, onChange, totalItems } = props;
  const prevItem = {
    'aria-label': 'Previous item',
    content: 'Previous',
    disabled: page === 1,
  };

  const nextItem = {
    'aria-label': 'Next item',
    content: 'Next',
    disabled: page === totalPages,
  };

  const onPageChange = (event, data) => {
    onChange({ 'page[number]': data.activePage });
  };

  return (
    <div className="pagination-section">
      <CurrentPageInfo page={page} perPage={perPage} totalItems={totalItems} />
      <Pagination
        activePage={page}
        boundaryRange={1}
        totalPages={totalPages}
        firstItem={null}
        lastItem={null}
        prevItem={prevItem}
        nextItem={nextItem}
        siblingRange={1}
        onPageChange={onPageChange}
      />
      <PerPageSelector onChange={onChange} perPage={perPage} />
    </div>
  );
}
