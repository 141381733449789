import { useTranslation } from 'react-i18next';

import LinkButton from '../LinkButton';

export default function SelectAllPages(props) {
  const {
    selectAllPages,
    selectedAll,
    filters,
    totalItems,
    setSelectAllPages,
    pages,
    itemsInPage,
  } = props;
  const { t } = useTranslation();

  if (
    !itemsInPage ||
    pages === 1 ||
    !selectedAll ||
    selectAllPages === undefined
  )
    return null;

  const handleClick = () => setSelectAllPages((prev) => !prev);

  const itemsPerPage = filters['page[size]'] ?? 10;
  const itemsSelected = Math.min(itemsInPage, itemsPerPage, totalItems);

  return (
    <div className="select-all-pages">
      <span>
        {selectAllPages
          ? t('AllPagesSelected', { items: totalItems })
          : t('CurrentPageSelected', { items: itemsSelected })}
      </span>
      <LinkButton
        onClick={handleClick}
        content={
          selectAllPages
            ? t('SelectCurrentPage')
            : t('SelectAllPages', { items: totalItems })
        }
      />
    </div>
  );
}
