import { Popover, Select } from 'antd';

const SavedTags = ({
  preSavedFiltersValues,
  onSavedFiltersChange,
  preSavedFiltersOptions,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        marginRight: '0.25em',
      }}
    >
      <div
        style={{
          fontSize: '12px',
          color: '#606060',
        }}
      >
        Tags
      </div>
      <Popover>
        <Select
          defaultValue={preSavedFiltersValues?.indexTags?.map((tag) => ({
            label: tag.attributes.name,
            value: tag.attributes.name,
          }))}
          onChange={(values) => {
            onSavedFiltersChange(values, 'tags');
          }}
          placeholder="Please select tags"
          maxTagCount={2}
          mode="multiple"
          options={preSavedFiltersOptions?.tags?.sort((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          })}
          style={{ width: 300, height: 36 }}
        />
      </Popover>
    </div>
  );
};

export default SavedTags;
