/* eslint-disable no-unused-expressions */
import { useRef, useEffect } from 'react';

export default function useClickOut(callback, dropdownOpen = false) {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (evt) => {
      if (ref.current && !ref.current.contains(evt.target) && !dropdownOpen) {
        callback && callback();
      }
    };
    document.addEventListener('mousedown', handleClickOutside, false);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  }, [ref.current, callback]);

  return { ref };
}
