import { DownCircleOutlined, UpCircleOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import s from './InfoCard.module.scss';

const InfoCard = ({ title, subTitle, value, changePercent }) => {
  return (
    <div className={s.wrapper}>
      <div className={s.title}>{title}</div>
      <div className={s.value}>{value}</div>
      <div className={s.percentBlock}>
        <div
          className={clsx(
            s.percent,
            title !== 'Average rules broken' && changePercent > 0 && s.green,
            title !== 'Average rules broken' && changePercent < 0 && s.red,
            title === 'Average rules broken' && changePercent < 0 && s.green,
            title === 'Average rules broken' && changePercent > 0 && s.red,
          )}
        >
          {changePercent > 0 || (changePercent === 0 && '+')}
          {changePercent}%
        </div>
        {changePercent > 0 && (
          <UpCircleOutlined
            className={clsx(
              s.upArrow,
              title === 'Average rules broken' && changePercent > 0 && s.red,
              title === 'Average rules broken' && changePercent < 0 && s.green,
            )}
          />
        )}
        {changePercent < 0 && (
          <DownCircleOutlined
            className={clsx(
              s.downArrow,
              title === 'Average rules broken' && changePercent > 0 && s.red,
              title === 'Average rules broken' && changePercent < 0 && s.green,
            )}
          />
        )}
      </div>
      <div className={s.subtitle}>{subTitle}</div>
      {/* {JSON.stringify({ title, subTitle, value, changePercent })} */}
    </div>
  );
};

export default InfoCard;
