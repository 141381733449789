import Sidebar from '@components/aria/Sidebar/Sidebar';
import WithCurrentUser from '@services/WithCurrentUser';
import WorkItemsList from '@components/WorkItemsList';

const DocumentPage = () => {
  return (
    <WithCurrentUser>
      <Sidebar>
        <WorkItemsList />
      </Sidebar>
    </WithCurrentUser>
  );
};

export default DocumentPage;
