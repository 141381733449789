/* eslint-disable jsx-a11y/label-has-associated-control */
import { Select as AntdSelect, Input as AntdInput, Radio } from 'antd';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Popup } from 'semantic-ui-react';

import {
  setEditedOutputField,
  updateOutputFields,
  getAdditionalFeesOptions,
  getApprovalOverrideOptions,
} from '@actions';
import api from '@services/axios';
import {
  EquipmentTypeOptions,
  ReasonCodeOptions,
  USER_ROLES,
} from '@constants';
import { useGetRoles } from '@hooks/useGetRoles';
import AdditionalFeesItem from '@components/WorkItemDocument/AdditionalFeesItem';
import OutputNotes from '@components/OutputNotes';
import useDebounce from '@hooks/useDebounce';

import s from './styles.module.scss';

const { Option } = AntdSelect;

const DocumentEventsForm = ({ readOnly }) => {
  const dispatch = useDispatch();
  const roles = useGetRoles();
  const workItem = useSelector((state) => state.workItemReducer.workItem);
  const selectedOutputField = useSelector(
    (state) => state?.workItemReducer?.editedField?.field,
  );
  const { additionalFeesOptions, approvalOverrideOptions } = useSelector(
    (state) => state.workItemReducer,
  );
  const outputFields = useMemo(() => workItem?.outputFields || {}, [workItem]);
  const hiddenOutputFields = useMemo(
    () => workItem?.hiddenOutputFields || [],
    [workItem],
  );
  const [clientsList, setClientsList] = useState([]);
  const [debtorsList, setDebtorsList] = useState([]);
  const [searchClientTerm, setSearchClientTerm] = useState('');
  const [searchDebtorTerm, setSearchDebtorTerm] = useState('');
  const debouncedSearchClientTerm = useDebounce(searchClientTerm, 300);
  const debouncedSearchDebtorTerm = useDebounce(searchDebtorTerm, 300);

  const [invoiceFields, setInvoiceFields] = useState({});
  const [shipmentFields, setShipmentFields] = useState({});

  const [isClientsLoading, setIsClientsLoading] = useState(false);
  const [isDebtorsLoading, setIsDebtorsLoading] = useState(false);

  const isFieldsEditDisabled = !roles.includes(USER_ROLES.auditor) || readOnly;
  const currentPaymentType = outputFields?.payment?.type;
  const paymentOptions =
    workItem?.paymentTypes?.map((paymentType) => ({
      value: paymentType,
      name: paymentType,
    })) || [];

  useEffect(() => {
    setShipmentFields({
      originCity: outputFields?.shipment?.originCity || '',
      originState: outputFields?.shipment?.originState || '',
      destinationCity: outputFields?.shipment?.destinationCity || '',
      destinationState: outputFields?.shipment?.destinationState || '',
      loadAmount: outputFields?.shipment?.loadAmount || '',
      multiStop: outputFields?.shipment?.multiStop || '',
      pickupDate: outputFields?.shipment?.pickupDate || '',
      deliveryDate: outputFields?.shipment?.deliveryDate || '',
      equipmentType: outputFields?.shipment?.equipmentType || '',
      commodity: outputFields?.shipment?.commodity || '',
      weight: outputFields?.shipment?.weight || '',
    });
    setInvoiceFields({
      number: outputFields?.invoice?.number || '',
      poNumber: outputFields?.invoice?.poNumber || '',
      invoiceDate: outputFields?.invoice?.invoiceDate || '',
      amount: outputFields?.invoice?.amount || '',
      notes: outputFields?.invoice?.notes || '',
      internalNotes: outputFields?.invoice?.internalNotes || '',
      approvalOverride: outputFields?.invoice?.approvalOverride || '',
      extendedAudit: outputFields?.invoice?.extendedAudit || '',
      tonu: outputFields?.invoice?.tonu || '',
      duplicate: outputFields?.invoice?.duplicate || '',
      advanceAmount: outputFields?.invoice?.advanceAmount || '',
      reasonCode: outputFields?.invoice?.reasonCode || '',
      supplemental: outputFields?.invoice?.supplemental || '',
    });
  }, [outputFields]);
  const onAdditionalFeesChange = ({ id, description, amount }) => {
    api
      .jsonAPI()
      .patch(`/work_items/${workItem.id}/accessorials/${id}`, {
        description,
        amount,
      })
      .then(({ data }) => {
        dispatch(updateOutputFields(data.outputFields));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onRemoveAdditionalFees = (id) => {
    api
      .jsonAPI()
      .delete(`/work_items/${workItem.id}/accessorials/${id}`)
      .then(({ data }) => {
        dispatch(updateOutputFields(data.outputFields));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onAdditionalFeesAdd = () => {
    api
      .jsonAPI()
      .post(`/work_items/${workItem.id}/accessorials/`)
      .then(({ data }) => {
        dispatch(updateOutputFields(data.outputFields));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const updateClientDebtorFields = (target, targetId) => {
    api
      .jsonAPI()
      .patch(`/work_items/${workItem.id}/output_fields`, {
        data: { [target]: { id: targetId } },
      })
      .then(({ data }) => {
        dispatch(updateOutputFields(data.outputFields));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleClientsChange = (selectedValue) => {
    console.log(selectedValue);
    const selectedClient = clientsList.find(
      (client) => client.label === selectedValue.label,
    );

    updateClientDebtorFields('client', selectedClient?.id);
  };

  const handleDebtorsChange = (selectedValue) => {
    const selectedDebtor = debtorsList.find(
      (debtor) => debtor.label === selectedValue.label,
    );

    updateClientDebtorFields('debtor', selectedDebtor?.id);
  };

  const formSelectList = (list) => {
    return list.map((listItem) => ({
      ...listItem,
      value: listItem.name,
      label: listItem.name,
    }));
  };

  const getDebtorsList = (name = '') => {
    setIsDebtorsLoading(true);
    const query = `?name=${name}`;
    api
      .jsonAPI()
      .get(`/debtors${query}`)
      .then((res) => {
        const rawDebtors = res?.data;
        const debtors = formSelectList(rawDebtors);

        setIsDebtorsLoading(false);
        setDebtorsList(debtors);
      })
      .catch((err) => {
        setIsDebtorsLoading(false);
        console.error('err', err);
      });
  };

  const getClientsList = (name = '') => {
    setIsClientsLoading(true);
    const query = `?name=${name}`;
    api
      .jsonAPI()
      .get(`/clients${query}`)
      .then((res) => {
        const rawClients = res?.data;
        const clients = formSelectList(rawClients);

        setIsClientsLoading(false);
        setClientsList(clients);
      })
      .catch((err) => {
        setIsClientsLoading(false);
        console.error('err', err);
      });
  };

  useEffect(() => {
    dispatch(getAdditionalFeesOptions());
    dispatch(getApprovalOverrideOptions());
  }, []);

  useEffect(() => {
    getClientsList(debouncedSearchClientTerm);
  }, [debouncedSearchClientTerm]);

  useEffect(() => {
    getDebtorsList(debouncedSearchDebtorTerm);
  }, [debouncedSearchDebtorTerm]);

  const updateFields = async (target, debounceTarget, fieldsType) => {
    api
      .jsonAPI()
      .patch(`/work_items/${workItem.id}/output_fields`, {
        data: { [fieldsType]: { [target]: debounceTarget } },
      })
      .then(({ data }) => {
        dispatch(updateOutputFields(data.outputFields));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onShipmentFieldBlur = (e) => {
    const { value, name: fieldPath } = e.target;
    const fieldName = getFieldNameByCustomName(fieldPath);
    const fieldType = 'shipment';
    if (fieldName && value !== workItem.outputFields[fieldType][fieldName]) {
      updateFields(fieldName, value, fieldType);
    }
  };

  const onInvoiceFieldBlur = (e) => {
    const { value, name: fieldPath } = e.target;
    const fieldName = getFieldNameByCustomName(fieldPath);

    const fieldType = 'invoice';
    if (fieldName && value !== workItem.outputFields[fieldType][fieldName]) {
      updateFields(fieldName, value, fieldType);
    }
  };

  const onInvoiceFieldChange = (e) => {
    const { value, name: fieldPath } = e.target;
    const fieldName = getFieldNameByCustomName(fieldPath);
    if (fieldName) {
      setInvoiceFields({ ...invoiceFields, [fieldName]: value });
    }
  };

  const getFieldNameByCustomName = (fieldPath) =>
    fieldPath.split('.')[fieldPath.split('.').length - 1];

  const onShipmentFieldChange = (e) => {
    const { value, name: fieldPath } = e.target;
    const fieldName = getFieldNameByCustomName(fieldPath);
    if (fieldName) {
      setShipmentFields({ ...shipmentFields, [fieldName]: value });
    }
  };

  const handlePaymentChange = (selectedValue) => {
    api
      .jsonAPI()
      .patch(`/work_items/${workItem.id}/output_fields`, {
        data: { payment: { type: selectedValue } },
      })
      .then(({ data }) => {
        dispatch(updateOutputFields(data.outputFields));
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onActivateField = (e) => {
    dispatch(setEditedOutputField(e.target.name));
  };

  console.log(clientsList);
  return (
    <>
      <div className={clsx('outputForm', s.outputForm)}>
        <div className={s.outputBox}>
          <div className="clientsBlock">
            <div className={s.headerWrapper}>
              <div className={s.outputBoxName}>Client</div>
              {outputFields?.client?.notes?.length > 0 && (
                <OutputNotes
                  modalTitle="Client notes"
                  text={outputFields?.client?.notes}
                />
              )}
            </div>

            <div className={s.fieldLine}>
              <label className={s.fieldName}>Name</label>
              <div className={s.selectWrapper}>
                <AntdSelect
                  searchValue={decodeURIComponent(searchClientTerm)}
                  showSearch
                  labelInValue
                  defaultValue={outputFields?.client?.name}
                  value={{
                    value: outputFields?.client?.name,
                    label: outputFields?.client?.name,
                  }}
                  onSearch={(search) =>
                    setSearchClientTerm(encodeURIComponent(search))
                  }
                  loading={isClientsLoading}
                  disabled={isFieldsEditDisabled === true}
                  placeholder="Search clients"
                  style={{ maxWidth: '289px', width: '100%' }}
                  popupMatchSelectWidth={false}
                  onChange={handleClientsChange}
                >
                  {clientsList?.length > 0 &&
                    clientsList.map((client) => (
                      <Option value={client} key={client.id}>
                        <Popup
                          on="hover"
                          className={s.additionalDropdownInfo}
                          position="right center"
                          trigger={<div>{client?.name}</div>}
                          flowing
                          hoverable
                        >
                          <div>Email: {client?.email}</div>
                          <div>Phone: {client?.phone}</div>
                          <div>
                            Address: {client?.addr1}, {client?.city},{' '}
                            {client?.state} {client?.zipcode}
                          </div>
                          <div>MC Number: {client?.mcnum}</div>
                          <div>DOT Number: {client?.dotnum}</div>
                        </Popup>
                      </Option>
                    ))}
                </AntdSelect>
              </div>
            </div>
            <div className={s.fieldLine}>
              <label className={s.fieldName}>ID</label>
              <div className={s.readonlyField}>{outputFields?.client?.id}</div>
            </div>
            <div className={s.fieldLine}>
              <label className={s.fieldName}>Email</label>
              <div className={s.readonlyField}>
                {outputFields?.client?.email}
              </div>
            </div>
            <div className={s.fieldLine}>
              <label className={s.fieldName}>Phone</label>
              <div className={s.readonlyField}>
                {outputFields?.client?.phone}
              </div>
            </div>
            <div className={s.fieldLine}>
              <label className={s.fieldName}>Address</label>
              <div className={s.readonlyField}>
                {outputFields?.client?.address}
                <p>
                  {outputFields?.client?.city}
                  {outputFields?.client?.state && ','}&nbsp;
                  {outputFields?.client?.state}
                  &nbsp;
                  {outputFields?.client?.zipcode}
                </p>
              </div>
            </div>
            <div className={s.fieldLine}>
              <label className={s.fieldName}>MC Number</label>
              <div className={s.readonlyField}>
                {outputFields?.client?.mcnum}
              </div>
            </div>
            <div className={s.fieldLine}>
              <label className={s.fieldName}>DOT Number</label>
              <div className={s.readonlyField}>
                {outputFields?.client?.dotnum}
              </div>
            </div>
          </div>
        </div>
        <div className={s.outputBox}>
          <div className="debtorBlock">
            <div className={s.headerWrapper}>
              <div className={s.outputBoxName}>Debtor</div>
              {outputFields?.debtor?.notes?.length > 0 && (
                <OutputNotes
                  modalTitle="Debtor notes"
                  text={outputFields?.debtor?.notes}
                />
              )}
            </div>
            <div className={s.fieldLine}>
              <label className={s.fieldName}>Name</label>
              <div className={s.selectWrapper}>
                <AntdSelect
                  searchValue={decodeURIComponent(searchDebtorTerm)}
                  showSearch
                  labelInValue
                  defaultValue={outputFields?.debtor?.name}
                  value={{
                    value: outputFields?.debtor?.name,
                    label: outputFields?.debtor?.name,
                  }}
                  loading={isDebtorsLoading}
                  disabled={isFieldsEditDisabled === true}
                  onSearch={(search) =>
                    setSearchDebtorTerm(encodeURIComponent(search))
                  }
                  placeholder="Search debtors"
                  onChange={handleDebtorsChange}
                  style={{ maxWidth: '289px', width: '100%' }}
                  popupMatchSelectWidth={false}
                >
                  {debtorsList?.length > 0 &&
                    debtorsList.map((debtor) => (
                      <Option value={debtor} key={debtor.id}>
                        <Popup
                          on="hover"
                          className={s.additionalDropdownInfo}
                          position="right center"
                          trigger={<div>{debtor?.name}</div>}
                          flowing
                          hoverable
                        >
                          <div>Email: {debtor?.email}</div>
                          <div>Phone: {debtor?.phone}</div>
                          <div>
                            Address: {debtor?.addr1}, {debtor?.city},{' '}
                            {debtor?.state} {debtor?.zipcode}
                          </div>
                          <div>MC Number: {debtor?.mcnum}</div>
                          <div>DOT Number: {debtor?.dotnum}</div>
                        </Popup>
                      </Option>
                    ))}
                </AntdSelect>
              </div>
            </div>
            <div className={s.fieldLine}>
              <label className={s.fieldName}>ID</label>
              <div className={s.readonlyField}>{outputFields?.debtor?.id}</div>
            </div>
            <div className={s.fieldLine}>
              <label className={s.fieldName}>Email</label>
              <div className={s.readonlyField}>
                {outputFields?.debtor?.email}
              </div>
            </div>
            <div className={s.fieldLine}>
              <label className={s.fieldName}>Phone</label>
              <div className={s.readonlyField}>
                {outputFields?.debtor?.phone}
              </div>
            </div>
            <div className={s.fieldLine}>
              <label className={s.fieldName}>Address</label>
              <div className={s.readonlyField}>
                {outputFields?.debtor?.address}
                <p>
                  {outputFields?.debtor?.city}
                  {outputFields?.debtor?.state && ','}&nbsp;
                  {outputFields?.debtor?.state}
                  &nbsp;
                  {outputFields?.debtor?.zipcode}
                </p>
              </div>
            </div>
            <div className={s.fieldLine}>
              <label className={s.fieldName}>MC Number</label>
              <div className={s.readonlyField}>
                {outputFields?.debtor?.mcnum}
              </div>
            </div>
          </div>
        </div>
        {!hiddenOutputFields?.includes('shipment') && (
          <div className={s.outputBox}>
            <div className="invoiceBlock">
              <div className={s.outputBoxName}>Shipment</div>
              {!hiddenOutputFields?.includes('shipment.originCity') && (
                <div className={clsx(s.fieldLine, s.shipmentField)}>
                  <label className={s.fieldName}>Origin city, state</label>
                  <div className={s.shipmentInputWithSelect}>
                    <Input
                      placeholder={
                        isFieldsEditDisabled ? '' : 'Enter origin city'
                      }
                      value={shipmentFields.originCity}
                      onChange={onShipmentFieldChange}
                      onBlur={onShipmentFieldBlur}
                      name="shipment.originCity"
                      disabled={isFieldsEditDisabled}
                      onFocus={onActivateField}
                      className={clsx(
                        selectedOutputField === 'shipment.originCity' &&
                          s.activeInput,
                      )}
                    />
                    <Input
                      placeholder={isFieldsEditDisabled ? '' : 'State'}
                      value={shipmentFields.originState}
                      onChange={onShipmentFieldChange}
                      onBlur={onShipmentFieldBlur}
                      name="shipment.originState"
                      disabled={isFieldsEditDisabled}
                      style={{ width: 80 }}
                      onFocus={onActivateField}
                      className={clsx(
                        selectedOutputField === 'shipment.originState' &&
                          s.activeInput,
                      )}
                    />
                  </div>
                </div>
              )}
              {!hiddenOutputFields?.includes('shipment.pickupDate') && (
                <div className={clsx(s.fieldLine, s.shipmentField)}>
                  <label className={s.fieldName}>Pickup Date</label>
                  <Input
                    placeholder={
                      isFieldsEditDisabled ? '' : 'Enter pickup date'
                    }
                    value={shipmentFields.pickupDate}
                    onChange={onShipmentFieldChange}
                    onBlur={onShipmentFieldBlur}
                    // type="date"
                    name="shipment.pickupDate"
                    disabled={isFieldsEditDisabled}
                    onFocus={onActivateField}
                    className={clsx(
                      selectedOutputField === 'shipment.pickupDate' &&
                        s.activeInput,
                    )}
                  />
                </div>
              )}
              {!hiddenOutputFields?.includes('shipment.destinationCity') && (
                <div className={clsx(s.fieldLine, s.shipmentField)}>
                  <label className={s.fieldName}>
                    Destination <p>city, state</p>
                  </label>
                  <div className={s.shipmentInputWithSelect}>
                    <Input
                      placeholder={
                        isFieldsEditDisabled ? '' : 'Enter destination city'
                      }
                      onFocus={onActivateField}
                      value={shipmentFields.destinationCity}
                      onChange={onShipmentFieldChange}
                      onBlur={onShipmentFieldBlur}
                      name="shipment.destinationCity"
                      disabled={isFieldsEditDisabled}
                      className={clsx(
                        selectedOutputField === 'shipment.destinationCity' &&
                          s.activeInput,
                      )}
                    />
                    <Input
                      onFocus={onActivateField}
                      placeholder={isFieldsEditDisabled ? '' : 'State'}
                      value={shipmentFields.destinationState}
                      onChange={onShipmentFieldChange}
                      onBlur={onShipmentFieldBlur}
                      name="shipment.destinationState"
                      disabled={isFieldsEditDisabled}
                      style={{ width: 80 }}
                      className={clsx(
                        selectedOutputField === 'shipment.destinationState' &&
                          s.activeInput,
                      )}
                    />
                  </div>
                </div>
              )}
              {!hiddenOutputFields?.includes('shipment.deliveryDate') && (
                <div className={clsx(s.fieldLine, s.shipmentField)}>
                  <label className={s.fieldName}>Delivery Date</label>
                  <Input
                    placeholder={
                      isFieldsEditDisabled ? '' : 'Enter delivery date'
                    }
                    value={shipmentFields.deliveryDate}
                    onChange={onShipmentFieldChange}
                    onBlur={onShipmentFieldBlur}
                    // type="date"
                    name="shipment.deliveryDate"
                    disabled={isFieldsEditDisabled}
                    onFocus={onActivateField}
                    className={clsx(
                      selectedOutputField === 'shipment.deliveryDate' &&
                        s.activeInput,
                    )}
                  />
                </div>
              )}
              {!hiddenOutputFields?.includes('shipment.loadAmount') && (
                <div className={clsx(s.fieldLine, s.shipmentField)}>
                  <label className={s.fieldName}>Load amount</label>
                  <Input
                    onFocus={onActivateField}
                    placeholder={
                      isFieldsEditDisabled ? '' : 'Enter load amount'
                    }
                    value={shipmentFields.loadAmount}
                    onChange={onShipmentFieldChange}
                    onBlur={onShipmentFieldBlur}
                    name="shipment.loadAmount"
                    disabled={isFieldsEditDisabled}
                    className={clsx(
                      selectedOutputField === 'shipment.loadAmount' &&
                        s.activeInput,
                    )}
                  />
                </div>
              )}
              {!hiddenOutputFields?.includes('shipment.multiStop') && (
                <div className={clsx(s.fieldLine, s.shipmentField)}>
                  <label className={s.fieldName}>Multi stop</label>
                  <Input
                    placeholder={isFieldsEditDisabled ? '' : 'Enter multi stop'}
                    value={shipmentFields.multiStop}
                    onChange={onShipmentFieldChange}
                    onBlur={onShipmentFieldBlur}
                    onFocus={onActivateField}
                    name="shipment.multiStop"
                    disabled={isFieldsEditDisabled}
                    className={clsx(
                      selectedOutputField === 'shipment.multiStop' &&
                        s.activeInput,
                    )}
                  />
                </div>
              )}
              {!hiddenOutputFields?.includes('shipment.equipmentType') && (
                <div className={clsx(s.fieldLine, s.shipmentField)}>
                  <label className={s.fieldName}>Equipment Type</label>
                  <div className={s.selectWrapper}>
                    <AntdSelect
                      value={shipmentFields.equipmentType}
                      options={EquipmentTypeOptions}
                      onChange={(value) => {
                        onShipmentFieldChange({
                          target: { name: 'equipmentType', value },
                        });
                        onShipmentFieldBlur({
                          target: { name: 'equipmentType', value },
                        });
                      }}
                      onBlur={onInvoiceFieldBlur}
                      style={{ width: '100%' }}
                      disabled={isFieldsEditDisabled}
                    />
                  </div>
                </div>
              )}
              {!hiddenOutputFields?.includes('shipment.commodity') && (
                <div className={clsx(s.fieldLine, s.shipmentField)}>
                  <label className={s.fieldName}>Commodity</label>
                  <Input
                    placeholder={isFieldsEditDisabled ? '' : 'Enter commodity'}
                    value={shipmentFields.commodity}
                    onChange={onShipmentFieldChange}
                    onBlur={onShipmentFieldBlur}
                    onFocus={onActivateField}
                    name="shipment.commodity"
                    disabled={isFieldsEditDisabled}
                    className={clsx(
                      selectedOutputField === 'shipment.commodity' &&
                        s.activeInput,
                    )}
                  />
                </div>
              )}
              {!hiddenOutputFields?.includes('shipment.weight') && (
                <div className={clsx(s.fieldLine, s.shipmentField)}>
                  <label className={s.fieldName}>Weight</label>
                  <Input
                    placeholder={isFieldsEditDisabled ? '' : 'Enter weight'}
                    value={shipmentFields.weight}
                    onChange={onShipmentFieldChange}
                    onBlur={onShipmentFieldBlur}
                    onFocus={onActivateField}
                    name="shipment.weight"
                    disabled={isFieldsEditDisabled}
                    className={clsx(
                      selectedOutputField === 'shipment.weight' &&
                        s.activeInput,
                    )}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {!hiddenOutputFields?.includes('invoice') && (
          <div className={s.outputBox}>
            <div className="invoiceBlock">
              <div className={s.outputBoxName}>Invoice</div>
              {!hiddenOutputFields?.includes('invoice.number') && (
                <div className={s.fieldLine}>
                  <label className={s.fieldName}>Number</label>
                  <Input
                    value={invoiceFields.number}
                    placeholder={isFieldsEditDisabled ? '' : 'Enter number'}
                    onChange={onInvoiceFieldChange}
                    onBlur={onInvoiceFieldBlur}
                    name="invoice.number"
                    disabled={isFieldsEditDisabled}
                    onFocus={onActivateField}
                    className={clsx(
                      selectedOutputField === 'invoice.number' && s.activeInput,
                    )}
                  />
                </div>
              )}
              {!hiddenOutputFields?.includes('invoice.invoiceDate') && (
                <div className={s.fieldLine}>
                  <label className={s.fieldName}>Date</label>
                  <Input
                    placeholder={isFieldsEditDisabled ? '' : 'Enter date'}
                    value={invoiceFields.invoiceDate}
                    onChange={onInvoiceFieldChange}
                    onBlur={onInvoiceFieldBlur}
                    name="invoice.invoiceDate"
                    disabled={isFieldsEditDisabled}
                    onFocus={onActivateField}
                    className={clsx(
                      selectedOutputField === 'invoice.invoiceDate' &&
                        s.activeInput,
                    )}
                  />
                </div>
              )}
              {!hiddenOutputFields?.includes('invoice.amount') && (
                <div className={s.fieldLine}>
                  <label className={s.fieldName}>Amount</label>
                  <Input
                    placeholder={isFieldsEditDisabled ? '' : 'Enter amount'}
                    value={invoiceFields.amount}
                    onChange={onInvoiceFieldChange}
                    onBlur={onInvoiceFieldBlur}
                    name="invoice.amount"
                    disabled={isFieldsEditDisabled}
                    onFocus={onActivateField}
                    className={clsx(
                      selectedOutputField === 'invoice.amount' && s.activeInput,
                    )}
                  />
                </div>
              )}
              {!hiddenOutputFields?.includes('invoice.poNumber') && (
                <div className={s.fieldLine}>
                  <label className={s.fieldName}>PO number</label>
                  <Input
                    placeholder={isFieldsEditDisabled ? '' : 'Enter PO'}
                    value={invoiceFields.poNumber}
                    onChange={onInvoiceFieldChange}
                    onBlur={onInvoiceFieldBlur}
                    name="invoice.poNumber"
                    disabled={isFieldsEditDisabled}
                    onFocus={onActivateField}
                    className={clsx(
                      selectedOutputField === 'invoice.poNumber' &&
                        s.activeInput,
                    )}
                  />
                </div>
              )}
              {!hiddenOutputFields?.includes('invoice.tonu') && (
                <div className={s.fieldLine}>
                  <label className={s.fieldName}>TONU</label>
                  <div className={s.selectWrapper}>
                    <Radio.Group
                      name="tonu"
                      value={invoiceFields.tonu}
                      onChange={(e) => {
                        onInvoiceFieldChange({
                          target: {
                            name: 'tonu',
                            value: e.target.value,
                          },
                        });
                        onInvoiceFieldBlur({
                          target: {
                            name: 'tonu',
                            value: e.target.value,
                          },
                        });
                      }}
                      disabled={isFieldsEditDisabled === true}
                    >
                      <Radio value="true">True</Radio>
                      <Radio value="false">False</Radio>
                    </Radio.Group>
                  </div>
                </div>
              )}
              {!hiddenOutputFields?.includes('invoice.supplemental') && (
                <div className={s.fieldLine}>
                  <label className={s.fieldName}>
                    Supplemental/ <br />
                    Dupe
                  </label>
                  <div className={s.selectWrapper}>
                    <Radio.Group
                      name="supplemental"
                      value={invoiceFields.supplemental}
                      onChange={(e) => {
                        onInvoiceFieldChange({
                          target: {
                            name: 'supplemental',
                            value: e.target.value,
                          },
                        });
                        onInvoiceFieldBlur({
                          target: {
                            name: 'supplemental',
                            value: e.target.value,
                          },
                        });
                      }}
                      disabled={isFieldsEditDisabled === true}
                    >
                      <Radio value="true">True</Radio>
                      <Radio value="false">False</Radio>
                    </Radio.Group>
                  </div>
                </div>
              )}
              {!hiddenOutputFields?.includes('invoice.reasonCode') && (
                <div className={s.fieldLine}>
                  <label className={s.fieldName}>Reason Code</label>
                  <div className={s.selectWrapper}>
                    <AntdSelect
                      placeholder={
                        isFieldsEditDisabled ? '' : 'Select reason code'
                      }
                      value={invoiceFields.reasonCode}
                      options={ReasonCodeOptions}
                      onChange={(value) => {
                        onInvoiceFieldChange({
                          target: { name: 'reasonCode', value },
                        });
                        onInvoiceFieldBlur({
                          target: { name: 'reasonCode', value },
                        });
                      }}
                      onBlur={onInvoiceFieldBlur}
                      style={{ width: '100%' }}
                      disabled={isFieldsEditDisabled}
                    />
                  </div>
                </div>
              )}
              {!hiddenOutputFields?.includes('invoice.notes') && (
                <div className={clsx(s.fieldLine, s.notesField)}>
                  <label className={s.fieldName}>Notes*</label>
                  <div className={s.textAreaWrapper}>
                    <AntdInput.TextArea
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder={isFieldsEditDisabled ? '' : 'Enter note'}
                      value={invoiceFields.notes}
                      onChange={onInvoiceFieldChange}
                      onBlur={onInvoiceFieldBlur}
                      name="invoice.notes"
                      disabled={isFieldsEditDisabled}
                      onFocus={onActivateField}
                      className={clsx(
                        s.textArea,
                        selectedOutputField === 'invoice.notes' && s.activeArea,
                      )}
                    />
                    <p className={s.warning}>
                      * these notes will show up on the invoice
                    </p>
                  </div>
                </div>
              )}
              {!hiddenOutputFields?.includes('invoice.approvalOverride') && (
                <div className={s.fieldLine}>
                  <label className={s.fieldName}>Approval override</label>
                  <div className={s.selectWrapper}>
                    <AntdSelect
                      value={invoiceFields.approvalOverride}
                      options={approvalOverrideOptions}
                      onChange={(value) => {
                        onInvoiceFieldChange({
                          target: { name: 'approvalOverride', value },
                        });
                        onInvoiceFieldBlur({
                          target: { name: 'approvalOverride', value },
                        });
                      }}
                      onBlur={onInvoiceFieldBlur}
                      style={{ width: '100%' }}
                      disabled={isFieldsEditDisabled}
                    />
                  </div>
                </div>
              )}
              {!hiddenOutputFields?.includes('invoice.extendedAudit') && (
                <div className={s.fieldLine}>
                  <label className={s.fieldName}>Extended audit</label>
                  <div className={s.selectWrapper}>
                    <Radio.Group
                      name="extendedAudit"
                      value={invoiceFields.extendedAudit}
                      onChange={(e) => {
                        onInvoiceFieldChange({
                          target: {
                            name: 'extendedAudit',
                            value: e.target.value,
                          },
                        });
                        onInvoiceFieldBlur({
                          target: {
                            name: 'extendedAudit',
                            value: e.target.value,
                          },
                        });
                      }}
                      disabled={isFieldsEditDisabled === true}
                    >
                      <Radio value="true">True</Radio>
                      <Radio value="false">False</Radio>
                    </Radio.Group>
                  </div>
                </div>
              )}
              {!hiddenOutputFields?.includes('invoice.duplicate') && (
                <div className={s.fieldLine}>
                  <label className={s.fieldName}>
                    Duplicate <br />
                    (Original PO)
                  </label>
                  <Input
                    placeholder={
                      isFieldsEditDisabled
                        ? ''
                        : 'Enter duplicate (Original PO)'
                    }
                    value={invoiceFields.duplicate}
                    onChange={onInvoiceFieldChange}
                    onBlur={onInvoiceFieldBlur}
                    name="invoice.duplicate"
                    disabled={isFieldsEditDisabled}
                    onFocus={onActivateField}
                    className={clsx(
                      selectedOutputField === 'invoice.duplicate' &&
                        s.activeInput,
                    )}
                  />
                </div>
              )}
              {!hiddenOutputFields?.includes('invoice.advanceAmount') && (
                <div className={s.fieldLine}>
                  <label className={s.fieldName}>Advance Amount</label>
                  <Input
                    placeholder={
                      isFieldsEditDisabled ? '' : 'Enter advance amount'
                    }
                    value={invoiceFields.advanceAmount}
                    onChange={onInvoiceFieldChange}
                    onBlur={onInvoiceFieldBlur}
                    name="invoice.advanceAmount"
                    disabled={isFieldsEditDisabled}
                    onFocus={onActivateField}
                    className={clsx(
                      selectedOutputField === 'invoice.advanceAmount' &&
                        s.activeInput,
                    )}
                  />
                </div>
              )}
              {!hiddenOutputFields?.includes('invoice.internalNotes') && (
                <div className={clsx(s.fieldLine, s.notesField)}>
                  <label className={s.fieldName}>Internal Notes</label>
                  <div className={s.textAreaWrapper}>
                    <AntdInput.TextArea
                      autoSize={{ minRows: 3, maxRows: 5 }}
                      placeholder={
                        isFieldsEditDisabled ? '' : 'Enter internal note'
                      }
                      value={invoiceFields.internalNotes}
                      onChange={onInvoiceFieldChange}
                      onBlur={onInvoiceFieldBlur}
                      name="invoice.internalNotes"
                      disabled={isFieldsEditDisabled}
                      onFocus={onActivateField}
                      className={clsx(
                        s.textArea,
                        selectedOutputField === 'invoice.internalNotes' &&
                          s.activeArea,
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {!hiddenOutputFields?.includes('additionalFees') && (
          <div className={s.outputBox}>
            <div className="invoiceBlock">
              <div className={s.outputBoxName}>
                <div>Additional fees </div>
                <div
                  onClick={onAdditionalFeesAdd}
                  className={s.additionalAddItem}
                >
                  + Add more
                </div>
              </div>

              <div className={s.additionalBlock}>
                {outputFields?.accessorials?.length &&
                  outputFields?.accessorials?.map(
                    ({ id, description, amount, removable }) => (
                      <AdditionalFeesItem
                        options={additionalFeesOptions}
                        key={id}
                        disabled={isFieldsEditDisabled === true}
                        amount={amount}
                        id={id}
                        description={description}
                        removable={removable}
                        onChange={onAdditionalFeesChange}
                        onRemove={() => onRemoveAdditionalFees(id)}
                      />
                    ),
                  )}
              </div>
            </div>
          </div>
        )}
        {!hiddenOutputFields?.includes('payment') && (
          <div className={s.outputBox}>
            <div className="paymentBlock">
              <div className={s.outputBoxName}>Payment</div>
              {!hiddenOutputFields?.includes('payment.type') && (
                <div className={s.fieldLine}>
                  <label className={s.fieldName}>Type</label>
                  <div className={s.selectWrapper}>
                    <AntdSelect
                      defaultValue={currentPaymentType}
                      value={currentPaymentType}
                      options={paymentOptions}
                      onChange={handlePaymentChange}
                      style={{ width: '100%' }}
                      disabled={isFieldsEditDisabled}
                    />
                  </div>
                </div>
              )}
              {!hiddenOutputFields?.includes('payment.amount') && (
                <div className={s.fieldLine}>
                  <label className={s.fieldName}>Amount</label>
                  <div className={s.readonlyField}>
                    $ {outputFields?.payment?.amount}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {/*

              // TODO: we will need this later
              <div className={s.payouts}>
                <FieldArray name="payout">
                  {() =>
                    values.tickets.map((ticket, i) => {
                      return (
                        <div className={s.payout} key={i}>
                          <div className={s.select}>
                            <Field as="select" name={`tickets.${i}.value`}>
                              <option value="wire">Wire</option>
                              <option value="ach">ACH</option>
                            </Field>
                          </div>
                          <div className={s.input}>
                            <Field
                              name={`tickets.${i}.price`}
                              placeholder="price"
                            />
                          </div>
                        </div>
                      );
                    })
                  }
                </FieldArray>
              </div>
              {status?.payoutError && <div>{status.payoutError}</div>}
              <div className={s.payoutButtonWrapper}>
                <button
                  type="button"
                  className={s.addPayoutButton}
                  onClick={(e) =>
                    handleTicketsChange(e, values, setValues, setStatus)
                  }
                >
                  + Payout
                </button>
              </div> */}
    </>
  );
};

export default DocumentEventsForm;
