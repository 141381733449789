import toast from 'react-hot-toast';
import Jsona from 'jsona';
import { t } from 'i18next';

import {
  DELETE_FILTERS,
  GET_CURRENT_USER,
  GET_WORK_ITEM,
  SET_WORK_ITEM,
  RESET_WORK_ITEM,
  SET_BATCH_WORK_ITEM,
  RESET_BATCH,
  HS_ADD_ROW,
  HS_EDIT_FIELD,
  HS_REMOVE_ROW,
  HS_RESET_DOCUMENT,
  HS_SELECT_ITEM,
  HS_SET_EDITABLE,
  HS_SET_FILTERS,
  LOAD_APPLICATIONS,
  LOAD_FILTERS,
  LOAD_USERS,
  LOGIN,
  LOGOUT,
  SAVE_SERVICES,
  SET_BREADCRUMB,
  SETTINGS,
  SET_ALERTS_LIST,
  UPDATE_OUTPUT_FIELDS,
  SET_DOCUMENT_TYPES,
  UPDATE_RULES,
  UPDATE_ALERTS,
  LOAD_GROUPING_BATCH_SUCCESS,
  LOAD_GROUPING_BATCH_ERROR,
  LOAD_GROUPING_BATCH_LOADING,
  LOAD_GROUPING_WORK_ITEMS_LOADING,
  LOAD_GROUPING_WORK_ITEMS_SUCCESS,
  LOAD_GROUPING_WORK_ITEMS_ERROR,
  LOAD_GROUPING_WORK_ITEM_LOADING,
  LOAD_GROUPING_WORK_ITEM_ERROR,
  LOAD_GROUPING_WORK_ITEM_DOCUMENTS_LOADING,
  LOAD_GROUPING_WORK_ITEM_DOCUMENTS_SUCCESS,
  LOAD_GROUPING_WORK_ITEM_DOCUMENTS_ERROR,
  ON_DOCUMENT_DROP,
  ON_PAGE_DROP,
  SET_GROUPING_DATA,
  ADD_UNASSIGNED_DOCUMENT_SUCCESS,
  ADD_UNASSIGNED_DOCUMENT_LOADING,
  REMOVE_WORK_ITEM_LOADING,
  REMOVE_WORK_ITEM_SUCCESS,
  ADD_NEW_WORK_ITEM_LOADING,
  ADD_NEW_WORK_ITEM_SUCCESS,
  RESET_GROUPING,
  CHANGE_DOCUMENT_TYPE,
  UPDATE_SAVED_TAGS,
  UPDATE_STATUS,
  UPDATE_EDITED_FIELD,
  REMOVE_DOCUMENT,
  SET_ADDITIONAL_FEES_OPTIONS,
  SET_APPROVAL_OVERRIDE_OPTIONS,
} from '@constants/action-types';
import api from '@services/axios';

export const logIn = (user) => (dispatch) => {
  return dispatch({ type: LOGIN, payload: user });
};

export const logOut = () => (dispatch) => {
  return dispatch({ type: LOGOUT, payload: null });
};

export const saveSettings = (settings) => (dispatch) => {
  return dispatch({ type: SETTINGS, payload: settings });
};

export const setBreadcrumb = (breadcrumb) => (dispatch) => {
  return dispatch({ type: SET_BREADCRUMB, payload: breadcrumb });
};

export const loadApplications = (applications) => (dispatch) => {
  return dispatch({ type: LOAD_APPLICATIONS, payload: applications });
};

export const loadFilters = (appUuid, filters) => (dispatch) => {
  return dispatch({ appUuid, type: LOAD_FILTERS, payload: filters });
};

export const deleteFilters = () => (dispatch) => {
  return dispatch({ type: DELETE_FILTERS });
};

export const saveNcwServices = (services) => (dispatch) => {
  return dispatch({ type: SAVE_SERVICES, payload: services });
};

export const hsSetEditable = (editable) => (dispatch) => {
  return dispatch({ type: HS_SET_EDITABLE, payload: editable });
};

export const updateHsState = (selected) => (dispatch) => {
  return dispatch({ type: HS_SELECT_ITEM, payload: selected });
};

export const updateHSField = (field) => (dispatch) => {
  return dispatch({ type: HS_EDIT_FIELD, payload: field });
};

export const updateHSFilters = (filters) => (dispatch) => {
  return dispatch({ type: HS_SET_FILTERS, payload: filters });
};

export const hsAddRow = (row) => (dispatch) => {
  return dispatch({ type: HS_ADD_ROW, payload: row });
};

export const hsRemoveRow = (rowId) => (dispatch) => {
  return dispatch({ type: HS_REMOVE_ROW, payload: rowId });
};

export const resetHsState = () => (dispatch) => {
  return dispatch({ type: HS_RESET_DOCUMENT });
};

export const getUsers = () => (dispatch) => {
  return dispatch({ type: LOAD_USERS });
};

export const getCurrentUser = (user) => (dispatch) => {
  return dispatch({ type: GET_CURRENT_USER, payload: user });
};

export const setWorkItem = (workItem) => (dispatch) => {
  return dispatch({ type: SET_WORK_ITEM, payload: workItem });
};

export const getWorkItem = () => (dispatch) => {
  return dispatch({ type: GET_WORK_ITEM });
};

export const resetWorkItem = () => (dispatch) => {
  return dispatch({ type: RESET_WORK_ITEM });
};

export const setBatchWorkItem = (workItem) => (dispatch) => {
  return dispatch({ type: SET_BATCH_WORK_ITEM, payload: workItem });
};

export const resetBatch = () => (dispatch) => {
  return dispatch({ type: RESET_BATCH });
};

export const setAlerts = (alerts) => (dispatch) => {
  return dispatch({ type: SET_ALERTS_LIST, payload: alerts });
};

export const updateOutputFields = (outputFields) => (dispatch) => {
  return dispatch({ type: UPDATE_OUTPUT_FIELDS, payload: outputFields });
};

export const setDocumentTypes = (documentTypes) => (dispatch) => {
  return dispatch({ type: SET_DOCUMENT_TYPES, payload: documentTypes });
};

export const updateRules = (rules) => (dispatch) => {
  return dispatch({ type: UPDATE_RULES, payload: rules });
};

export const updateAlerts = (alerts) => (dispatch) => {
  return dispatch({ type: UPDATE_ALERTS, payload: alerts });
};

export const getBatchById = (batchId) => async (dispatch) => {
  dispatch({ type: LOAD_GROUPING_BATCH_LOADING });
  api
    .jsonAPI()
    .get(`/batches/${batchId}`)
    .then((res) => {
      dispatch({ type: LOAD_GROUPING_BATCH_SUCCESS, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: LOAD_GROUPING_BATCH_ERROR });
      console.error(err);
    });
};
export const getWorkItemByFilter = (filters) => async (dispatch) => {
  dispatch({ type: LOAD_GROUPING_WORK_ITEMS_LOADING });
  api
    .restAPI()
    .get(`/work_items`, { params: filters })
    .then((res) => {
      dispatch({
        type: LOAD_GROUPING_WORK_ITEMS_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch(() => {
      dispatch({ type: LOAD_GROUPING_WORK_ITEMS_ERROR });
    });
};

export const getWorkItemById = (workItemId) => async (dispatch) => {
  dispatch({ type: LOAD_GROUPING_WORK_ITEM_LOADING });
  api
    .restAPI()
    .get(`/work_items/${workItemId}`)
    .then((res) => {
      dispatch({
        type: SET_WORK_ITEM,
        payload: res.data.data,
      });
      dispatch(getBatchById(res.data.data.attributes.batch.data.id));
    })
    .catch(() => {
      dispatch({ type: LOAD_GROUPING_WORK_ITEM_ERROR });
    });
};

export const getWorkItemDocumentsById = (workItemId) => async (dispatch) => {
  dispatch({
    type: LOAD_GROUPING_WORK_ITEM_DOCUMENTS_LOADING,
    payload: workItemId,
  });
  api
    .restAPI()
    .get(`/work_items/${workItemId}`)
    .then((res) => {
      dispatch({
        type: LOAD_GROUPING_WORK_ITEM_DOCUMENTS_SUCCESS,
        payload: res.data.data,
      });
    })
    .catch(() => {
      dispatch({ type: LOAD_GROUPING_WORK_ITEM_DOCUMENTS_ERROR });
    });
};
export const onDocumentDropAction =
  (document) => async (dispatch, getState) => {
    const cachedReducer = getState().groupingReducer;
    const { oldWorkItemId, newWorkItemId, documentId } = document;
    api
      .jsonAPI()
      .patch(
        `work_items/${oldWorkItemId}/documents/${documentId}/move_to?to_work_item_id=${newWorkItemId}`,
      )
      .catch((error) => {
        error?.response?.data?.messages?.forEach((message) => {
          toast.error(message);
        });
        return dispatch({ type: SET_GROUPING_DATA, payload: cachedReducer });
      });

    return dispatch({ type: ON_DOCUMENT_DROP, payload: document });
  };

export const onPageDropAction = (dragInfo) => async (dispatch, getState) => {
  const { newDocumentId, oldDocumentId, pageId, newWorkItemId, oldWorkItemId } =
    dragInfo;
  const cachedReducer = getState().groupingReducer;
  const replacePageParam = dragInfo.replaceThisPageTo
    ? `&swapTo=${dragInfo.replaceThisPageTo}`
    : '';
  api
    .jsonAPI()
    .patch(
      `/work_items/${oldWorkItemId}/documents/${oldDocumentId}/page_move_to?page_id=${pageId}&to_document_id=${newDocumentId}&to_work_item_id=${newWorkItemId}${replacePageParam}`,
    )
    .catch((error) => {
      error?.response?.data?.messages?.forEach((message) => {
        toast.error(message);
      });
      return dispatch({ type: SET_GROUPING_DATA, payload: cachedReducer });
    });

  return dispatch({ type: ON_PAGE_DROP, payload: dragInfo });
};

export const onAddUnassignedDocumentAction =
  ({ wiId, from }) =>
  async (dispatch, getState) => {
    dispatch({
      type: ADD_UNASSIGNED_DOCUMENT_LOADING,
      payload: { wiId, from },
    });
    const cachedReducer = getState().groupingReducer;
    api
      .restAPI()
      .post(`/work_items/${wiId}/add_unassigned_pages`)
      .then((res) => {
        dispatch({
          type: ADD_UNASSIGNED_DOCUMENT_SUCCESS,
          payload: { from, newWI: res.data.data, wiId },
        });
        toast.success(
          'The document was successfully added to the top of the list',
        );
      })
      .catch((error) => {
        error?.response?.data?.messages?.forEach((message) => {
          toast.error(message);
        });
        return dispatch({
          type: SET_GROUPING_DATA,
          payload: cachedReducer,
        });
      });
  };

export const onMovePageToNewDocumentAction =
  (dragInfo) => async (dispatch, getState) => {
    const { pageId, documentId, wiId, from, to } = dragInfo;
    const cachedReducer = getState().groupingReducer;
    api
      .restAPI()
      .post(`/work_items/${wiId}/add_unassigned_pages`)
      .then((res) => {
        dispatch({
          type: ADD_UNASSIGNED_DOCUMENT_SUCCESS,
          payload: { from, newWi: res.data.data, wiId },
        });
        return res.data.data;
      })
      .then((newWI) => {
        dispatch(
          onPageDropAction({
            newDocumentId:
              newWI.attributes.documents[newWI.attributes.documents.length - 1]
                .id,
            oldDocumentId: documentId,
            pageId,
            newWorkItemId: newWI.id,
            oldWorkItemId: wiId,
            to,
            from,
          }),
        );
      })
      .catch((error) => {
        error?.response?.data?.messages?.forEach((message) => {
          toast.error(message);
        });
        return dispatch({
          type: SET_GROUPING_DATA,
          payload: cachedReducer,
        });
      });
  };

export const onRemoveWorkItemAction =
  ({ id, from }) =>
  async (dispatch, getState) => {
    const cachedReducer = getState().groupingReducer;
    dispatch({ type: REMOVE_WORK_ITEM_LOADING });
    api
      .restAPI()
      .delete(`/work_items/${id}`)
      .then(() => {
        dispatch({ type: REMOVE_WORK_ITEM_SUCCESS, payload: { id, from } });
      })
      .catch((error) => {
        error?.response?.data?.messages?.forEach((message) => {
          toast.error(message);
        });
        return dispatch({ type: SET_GROUPING_DATA, payload: cachedReducer });
      });
  };

export const addNewWorkItemAction =
  (parentId) => async (dispatch, getState) => {
    const cachedReducer = getState().groupingReducer;
    dispatch({ type: ADD_NEW_WORK_ITEM_LOADING });
    api
      .restAPI()
      .post(`/work_items?batch_id=${cachedReducer.batch.data.id}`, {
        parent_id: parentId,
      })
      .then((res) => {
        dispatch({ type: ADD_NEW_WORK_ITEM_SUCCESS, payload: res.data.data });
      })
      .catch((error) => {
        error?.response?.data?.messages?.forEach((message) => {
          toast.error(message);
        });
        return dispatch({ type: SET_GROUPING_DATA, payload: cachedReducer });
      });
  };

export const getDocumentsTypes = () => async (dispatch) => {
  const url = 'document_types'; // needs to be changed to the actual app once settings are finished
  api
    .jsonAPI()
    .get(url, { params: { 'page[number]': 0 } })
    .then((res) => {
      const options = res.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
          visible: item.visible,
        };
      });
      dispatch(setDocumentTypes(options));
    })
    .catch((err) => console.error(err));
};

export const changeDocumentType =
  ({ selectedType, documentId, wiId, from }) =>
  async (dispatch, getState) => {
    const cachedReducer = getState().groupingReducer;

    const dataFormatter = new Jsona();
    const data = {
      type: 'workItem',
      id: wiId,
      documents: [
        {
          id: documentId,
          document_type: selectedType.label,
          layout_name: selectedType.label,
        },
      ],
    };
    const jsonAPIData = dataFormatter.serialize({ stuff: data });

    api
      .restAPI()
      .patch(`work_items/${wiId}/documents`, jsonAPIData)
      .then((res) => {
        toast.success('Updated!');
        // we should update the layout_name of the edited document here.
        dispatch({
          type: CHANGE_DOCUMENT_TYPE,
          payload: { from, wiId, newWI: res.data.data },
        });
      })
      .catch((error) => {
        error?.response?.data?.messages?.forEach((message) => {
          toast.error(message);
        });
        return dispatch({ type: SET_GROUPING_DATA, payload: cachedReducer });
      });
  };
export const resetGrouping = () => async (dispatch) => {
  dispatch({ type: RESET_GROUPING });
};

export const updateSavedTags = (tags) => (dispatch) => {
  return dispatch({ type: UPDATE_SAVED_TAGS, payload: tags });
};

export const updateWorkItemStatus = (status) => (dispatch) => {
  return dispatch({ type: UPDATE_STATUS, payload: status });
};

export const setEditedOutputField = (field) => (dispatch) => {
  return dispatch({ type: UPDATE_EDITED_FIELD, payload: field });
};

export const removeDocument = (documentId, from, wiId) => async (dispatch) => {
  api
    .restAPI()
    .delete(`work_items/${wiId}/documents/${documentId}`)
    .then(({ data: { data } }) => {
      toast.success(t('FileRemoved'));
      dispatch({
        type: REMOVE_DOCUMENT,
        payload: { from, wiId, newWI: data },
      });
    })
    .catch((e) => {
      toast.error(t('FileNotRemoved'));
      // eslint-disable-next-line no-console
      console.log(e);
    });
};

export const getAdditionalFeesOptions = () => async (dispatch, getState) => {
  const cachedOptions = getState()?.workItemReducer?.additionalFeesOptions;
  if (!cachedOptions?.length) {
    await api
      .restAPI()
      .get('accessorial_fees')
      .then((res) => {
        const options = res?.data?.map((item) => ({
          label: item,
          value: item,
        }));
        return dispatch({
          type: SET_ADDITIONAL_FEES_OPTIONS,
          payload: options,
        });
      })
      .catch((err) => console.error(err));
  }
};

export const getApprovalOverrideOptions = () => async (dispatch, getState) => {
  const cachedOptions = getState()?.workItemReducer?.approvalOverrideOptions;
  if (!cachedOptions?.length) {
    await api
      .restAPI()
      .get('approval_overrides')
      .then((res) => {
        const options = res?.data?.map((item) => ({
          label: item,
          value: item,
        }));
        return dispatch({
          type: SET_APPROVAL_OVERRIDE_OPTIONS,
          payload: options,
        });
      })
      .catch((err) => console.error(err));
  }
};
