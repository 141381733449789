import { useState } from 'react';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import useClickOut from '@hooks/useClickOut';

import Sorter from '../../Admin/Dashboard/Sorter/Sorter';

export default function TableSorter(props) {
  const [show, setShow] = useState(false);
  const { ref } = useClickOut(() => setShow(false));

  const { t } = useTranslation();
  return (
    <div className="sorter-wrapper" ref={ref}>
      <Button
        basic
        color="blue"
        icon="sort amount down"
        secondary={!!show}
        content={t('Manage columns')}
        onClick={() => setShow((prevShow) => !prevShow)}
      />
      {show && <Sorter {...props} setShow={setShow} />}
    </div>
  );
}
