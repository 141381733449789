import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'antd';
import clsx from 'clsx';
import { Checkbox, Icon } from 'semantic-ui-react';
import { toast } from 'react-hot-toast';
import { t } from 'i18next';

import api from '@services/axios';
import { setDocumentTypes, setWorkItem } from '@actions';
import DocumentViewer from '@components/aria/Application/Activity/Hyperscience/DocumentViewer/DocumentViewer';
import WorkItemDocumentList from '@components/WorkItemDocumentList';
import { generateAddDocumentsOptions } from '@components/WorkItemDocuments/downloadDropdown';
import UploadFilesModal from '@components/WorkItemDocument/UploadFilesModal';
import NewModal from '@components/Modal/NewModal';
import { PDF_VIEWER_ACTIONS } from '@components/aria/Application/Activity/Hyperscience/DocumentViewer/Toolbar';

import s from './styles.module.scss';

export default function WorkItemDocuments({ fields, readOnly }) {
  const [visibleAttachments, setVisibleAttachments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showDeletedDocuments, setShowDeletedDocuments] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState({
    modal: null,
    metadata: {},
  });

  const workItem = useSelector((state) => state.workItemReducer.workItem);
  const cleanOpenModalData = () =>
    setOpenModal({
      modal: null,
      metadata: {},
    });
  const documentTypes = useSelector(
    (state) => state.documentTypesReducer.documentTypes,
  );
  const dispatch = useDispatch();

  const getDocumentTypes = () => {
    const url = 'document_types'; // needs to be changed to the actual app once settings are finished
    api
      .jsonAPI()
      .get(url, { params: { 'page[number]': 0 } })
      .then((res) => {
        const options = res.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
            visible: item.visible,
          };
        });
        dispatch(setDocumentTypes(options));
      })
      .catch((err) => console.error(err));
  };
  const uploadOptions = generateAddDocumentsOptions({
    canGenerateInvoice: !workItem?.documents?.some((document) =>
      document?.layout_name?.includes('0 - Factor Invoice'),
    ),
  });

  const uploadButtonActions = ({ key }) => {
    setOpenModal({ modal: key, metadata: {} });
  };

  const renderModal = ({ modal }) => {
    switch (modal) {
      case 'document': {
        return (
          <UploadFilesModal
            onSubmit={onUploadDocument}
            onCancel={cleanOpenModalData}
            uploadModalOpen
          />
        );
      }
      case 'invoice': {
        return (
          <NewModal
            title="Do you want to generate an Invoice?"
            visible
            submitButtonText="Create"
            onSubmit={generateInvoice}
            onCancel={cleanOpenModalData}
            onCrossClick={cleanOpenModalData}
          >
            Newly created Invoice document will appear in WI document list
          </NewModal>
        );
      }
      default: {
        return null;
      }
    }
  };

  const generateInvoice = () => {
    const url = `work_items/${workItem.id}/documents`;

    const multipartData = new FormData();
    multipartData.append(
      'documentType',
      documentTypes.find((document) =>
        document.label.includes('0 - Factor Invoice'),
      ).value,
    );

    toast.success(t('InvoiceOnGenerating'));
    cleanOpenModalData();

    api
      .formData()
      .post(url, multipartData)
      .then(({ data }) => {
        toast.success(t('InvoiceIsGenerated'));
        dispatch(setWorkItem(data));
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.errors?.[0] || t('InvoiceInNotGenerated'),
        );
      });
  };

  const onUploadDocument = ({ file, fileType, noaToAll }) => {
    const url = `work_items/${workItem.id}/documents`;

    const multipartData = new FormData();
    multipartData.append('manuallyAddedDocument', file);
    multipartData.append('documentType', fileType.key);
    if (noaToAll) {
      multipartData.append('noaToAll', noaToAll);
    }

    toast.success(t('FileIsLoading'));
    api
      .formData()
      .post(url, multipartData)
      .then(({ data }) => {
        if (fileType.label === '55 - NOA') {
          setSelectedDocument(null);
          toast.success('NOA was successfully updated');
        }
        toast.success(t('FileUploaded'));
        dispatch(setWorkItem(data));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.errors?.[0] || 'Unknown error');
      });
  };

  const handleRemoveDocument = (id) => {
    api
      .jsonAPI()
      .delete(`work_items/${workItem.id}/documents/${id}`)
      .then(({ data }) => {
        toast.success(t('FileRemoved'));
        dispatch(setWorkItem(data));
        setSelectedDocument(null);
      })
      .catch((e) => {
        toast.error(t('FileNotRemoved'));
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  const applyFilters = ({ method, value, pageId }) => {
    setIsLoading(true);

    let editData = {};

    switch (method) {
      case PDF_VIEWER_ACTIONS.brightness:
      case PDF_VIEWER_ACTIONS.contrast: {
        editData = {
          method,
          ...(value && { percent: value * 100 }),
          page_id: pageId,
        };
        break;
      }
      case PDF_VIEWER_ACTIONS.rotate: {
        editData = {
          method,
          ...(value && { degrees: value }),
          page_id: pageId,
        };
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.log('method handler not found');
      }
    }
    api
      .jsonAPI()
      .patch(
        `work_items/${workItem.id}/documents/${selectedDocument}/page_edit`,
        editData,
      )
      .then(({ data }) => {
        toast.success(t('FileFilteredSuccess', { item: method }));
        dispatch(setWorkItem(data));
        setIsLoading(false);
        setSelectedDocument(selectedDocument);
      })
      .catch(() => {
        setIsLoading(false);
        toast.success(t('FileFilteredFailed', { item: method }));
      });
  };

  const onPageCrop = (cropParams, pageId) => {
    setIsLoading(true);
    api
      .jsonAPI()
      .patch(
        `work_items/${workItem.id}/documents/${selectedDocument}/page_crop`,
        {
          crop: cropParams,
          page_id: pageId,
        },
      )
      .then(({ data }) => {
        toast.success(t('FileCroppedSuccess'));
        dispatch(setWorkItem(data));
        setIsLoading(false);
        setSelectedDocument(selectedDocument);
      })
      .catch(() => {
        setIsLoading(false);
        toast.success(t('FileCroppedFailed'));
      });
  };

  const onPageStamp = ({ x1, x2, y1, y2 }, pageId, stampId) => {
    setIsLoading(true);
    api
      .jsonAPI()
      .patch(`work_items/${workItem.id}/documents/${selectedDocument}/stamp`, {
        pageId,
        stamp: {
          x1,
          y1,
          x2,
          y2,
        },
        stampId,
      })
      .then(({ data }) => {
        toast.success(t('FileStampedSuccess'));
        dispatch(setWorkItem(data));
        setIsLoading(false);
        setSelectedDocument(selectedDocument);
      })
      .catch(() => {
        setIsLoading(false);
        toast.success(t('FileStampedFailed'));
      });
  };

  const handleRestoreDocument = (id) => {
    api
      .jsonAPI()
      .patch(`work_items/${workItem.id}/documents/${id}/restore`)
      .then(({ data }) => {
        toast.success(t('FileRestored'));
        dispatch(setWorkItem(data));
        setSelectedDocument(null);
      })
      .catch((e) => {
        toast.error(t('FileNotRestored'));
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  useEffect(() => {
    function getAttachmentsFromDocument(id) {
      return workItem?.documents.find((doc) => doc.id === id)?.pages || [];
    }

    const documentAttachments = getAttachmentsFromDocument(selectedDocument);
    setVisibleAttachments(documentAttachments);
  }, [selectedDocument, workItem.documents]);

  useEffect(() => {
    if (workItem?.documents.length > 0) {
      getDocumentTypes();
    }
  }, [workItem]);

  return (
    <section className={s.wiDocuments}>
      {renderModal(openModal)}
      <div className="wi-documents-container">
        <div>
          <div className={s.wiDocumentsListContainer}>
            <div className={s.headerText}>
              <h4 className={s.docsHeading}>Documents</h4>
              <div className={s.showDeleted}>
                Show deleted
                <Checkbox
                  onChange={(_, { checked }) =>
                    setShowDeletedDocuments(checked)
                  }
                />
              </div>
            </div>
            <Dropdown
              disabled={readOnly}
              className={clsx(
                s.addDocumentSelect,
                readOnly && s.readOnlyDropdown,
              )}
              menu={{ items: uploadOptions, onClick: uploadButtonActions }}
              trigger={['click']}
            >
              <div className={clsx('ui', 'primary', 'button', s.button)}>
                Add document <Icon name="angle down" className={s.icon} />
              </div>
            </Dropdown>
          </div>
          <WorkItemDocumentList
            documents={workItem.documents.filter((document) => {
              if (!document.discarded) {
                return true;
              }
              return document.discarded === showDeletedDocuments;
            })}
            selectedDocument={selectedDocument}
            setSelectedDocument={setSelectedDocument}
            onDocumentRemove={handleRemoveDocument}
            onDocumentRestore={handleRestoreDocument}
            readOnly={readOnly}
          />
        </div>
        <DocumentViewer
          selectedDocument={selectedDocument}
          fields={fields}
          attachments={visibleAttachments}
          documentsCount={workItem?.documents?.length || 0}
          documents={workItem?.documents || []}
          readOnly={readOnly}
          onPageCrop={onPageCrop}
          isLoadingNewData={isLoading}
          onPageStamp={onPageStamp}
          onPageApplyFilter={applyFilters}
          stampImage={workItem?.stamp}
          defaultDocumentFitToWidth={workItem?.flags?.imageFitToWidth}
        />
      </div>
    </section>
  );
}
