/* eslint-disable jsx-a11y/label-has-associated-control */
import { ErrorMessage, Formik } from 'formik';
import Jsona from 'jsona';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Form, Input } from 'semantic-ui-react';
import * as Yup from 'yup';

import api from '@services/axios';

export default function TagsForm({ tag, loading }) {
  const navigate = useNavigate();
  const { id: tagId } = useParams();
  const { t } = useTranslation();
  const dataFormatter = new Jsona();
  const prepareData = (formData) => {
    const data = {
      name: formData?.name,
    };
    return {
      type: 'tag',
      id: tag?.id ?? null,
      ...data,
    };
  };

  const onSubmitHandler = (formValues, { setSubmitting }) => {
    const preparedData = dataFormatter.serialize({
      stuff: prepareData(formValues),
    });
    const saveFunction = tagId
      ? api.jsonAPI().put(`/tags/${tagId}`, preparedData)
      : api.jsonAPI().post('tags', preparedData);
    setSubmitting(true);
    saveFunction
      .then(() => {
        const toastText = tagId
          ? t('ItemSaved', { item: 'Tag' })
          : t('TagCreated', { name: formValues.name });
        toast.success(toastText);
        navigate('/admin/tags');
      })
      .catch((err) => {
        console.error('Error on form submission', { err });
        toast.error(t('ItemNotSaved', { item: 'tag' }));
      })
      .finally(() => setSubmitting(false));
  };

  const displayError = (errors, touched, key) => {
    return errors[key] && touched[key];
  };
  return (
    <Formik
      initialValues={{
        name: tag?.name ?? '',
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(t('ItemRequired', { item: 'Name' })),
      })}
      enableReinitialize
      onSubmit={onSubmitHandler}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
      }) => (
        <Form
          loading={loading}
          onSubmit={handleSubmit}
          className="form-container"
        >
          <div className="users-form">
            <Form.Field
              required
              className="inline-field"
              error={displayError(errors, touched, 'name')}
            >
              <label>{t('Name')}</label>
              <div className="text-input">
                <Input
                  name="name"
                  onChange={handleChange}
                  placeholder={t('ItemAnPlaceholder', {
                    item: 'name',
                  })}
                  value={values.name}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <div className="submit-button">
              <Button basic as={Link} to="/admin/tags" disabled={isSubmitting}>
                {t('Cancel')}
              </Button>
              <Button
                primary
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {t('Submit')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
