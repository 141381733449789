/* eslint-disable jsx-a11y/label-has-associated-control */
import { ErrorMessage, Formik } from 'formik';
import Jsona from 'jsona';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Checkbox, Form, Input } from 'semantic-ui-react';
import * as Yup from 'yup';
import { Mentions, Select } from 'antd';
import { useEffect, useState } from 'react';

import api from '@services/axios';

export default function EmailTemplateForm({
  template,
  loading,
  documentTypes,
  statuses,
}) {
  const navigate = useNavigate();
  const { id: templateId } = useParams();
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);
  const dataFormatter = new Jsona();
  const prepareTemplateData = (templateData) => {
    const data = {
      template: templateData.template,
      subject: templateData.subject,
      title: templateData.title,
      toMergeDocuments: templateData.toMergeDocuments,
      documentTypes: templateData.documentTypes,
      nextStatus: templateData.nextStatus,
    };
    return {
      type: 'emailTemplate',
      id: template?.id ?? null,
      ...data,
    };
  };

  const onSubmitHandler = (formValues, { setSubmitting }) => {
    const preparedData = dataFormatter.serialize({
      stuff: prepareTemplateData(formValues),
    });
    const saveFunction = templateId
      ? api.jsonAPI().put(`/email_templates/${templateId}`, preparedData)
      : api.jsonAPI().post('email_templates', preparedData);
    setSubmitting(true);
    saveFunction
      .then(() => {
        const toastText = templateId
          ? t('ItemSaved', { item: 'Template' })
          : t('TemplateCreated', { name: formValues.title });
        toast.success(toastText);
        navigate('/admin/templates');
      })
      .catch((err) => {
        console.error('Error on form submission', { err });
        toast.error(t('ItemNotSaved', { item: 'template' }));
      })
      .finally(() => setSubmitting(false));
  };

  const displayError = (errors, touched, key) => {
    return errors[key] && touched[key];
  };

  useEffect(() => {
    const getTags = async () => {
      const { data } = await api.restAPI().get('email_templates/tags');
      if (!data) return [];

      return data.map((item) => ({
        ...item,
        value: `${item.value}}`,
      }));
    };

    getTags().then((res) => setTags(res));
  }, []);
  return (
    <Formik
      initialValues={{
        template: template?.template ?? '',
        subject: template?.subject ?? '',
        title: template?.title ?? '',
        toMergeDocuments: template?.toMergeDocuments ?? false,
        documentTypes: template?.documentTypes ?? [],
        nextStatus: template?.nextStatus ?? '',
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(t('ItemRequired', { item: 'Title' })),
        template: Yup.string().required(
          t('ItemRequired', { item: 'Template' }),
        ),
        subject: Yup.string().required(t('ItemRequired', { item: 'Subject' })),
      })}
      enableReinitialize
      onSubmit={onSubmitHandler}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        errors,
        touched,
        isSubmitting,
        setFieldValue,
        setTouched,
      }) => (
        <Form
          loading={loading}
          onSubmit={handleSubmit}
          className="form-container"
        >
          <div className="users-form">
            <Form.Field
              required
              className="inline-field"
              error={displayError(errors, touched, 'title')}
            >
              <label>{t('Title')}</label>
              <div className="text-input">
                <Input
                  name="title"
                  onChange={handleChange}
                  placeholder={t('ItemAnPlaceholder', { item: 'title' })}
                  value={values.title}
                />
                <ErrorMessage
                  name="title"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <Form.Field
              required
              className="inline-field"
              error={displayError(errors, touched, 'subject')}
            >
              <label>{t('Subject')}</label>
              <div className="text-input">
                <Input
                  name="subject"
                  onChange={handleChange}
                  placeholder={t('ItemAnPlaceholder', { item: 'subject' })}
                  value={values.subject}
                />
                <ErrorMessage
                  name="subject"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <Form.Field
              required
              className="inline-field"
              error={displayError(errors, touched, 'template')}
            >
              <label>{t('Email body')}</label>
              <div className="text-input">
                <Mentions
                  rows={12}
                  name="template"
                  options={tags}
                  prefix="{"
                  onChange={(value) =>
                    handleChange({
                      target: {
                        name: 'template',
                        value,
                      },
                    })
                  }
                  placeholder={t('ItemPlaceholder', {
                    item: 'Email body, press { to mentions Work Item field',
                  })}
                  value={values.template}
                />
                <ErrorMessage
                  name="template"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>

            <Form.Field
              className="inline-field"
              error={displayError(errors, touched, 'documentTypes')}
            >
              <label>{t('Document Types')}</label>
              <div className="text-input">
                <Select
                  onChange={(e) => {
                    setFieldValue('documentTypes', e);
                    setTimeout(
                      () => setTouched({ ...touched, documentTypes: true }),
                      1000,
                    );
                  }}
                  value={values.documentTypes}
                  placeholder="Select document types"
                  options={documentTypes}
                  style={{ width: '100%' }}
                  mode="multiple"
                />
                <ErrorMessage
                  name="roles"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <Form.Field
              className="inline-field"
              error={displayError(errors, touched, 'nextStatus')}
            >
              <label>{t('Next status')}</label>
              <div className="text-input">
                <Select
                  onChange={(e) => {
                    setFieldValue('nextStatus', e);
                    setTimeout(
                      () => setTouched({ ...touched, nextStatus: true }),
                      1000,
                    );
                  }}
                  value={values.nextStatus}
                  placeholder="Select next status"
                  options={statuses}
                  style={{ width: '100%' }}
                />
                <ErrorMessage
                  name="roles"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <Form.Field
              required
              className="inline-field"
              error={displayError(errors, touched, 'toMergeDocuments')}
            >
              <label>{t('To merge documents')}</label>
              <div className="text-input">
                <Checkbox
                  id="toMergeDocuments"
                  name="toMergeDocuments"
                  onChange={handleChange}
                  checked={values.toMergeDocuments}
                />
                <ErrorMessage
                  name="toMergeDocuments"
                  component="div"
                  className="form-error"
                />
              </div>
            </Form.Field>
            <div className="submit-button">
              <Button
                basic
                as={Link}
                to="/admin/templates"
                disabled={isSubmitting}
              >
                {t('Cancel')}
              </Button>
              <Button
                primary
                type="submit"
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                {t('Submit')}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
