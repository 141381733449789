import { Table } from 'semantic-ui-react';

import { FILTER_TYPES } from './filterTypes';
import DateFilter from './DateFilter';
import TextFilter from './TextFilter';
import MultiSelectFilter from './MultiSelectFilter';
import SingleSelectFilter from './SingleSelectFilter';
import SelectFromEndpointFilter from './SelectFromEndpointFilter';

export default function FilterCell({
  column,
  filters,
  updateFilters,
  onSelectAll,
  selectedAll,
}) {
  const setSearchKey = () => {
    if (column.searchKey) return column.searchKey;
    return column.key.includes('.') ? column.key.split('.').at(-1) : column.key;
  };
  const searchKey = setSearchKey();
  const filterKey = `filter[${searchKey}]`;

  const unselectAllElements = () => {
    if (typeof onSelectAll === 'function' && selectedAll) {
      // unselect all elements when a table filter is changed.
      onSelectAll();
    }
  };

  const onFilterChange = (value) => {
    unselectAllElements();
    updateFilters({ [filterKey]: value, 'page[number]': 1 });
  };

  const onDateFilterChange = (entries) => {
    unselectAllElements();
    updateFilters({ ...entries, 'page[number]': 1 });
  };

  const filterValue = filters[filterKey];

  const renderFilter = () => {
    switch (column.filter) {
      case FILTER_TYPES.DATE:
        return (
          <DateFilter
            column={column}
            filters={filters}
            onChange={onDateFilterChange}
          />
        );
      case FILTER_TYPES.MULTI_SELECT:
        return (
          <MultiSelectFilter
            options={column.options}
            value={filterValue}
            onChange={onFilterChange}
          />
        );
      case FILTER_TYPES.SELECT:
        return (
          <SingleSelectFilter
            options={column.options}
            value={filterValue}
            onChange={onFilterChange}
          />
        );
      case FILTER_TYPES.SELECT_ENDPOINT:
        return (
          <SelectFromEndpointFilter
            column={column}
            filterValue={filterValue}
            onChange={onFilterChange}
          />
        );
      case FILTER_TYPES.TEXT:
      default:
        return <TextFilter value={filterValue} onChange={onFilterChange} />;
    }
  };

  return (
    <Table.HeaderCell className="filter-cell">
      {renderFilter()}
    </Table.HeaderCell>
  );
}
